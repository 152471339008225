import React, { FC } from 'react';
import styled from 'styled-components';

import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import Spacer from 'components/V2/Spacer/Spacer';

interface Props {
  height?: number;
  withoutPadding?: boolean;
}

const LoadingWidget: FC<Props> = ({ height, withoutPadding }) => (
  <LoadingWidgetContainer height={height} withoutPadding={withoutPadding}>
    <FlexRow alignVertical={AlignDirections.Center}>
      <FlexColumn>
        <LoadingSkeleton width="90%" />
        <Spacer height="small" />
        <FlexRow>
          <FlexColumn width="33%">
            <LoadingSkeleton />
          </FlexColumn>
          <FlexColumn width="0.75%" />
          <FlexColumn width="60%">
            <LoadingSkeleton />
          </FlexColumn>
        </FlexRow>
        <Spacer height="small" />
        <FlexRow>
          <FlexColumn width="25%">
            <LoadingSkeleton />
          </FlexColumn>
          <FlexColumn width="0.75%" />
          <FlexColumn width="72%">
            <LoadingSkeleton />
          </FlexColumn>
        </FlexRow>
        <Spacer height="small" />
        <FlexRow>
          <FlexColumn width="54%">
            <LoadingSkeleton />
          </FlexColumn>
          <FlexColumn width="0.75%" />
          <FlexColumn width="35%">
            <LoadingSkeleton />
          </FlexColumn>
        </FlexRow>
        <Spacer height="small" />
        <FlexRow>
          <FlexColumn width="16%">
            <LoadingSkeleton />
          </FlexColumn>
          <FlexColumn width="0.75%" />
          <FlexColumn width="12%">
            <LoadingSkeleton />
          </FlexColumn>
          <FlexColumn width="0.75%" />
          <FlexColumn width="64%">
            <LoadingSkeleton />
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  </LoadingWidgetContainer>
);

const LoadingWidgetContainer = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  ${({ height }) =>
    height &&
    `
    height: ${height}px;
    `};
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  padding: ${({ theme, withoutPadding }) => (withoutPadding ? 0 : theme.spacing.extraLarge)};
`;

export default LoadingWidget;
