import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PATH_LEGAL_ENTITY_REQUIRED_DOCUMENTS } from 'routes/routesPaths';
import OnboardingBanner, { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';
import { ReactComponent as Document } from 'assets/images/icons/onboardingIcons/document.svg';

const RequiredDocuments: FC<{
  direction: OnboardingBannerDirection;
}> = ({ direction }) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <OnboardingBanner
      direction={direction}
      heading={intl.formatMessage({ id: 'widgets.onboarding.legalEntity.requiredDocuments.heading' })}
      description={intl.formatMessage({ id: 'widgets.onboarding.legalEntity.requiredDocuments.description' })}
      buttonText={intl.formatMessage({ id: 'widgets.onboarding.legalEntity.requiredDocuments.buttonText' })}
      buttonOnClick={() => history.push(PATH_LEGAL_ENTITY_REQUIRED_DOCUMENTS)}
      icon={<Document />}
    />
  );
};

export default RequiredDocuments;
