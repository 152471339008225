import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';

import { getWallet, QueryKeys, refetchIntervals } from '@investown/fe/api-sdk';
import { currencyFormat, defaultWalletCurrency } from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import { fillColor, textColor } from '../utils';
import PremiumPillWrapper from '../PremiumPillWrapper';

import { MenuButton } from './components/MenuButton';
import AutoinvestMenuIcon from './AutoinvestMenuIcon/AutoinvestMenuIcon';

import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/Spacer';
import WalletIcon from 'components/V2/Icons/Wallet';
import PlusIcon from 'components/V2/Icons/Plus';
import { PATH_USER, PATH_WALLET } from 'routes/routesPaths';
import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import { isOptimisticWalletRefetchInProgress } from 'appRedux/investment/sagas';

const WalletWithProfile: FC = () => {
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const isWalletActive = PATH_WALLET === pathname;
  const isProfileActive = PATH_USER === pathname;
  const wallet = useQuery(QueryKeys.Wallet, getWallet, {
    refetchInterval: () => {
      return isOptimisticWalletRefetchInProgress() ? false : refetchIntervals.thirtySeconds;
    },
  });
  const intl = useIntl();

  return (
    <MenuContainer>
      <AutoinvestMenuIcon />
      <MenuButton to={PATH_WALLET} $active={isWalletActive} data-testid={TestIds.Wallet}>
        <WalletIcon fillColor={fillColor(isWalletActive, theme)} color={textColor(isWalletActive, theme)} />
        <Spacer width="medium" />
        {wallet.isLoading && <LoadingSkeleton width="100px" />}

        {wallet.isSuccess && (
          <Typography variant="buttonBASESemiBold" color={isWalletActive ? 'brand' : 'strong'} clarityMask>
            {currencyFormat({
              input: wallet.data.Wallet.availableBalance,
              currency: defaultWalletCurrency,
              locale: intl.locale,
            })}
          </Typography>
        )}
        <Spacer width="medium" />
        <PlusButton>
          <PlusIcon fillColor={fillColor(true, theme)} color={textColor(true, theme)} width="45px" />
        </PlusButton>
      </MenuButton>
      <Spacer width="large" />
      <PremiumPillWrapper active={isProfileActive} />
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  z-index: 2;
`;

const PlusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.colorTokens.button.secondary.default};
  width: 32px;
  height: 32px;
`;

export default WalletWithProfile;
