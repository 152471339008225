import { useQuery, UseQueryResult } from 'react-query';

import { QueryKeys, CoreSDK, getRepaymentSchedule } from '@investown/fe/api-sdk';

const useRepaymentSchedule = (
  propertyInvestmentRoundId: string
): UseQueryResult<CoreSDK.RepaymentScheduleQuery, Error> =>
  useQuery<CoreSDK.RepaymentScheduleQuery, Error>(
    [QueryKeys.PortfolioSizeMonthlyHistory, { propertyInvestmentRoundId }],
    () => getRepaymentSchedule(propertyInvestmentRoundId),
    {
      refetchInterval: false,
    }
  );

export default useRepaymentSchedule;
