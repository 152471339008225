import React, { FC } from 'react';

import { UndrawImages } from 'constants/UndrawImages';
import Lang from 'util/IntlMessages';
import Typography from 'components/V2/Typography/Typography';

const FinancialAnalysisTableEmpty: FC = () => {
  const emptyState = {
    image: UndrawImages.ChoosingHouse,
    description: <Lang id="errors.financialAnalysis.title" />,
  };

  return (
    <Typography variant="bodyBASERegular" color="subtle">
      {emptyState.description}
    </Typography>
  );
};

export default FinancialAnalysisTableEmpty;
