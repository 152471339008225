import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PATH_WALLET } from 'routes/routesPaths';
import useFireworks from 'hooks/useFireworks';
import OnboardingBanner, { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';
import { ReactComponent as Wallet } from 'assets/images/icons/onboardingIcons/wallet.svg';

const MoneyDeposit: FC<{ inflectedFirstName: string; direction: OnboardingBannerDirection }> = ({
  inflectedFirstName,
  direction,
}) => {
  const intl = useIntl();
  const fireworks = useFireworks();
  const userWasCelebratedByFireworksKey = 'userWasCelebratedByFireworks';
  const history = useHistory();

  useEffect(() => {
    const userWasCelebratedByFireworks = localStorage.getItem(userWasCelebratedByFireworksKey);

    if (userWasCelebratedByFireworks !== '1') {
      setTimeout(() => {
        fireworks.fire();
        localStorage.setItem(userWasCelebratedByFireworksKey, '1');
      }, 1000);
    }
  });

  return (
    <OnboardingBanner
      direction={direction}
      heading={intl.formatMessage(
        { id: 'widgets.onboarding.moneyDeposit.heading' },
        { salutation: inflectedFirstName }
      )}
      description={intl.formatMessage({
        id: 'widgets.onboarding.moneyDeposit.description',
      })}
      buttonText={intl.formatMessage({ id: 'widgets.onboarding.moneyDeposit.button' })}
      buttonOnClick={() => history.push(PATH_WALLET)}
      icon={<Wallet />}
    />
  );
};

export default MoneyDeposit;
