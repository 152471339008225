import { UserDetailsDTO, UserLevelConfigDTO, UserLevelsConfigDTO, useUserLevels } from '@investown/fe/api-sdk';
import { getLevelFromConfig } from '@investown/fe/common-utils';

export function useUserLevelAndAllLevels(userDetailsData: UserDetailsDTO | undefined): {
  userLevels: {
    data: UserLevelsConfigDTO | undefined;
    isLoading: boolean;
    isError: unknown;
    isIdle: boolean;
  };
  currentUserLevel: UserLevelConfigDTO | undefined;
} {
  const { data, isLoading, isError, isIdle } = useUserLevels({});
  if (!userDetailsData || !data) {
    return { userLevels: { data: undefined, isLoading, isError, isIdle }, currentUserLevel: undefined };
  }

  const currentUserLevel = getLevelFromConfig({
    levelId: userDetailsData.level.currentLevel,
    levels: data.levels,
  });

  return { userLevels: { data, isLoading, isError, isIdle }, currentUserLevel };
}
