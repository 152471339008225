/* eslint-disable import/no-duplicates */
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { CoreSDK } from '@investown/fe/api-sdk';

interface Props {
  purpose: CoreSDK.PropertyInvestmentRoundPurpose;
}

export const PropertyPurpose: React.FC<Props> = ({ purpose }) => {
  const intl = useIntl();

  return <>{getPurpose(purpose, intl)}</>;
};

export function getPurpose(purpose: CoreSDK.PropertyInvestmentRoundPurpose, intl: IntlShape) {
  switch (purpose) {
    case CoreSDK.PropertyInvestmentRoundPurpose.Rental:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.rental' });
    case CoreSDK.PropertyInvestmentRoundPurpose.Reconstruction:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.reconstruction' });
    case CoreSDK.PropertyInvestmentRoundPurpose.PropertyAquisition:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.propertyAquisition' });
    case CoreSDK.PropertyInvestmentRoundPurpose.Construction:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.construction' });
    case CoreSDK.PropertyInvestmentRoundPurpose.NonPurposeLoan:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.nonPurposeLoan' });
    case CoreSDK.PropertyInvestmentRoundPurpose.Other:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.other' });
    case CoreSDK.PropertyInvestmentRoundPurpose.BusinessDevelopment:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.businessDevelopment' });
    case CoreSDK.PropertyInvestmentRoundPurpose.Refinance:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.refinance' });
    default:
      return intl.formatMessage({ id: 'property.investmentRoundInfo.propertyPurpose.other' });
  }
}
