import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import NewContractModalContent from './NewContractModalContent';

import Modal from 'components/V2/Modal/Modal';

interface Props {
  visible: boolean;
  onClose: () => void;
  inflectedFirstName: string;
  isLegalEntity: boolean;
}
const NewContractModal: FC<Props> = ({ visible, onClose, inflectedFirstName, isLegalEntity }) => {
  const intl = useIntl();

  const modalContentContainerStyle = React.useMemo(() => {
    return {
      padding: '24px 0',
      height: '100%',
    };
  }, []);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      header={intl.formatMessage({ id: 'newInvestmentContractModal.header' })}
      minWidth={1002}
      minHeight={720}
      contentContainerStyle={modalContentContainerStyle}
    >
      <ModalContent>
        <NewContractModalContent
          onClose={onClose}
          inflectedFirstName={inflectedFirstName}
          isLegalEntity={isLegalEntity}
        />
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: unset;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colorTokens.scrollbar.thumb};
  }
`;

export default NewContractModal;
