import { UseQueryResult, useQuery } from 'react-query';

import { CoreSDK, QueryKeys, getPropertyFinancialAnalysis } from '@investown/fe/api-sdk';

const usePropertyFinancialAnalysis = ({
  slug,
  borrowerId,
  isEnabled,
}: {
  slug: string;
  borrowerId?: string;
  isEnabled?: boolean;
}): UseQueryResult<CoreSDK.PropertyFinancialAnalysisQuery, Error> =>
  useQuery<CoreSDK.PropertyFinancialAnalysisQuery, Error>(
    [QueryKeys.PortfolioSizeMonthlyHistory, { slug, borrowerId }],
    () => getPropertyFinancialAnalysis(slug, borrowerId),
    {
      refetchInterval: false,
      enabled: isEnabled || true,
    }
  );

export default usePropertyFinancialAnalysis;
