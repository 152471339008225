import React, { FC } from 'react';
import styled from 'styled-components';

import { TestIds } from '@investown/fe/test-utils/testIds';

import Lang from 'util/IntlMessages';
import Button from 'components/V2/Button';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import CheckOutline from 'components/V2/Icons/CheckOutline';
import { theme } from 'styles/theme';

interface SuccessfulInvestmentProps {
  closeModal: () => void;
}

const BidPlacedSuccessfully: FC<SuccessfulInvestmentProps> = ({ closeModal }) => (
  <Wrapper>
    <CheckOutline
      width="160px"
      color={theme.colorTokens.utility.success.medium}
      hoverColor={theme.colorTokens.utility.success.medium}
    />
    <Spacer height="extraLarge" />
    <Typography variant="displaySMMedium" color="strong">
      <Lang id="secondaryMarketOfferModal.buy.success" />
    </Typography>
    <Spacer height="large" />
    <Typography variant="bodyBASERegular" color="subtle">
      <Lang id="secondaryMarketOfferModal.buy.success.disclaimer" />
    </Typography>
    <Spacer height="massive" />
    <StyledButton
      data-testid={TestIds.CloseSecondaryMarketBidSuccess}
      color="primary"
      size="large"
      onClick={closeModal}
    >
      <Lang id="secondaryMarketOfferModal.buy.back" />
    </StyledButton>
    <Spacer height="massive" />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  margin: auto;

  h1 {
    margin-bottom: ${(props) => props.theme.margins.huge};
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

export default BidPlacedSuccessfully;
