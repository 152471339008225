import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PATH_USER_VERIFICATION } from 'routes/routesPaths';
import OnboardingBanner, { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';
import { ReactComponent as PersonWithCross } from 'assets/images/icons/onboardingIcons/personWithCross.svg';

interface Props {
  reason?: string;
  inflectedFirstName: string;
  direction: OnboardingBannerDirection;
}

const Declined: FC<Props> = ({ reason, inflectedFirstName, direction }) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <OnboardingBanner
      direction={direction}
      heading={intl.formatMessage({ id: 'widgets.onboarding.declined.heading' }, { salutation: inflectedFirstName })}
      description={intl.formatMessage({ id: 'widgets.onboarding.declined.description' }, { reason })}
      buttonText={intl.formatMessage({ id: 'widgets.onboarding.declined.buttonText' })}
      buttonOnClick={() => history.push(PATH_USER_VERIFICATION)}
      icon={<PersonWithCross />}
    />
  );
};

export default Declined;
