import React, { FC } from 'react';
import styled from 'styled-components';

import { UtilityColorName } from '@investown/fe/common-utils';

export type TagColor = UtilityColorName;

interface Props {
  color: TagColor;
  children: React.ReactNode;
  className?: string;
  filled?: boolean;
  size?: 'default' | 'small';
}

const Tag: FC<Props> = ({ color, children, className, filled = false, size = 'default' }) => (
  <StyledDiv className={className} $filled={filled} $color={color} $size={size}>
    {children}
  </StyledDiv>
);

const StyledDiv = styled.div<{ $filled: boolean; $color: TagColor; $size?: 'default' | 'small' }>`
  color: ${({ theme, $color }) => theme.colorTokens.utility[$color].strong};
  background-color: ${({ theme, $color }) => theme.colorTokens.utility[$color].subtle};
  font-size: ${({ theme, $size }) =>
    $size === 'small' ? theme.typography.labelSMMedium.size : theme.typography.labelBASEMedium.size};
  font-weight: ${({ theme, $size }) =>
    $size === 'small' ? theme.typography.labelSMMedium.weight : theme.typography.labelBASEMedium.weight};
  line-height: ${({ theme, $size }) =>
    $size === 'small' ? theme.typography.labelSMMedium.lineHeight : theme.typography.labelBASEMedium.lineHeight};
  padding: ${({ theme, $size }) =>
    $size === 'small'
      ? `${theme.spacing.small} ${theme.spacing.medium}`
      : `${theme.spacing.medium} ${theme.spacing.regular}`};
  border-width: 0;
  border-radius: ${({ theme }) => theme.tag.borderRadius};
  display: inline-flex;
  align-items: center;
`;

export default Tag;
