/* eslint-disable no-param-reassign */
import React, { FC, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InfiniteData, UseInfiniteQueryResult } from 'react-query';
import { useIntl } from 'react-intl';

import { useAnalytics, projectDetailTabOpenedAction, ProjectDetailTabs } from '@investown/fe/common-utils';
import { CoreSDK, useInfiniteInvestmentRoundActivities } from '@investown/fe/api-sdk';

import { mapActivityToTimelineDataItem } from './TabLoanActivity';

import Markdown from 'components/V2/Markdown/Markdown';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import Timeline, { DataItem } from 'components/V2/Timeline/Timeline';
import { captureSentryError } from 'util/sentry';
import { getAppLanguageLocalVariable } from 'lngProvider';

interface Props {
  description: string;
  investmentRoundId?: string;
  slug?: string;
  showLatestActivity?: boolean;
}

type LatestActivityProps = {
  count?: number;
  slug?: string;
  investmentRoundId: string;
  enabled?: boolean;
};

const PropertyDescription: FC<Props> = ({ description, slug, investmentRoundId, showLatestActivity = false }) => {
  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.trackEvent(
      projectDetailTabOpenedAction({
        path: location.pathname,
        tab: ProjectDetailTabs.About,
      })
    );
  }, [analytics, location]);

  return (
    <>
      {investmentRoundId == null ? null : (
        <LatestProjectActivity
          count={3}
          slug={slug}
          investmentRoundId={investmentRoundId}
          enabled={showLatestActivity}
        />
      )}
      <Markdown>{description}</Markdown>
    </>
  );
};

function LatestProjectActivity({ count = 3, slug, investmentRoundId, enabled = true }: LatestActivityProps) {
  const intl = useIntl();
  const projectActivity = useInfiniteInvestmentRoundActivities(
    investmentRoundId,
    { perPage: count },
    {
      enabled,
      onError(err) {
        captureSentryError(
          `LatestProjectActivity: error fetching activities for slug: ${slug}, investment round: ${investmentRoundId}`,
          err
        );
      },
      select: useCallback(
        (data: InfiniteData<CoreSDK.PropertyInvestmentRoundActivitiesQuery>) => {
          // @ts-expect-error timelineData is not part of the original response
          data.timelineData = data.pages[0].propertyInvestmentRoundActivities.map((activity, index) =>
            mapActivityToTimelineDataItem(activity, intl, index)
          );
          return data;
        },
        [intl]
      ),
    }
  ) as Omit<UseInfiniteQueryResult<CoreSDK.PropertyInvestmentRoundActivitiesQuery, Error>, 'data'> & {
    data: (InfiniteData<CoreSDK.PropertyInvestmentRoundActivitiesQuery> & { timelineData: DataItem[] }) | undefined;
  };

  if (
    projectActivity.isIdle ||
    projectActivity.isError ||
    projectActivity.data?.timelineData == null ||
    projectActivity.data?.timelineData.length === 0
  ) {
    return null;
  }

  return (
    <>
      <Typography variant="displayXSRegular" color="strong">
        {intl.formatMessage({ id: 'property.tabs.description.activity' })}
      </Typography>
      <Spacer height="16" />
      <Timeline data={projectActivity.data?.timelineData || []} loading={projectActivity.isLoading} />
      <Spacer height="24" />

      {projectActivity.data?.pages[0].propertyInvestmentRoundActivities.length === 0 ? null : (
        <>
          <a data-testid="abc" href={`#${ProjectDetailTabs.Activity}`}>
            <Typography variant="labelSMRegular" color="brand">
              {intl.formatMessage({ id: 'global.showMore' })}
            </Typography>
          </a>
          <Spacer height="32" />
        </>
      )}
    </>
  );
}

export default PropertyDescription;
