import React, { FC } from 'react';
import styled from 'styled-components';

import { TestIds } from '@investown/fe/test-utils/testIds';

import Lang from 'util/IntlMessages';
import Button from 'components/Button';
import Spacer from 'components/Spacer';
import Typography from 'components/V2/Typography/Typography';
import { ReactComponent as ErrorSVG } from 'assets/images/errors/error.svg';

interface ContractAgreementErrorProps {
  closeModal: () => void;
}

const ContractAgreementError: FC<ContractAgreementErrorProps> = ({ closeModal }) => (
  <Wrapper>
    <ErrorSVG width="153px" />
    <Spacer height="40px" />
    <h1>
      <Typography variant="displayLGMedium" color="strong">
        <Lang id="newInvestmentContractModal.error.header" />
      </Typography>
    </h1>
    <Spacer height="16px" />
    <Typography variant="bodyBASERegular" color="medium">
      <Lang id="newInvestmentContractModal.error.description" />
    </Typography>
    <Spacer height="48px" />
    <StyledButton data-testid={TestIds.CloseNewInvestmentContractModal} color="primary" onClick={closeModal} block>
      <Lang id="newInvestmentContractModal.error.button" />
    </StyledButton>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 632px;
  margin: auto;
`;

const StyledButton = styled(Button)`
  max-width: 300px;
  margin-top: ${(props) => props.theme.margins.huge};
`;

export default ContractAgreementError;
