import React, { FC } from 'react';

import { ReactComponent as IconSVG } from 'assets/images/icons/three-dots-icon.svg';
import Icon, { IconProps } from 'components/V2/Icons';

const ThreeDots: FC<IconProps> = (props) => {
  const { color, fillColor, hoverColor, className, width } = props;

  return (
    <Icon color={color} fillColor={fillColor} hoverColor={hoverColor} className={className} width={width}>
      <IconSVG />
    </Icon>
  );
};

export default ThreeDots;
