import styled from 'styled-components';

export default styled.button`
  margin: initial;
  padding: initial;
  border: none;
  color: inherit;
  background: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;
