import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PATH_USER_VERIFICATION } from 'routes/routesPaths';
import OnboardingBanner, { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';
import { ReactComponent as Person } from 'assets/images/icons/onboardingIcons/person.svg';

const VerificationInProgress: FC<{
  inflectedFirstName: string;
  isLegalEntity?: boolean;
  direction: OnboardingBannerDirection;
}> = ({ inflectedFirstName, isLegalEntity, direction }) => {
  const intl = useIntl();
  const heading = `${inflectedFirstName}, ${intl.formatMessage({
    id: isLegalEntity
      ? 'widgets.onboarding.legalEntity.verificationInProgress.heading'
      : 'widgets.onboarding.verificationInProgress.heading',
  })}`;
  const history = useHistory();

  return (
    <OnboardingBanner
      direction={direction}
      heading={heading}
      description={intl.formatMessage({ id: 'widgets.onboarding.verificationInProgress.description' })}
      buttonText={intl.formatMessage({ id: 'widgets.onboarding.verificationInProgress.button' })}
      buttonOnClick={() => history.push(PATH_USER_VERIFICATION)}
      icon={<Person />}
    />
  );
};

export default VerificationInProgress;
