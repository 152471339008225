import { UseInfiniteQueryResult, UseQueryResult, useInfiniteQuery, useQuery } from 'react-query';

import {
  CoreSDK,
  PartnerRelatedProjectsRequest,
  QueryKeys,
  getInvestedPropertyRoundRepaymentStatistics,
  getInvestmentRoundFees,
  getPartnerRelatedProjects,
  refetchIntervals,
} from '@investown/fe/api-sdk';

export const useInvestedPropertyRoundRepaymentStatistics = ({
  propertyInvestmentRoundId,
  isEnabled,
}: {
  propertyInvestmentRoundId: string;
  isEnabled?: boolean;
}): UseQueryResult<CoreSDK.InvestedPropertyRoundRepaymentStatisticsQuery, Error> =>
  useQuery<CoreSDK.InvestedPropertyRoundRepaymentStatisticsQuery, Error>(
    [QueryKeys.InvestedPropertyRoundRepaymentStatistics, { propertyInvestmentRoundId }],
    () => getInvestedPropertyRoundRepaymentStatistics(propertyInvestmentRoundId),
    {
      enabled: isEnabled || true,
      refetchInterval: false,
    }
  );

export const useInvestmentRoundFees = ({
  investmentRoundId,
}: {
  investmentRoundId: string;
}): UseQueryResult<CoreSDK.InvestmentRoundFeesQuery, Error> =>
  useQuery<CoreSDK.InvestmentRoundFeesQuery, Error, CoreSDK.InvestmentRoundFeesQuery, [string, string]>(
    [QueryKeys.InvestmentRoundFees, investmentRoundId],
    () => getInvestmentRoundFees(investmentRoundId),
    { refetchInterval: refetchIntervals.sixtyMinutes, staleTime: 0 }
  );

export function usePartnerRelatedProjects({
  pageSize,
  slug,
  borrowerId,
  enabled,
}: {
  pageSize: number;
  slug: string;
  borrowerId?: string;
  enabled?: boolean;
}): UseInfiniteQueryResult<CoreSDK.RelatedPropertiesQuery, Error> {
  return useInfiniteQuery<
    CoreSDK.RelatedPropertiesQuery,
    Error,
    CoreSDK.RelatedPropertiesQuery,
    [string, PartnerRelatedProjectsRequest]
  >(
    [QueryKeys.PartnerRelatedProjects, { slug, borrowerId, page: 0, perPage: pageSize }],
    ({ pageParam = 0, queryKey: [, variables] }) =>
      getPartnerRelatedProjects({
        slug: variables.slug,
        borrowerId: variables.borrowerId,
        page: pageParam,
        perPage: variables.perPage,
      }),
    {
      getNextPageParam: (lastPage, pages) => (lastPage.relatedProperties.length < pageSize ? undefined : pages.length),
      refetchInterval: refetchIntervals.thirtySeconds,
      enabled,
    }
  );
}
