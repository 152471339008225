import React, { FC } from 'react';

import { ReactComponent as IconSVG } from 'assets/images/icons/chevron-forward-outline-sharp.svg';
import Icon, { IconProps } from 'components/V2/Icons';

const ChevronForwardSharp: FC<IconProps> = (props) => {
  const { color, className, fillColor, hoverColor, width } = props;

  return (
    <Icon color={color} className={className} fillColor={fillColor} hoverColor={hoverColor} width={width}>
      <IconSVG />
    </Icon>
  );
};

export default ChevronForwardSharp;
