import React, { FC } from 'react';
import styled from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

import { SpacingSizeName } from 'styles/theme';

interface Props extends WithChildren {
  $size: SpacingSizeName | 'none';
  className?: string;
}

const Spacing: FC<Props> = ({ children, $size, className }) => (
  <Container $size={$size} className={className}>
    {children}
  </Container>
);

const Container = styled.div<Props>`
  padding: 0 ${({ $size, theme }) => ($size === 'none' ? 'none' : theme.spacing[$size as SpacingSizeName])};
`;
export default Spacing;
