import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import OnboardingBanner, { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';
import { ReactComponent as Person } from 'assets/images/icons/onboardingIcons/person.svg';

const WaitingForApproval: FC<{ inflectedFirstName: string; direction: OnboardingBannerDirection }> = ({
  inflectedFirstName,
  direction,
}) => {
  const intl = useIntl();

  return (
    <OnboardingBanner
      direction={direction}
      heading={intl.formatMessage(
        { id: 'widgets.onboarding.waitingForApproval.heading' },
        { salutation: inflectedFirstName }
      )}
      description={intl.formatMessage({ id: 'widgets.onboarding.waitingForApproval.description' })}
      icon={<Person />}
    />
  );
};

export default WaitingForApproval;
