export enum FeatureFlags {
  ShowLoanActivityTabWeb = 'showLoanActivityTabWeb',
  ShowLoanActivityTabExtendedWeb = 'showLoanActivityTabExtendedWeb',
  ShowAutoinvestWeb = 'showAutoinvestWeb',
  ShowRepaymentScheduleWeb = 'showRepaymentSchedule',
}

export const FallbackFeatureFlagValues = {
  [FeatureFlags.ShowLoanActivityTabWeb]: false,
  [FeatureFlags.ShowLoanActivityTabExtendedWeb]: false,
  [FeatureFlags.ShowAutoinvestWeb]: false,
  [FeatureFlags.ShowRepaymentScheduleWeb]: false,
};
