import React from 'react';
import styled from 'styled-components';
import markdown from '@khanacademy/simple-markdown';

import ParseItem from './ParseItem';

import Spacer from 'components/V2/Spacer/Spacer';

const Markdown: React.FC<{ children: string }> = ({ children }) => (
  <Wrapper>
    {markdown.defaultRawParse(children).map((node, index) => (
      <div className="markdown-element" key={`${node.type}-${index.toString()}`}>
        {ParseItem({ node, key: index.toString() })}
        {index < markdown.defaultRawParse(children).length - 1 && <Spacer height="small" />}
      </div>
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  flex-direction: column;
  .markdown-element:first-child > * {
    margin-top: 0;
  }
`;

export default Markdown;
