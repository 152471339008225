import React, { FC } from 'react';
import styled from 'styled-components';

import { MarginSizeName } from 'styles/theme';

interface SpacerProps {
  height: string | MarginSizeName;
}

const SpacerDeprecated: FC<SpacerProps> = ({ height }) => <StyledSpacer height={height} />;

const StyledSpacer = styled.div<SpacerProps>`
  display: block;
  height: ${({ theme, height }) => theme.margins[height as MarginSizeName] || height};
  min-height: ${({ theme, height }) => theme.margins[height as MarginSizeName] || height};
`;

export default SpacerDeprecated;
