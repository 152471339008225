import React, { FC } from 'react';

import { ReactComponent as IconSVG } from '@investown/fe/ui-utils/icons/Premium.svg';

import Icon, { IconProps } from 'components/Icons';

const PremiumIcon: FC<IconProps> = (props) => {
  const { color, className, width } = props;

  return (
    <Icon color={color} fillColor="transparent" className={className} width={width}>
      <IconSVG width={width} height={width} />
    </Icon>
  );
};

export default PremiumIcon;
