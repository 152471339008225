/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import styled from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

export enum AlignDirections {
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
  Center = 'center',
  SpaceBetween = 'space-between',
  Baseline = 'baseline',
  Stretch = 'stretch',
}

interface Props extends WithChildren {
  className?: string;
  alignVertical?: AlignDirections;
  alignHorizontal?: AlignDirections;
  alignSelf?: AlignDirections;
}

const FlexRow: FC<Props> = ({
  children,
  className,
  alignVertical = AlignDirections.FlexStart,
  alignHorizontal = AlignDirections.FlexStart,
  alignSelf,
}) => (
  <Row className={className} $alignVertical={alignVertical} $alignHorizontal={alignHorizontal} $alignSelf={alignSelf}>
    {children}
  </Row>
);

const Row = styled.div<{
  $alignVertical: AlignDirections;
  $alignHorizontal: AlignDirections;
  $alignSelf?: AlignDirections;
}>`
  display: flex;
  flex: 1;
  ${({ $alignSelf }) => $alignSelf && `align-self: ${$alignSelf};`}

  ${({ $alignVertical }) => $alignVertical && `align-items: ${$alignVertical};`}

  ${({ $alignHorizontal }) => $alignHorizontal && `justify-content: ${$alignHorizontal};`}
`;

export default FlexRow;
