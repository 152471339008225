import React from 'react';
import styled from 'styled-components';

import CheckOutline from 'components/V2/Icons/CheckOutline';
import AlertTriangle from 'components/V2/Icons/AlertTriangle';
import InfoLabel from 'components/V2/InfoLabel/InfoLabel';
import TooltipInfo from 'components/V2/Icons/TooltipInfo';
import { theme } from 'styles/theme';

interface AlertProps {
  testID?: string;
  type?: 'success' | 'info' | 'warning' | 'error';
  variant?: 'slim' | 'regular';
  message: React.ReactNode;
  showIcon?: boolean;
  style?: React.CSSProperties;
}

const Alert: React.FC<AlertProps> = ({ testID, type = 'error', variant = 'slim', message, showIcon, style }) => {
  const getColors = () => {
    switch (type) {
      case 'success':
        return {
          backgroundColor: theme.colorTokens.utility.success.subtle,
          textColor: theme.colorTokens.utility.success.strong,
        };
      case 'info':
        return {
          backgroundColor: theme.colorTokens.surface.brandFaded25,
          textColor: theme.colorTokens.text.brand,
        };
      case 'warning':
        return {
          backgroundColor: theme.colorTokens.utility.warning.subtle,
          textColor: theme.colorTokens.utility.warning.strong,
        };
      case 'error':
      default:
        return {
          backgroundColor: theme.colorTokens.utility.error.subtle,
          textColor: theme.colorTokens.utility.error.strong,
        };
    }
  };

  const getIcon = () => {
    const { textColor } = getColors();
    switch (type) {
      case 'success':
        return (
          <CheckOutlineIcon
            color={textColor}
            hoverColor={textColor}
            fillColor="transparent"
            width="20px"
            heightInherit
          />
        );
      case 'info':
        return (
          <TooltipInfo color={textColor} hoverColor={textColor} fillColor="transparent" width="20px" heightInherit />
        );
      case 'warning':
      case 'error':
      default:
        return (
          <AlertTriangle color={textColor} fillColor="transparent" hoverColor={textColor} width="20px" heightInherit />
        );
    }
  };

  const { backgroundColor, textColor } = getColors();

  return (
    <InfoLabel
      testID={testID}
      icon={showIcon ? getIcon() : undefined}
      backgroundColor={backgroundColor}
      text={message}
      verticalAlign="center"
      textFont="labelSMRegular"
      textColor={textColor}
      variant={variant}
      style={style}
      fullWidth
    />
  );
};

const CheckOutlineIcon = styled(CheckOutline)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export default Alert;
