import React, { FC, useContext } from 'react';
import styled, { createGlobalStyle, ThemeContext } from 'styled-components';

import Tooltip, { TooltipPlacement } from 'components/V2/Tooltip/Tooltip';
import InformationCircleOutlineIcon from 'components/Icons/InformationCircleOutlineIcon';

interface Props {
  className?: string;
  title: React.ReactNode;
  placement?: TooltipPlacement;
}

const InformationCircle: FC<Props> = ({ title, placement = 'right', className }) => {
  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      <div className={className}>
        <Tooltip className={className} textComponent={title as React.ReactNode} position={placement}>
          <span>
            <InformationCircleOutlineIcon width={theme.icons.size.small} fillColor="transparent" />
          </span>
        </Tooltip>
        <TooltipStyles />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;

  i {
    font-weight: ${(props) => props.theme.font.weight.medium};
  }

  svg {
    display: flex;
    align-items: center;
    opacity: 0.5;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
`;

const TooltipStyles = createGlobalStyle`
  .portfolio-tooltip.tooltip-bubble {
    background-color: ${({ theme }) => theme.colorTokens.surface.inverted900};
    span {
      color: ${({ theme }) => theme.colorTokens.text.invertedStrong};
      line-height: ${({ theme }) => theme.typography.bodySMRegular};
    }
  }
`;

export default InformationCircle;
