import React from 'react';
import { generatePath, useLocation } from 'react-router-dom';

import { PATH_PROPERTY } from 'routes/routesPaths';

export const createPropertyUrl = (slug: string): string =>
  generatePath(PATH_PROPERTY, {
    slug,
  });

export const createAppUrl = (path: string): string => {
  if (!window) {
    throw Error('Window is not defined');
  }

  return `${window.location.protocol}//${window.location.host}${path}`;
};

export const parseParamsFromHash = (hash: string): { [key: string]: string } => {
  const result: { [key: string]: string } = {};

  if (hash.length > 1) {
    hash
      .substr(1)
      .split('&')
      .forEach((keyValueString) => {
        const [key, value] = keyValueString.split('=');
        result[key] = value;
      });
  }

  return result;
};

export const useQueryParams = (): URLSearchParams => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
