import React from 'react';
import styled from 'styled-components';

import Undraw from 'components/Undraw';
import { UndrawImages } from 'constants/UndrawImages';
import Paragraph from 'components/Typography/Paragraph';
import SpacerDeprecated from 'components/SpacerDeprecated';
import ErrorText from 'components/Errors/ErrorText';

interface Props {
  error?: Error | null;
}

const ErrorWithImage: React.FC<Props> = ({ error }) => (
  <Container>
    <Undraw name={UndrawImages.BugFixing} />
    <SpacerDeprecated height="medium" />
    <Paragraph>
      <ErrorText error={error} />
    </Paragraph>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default ErrorWithImage;
