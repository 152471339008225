import React, { FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { currencyFormat } from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import Lang from 'util/IntlMessages';
import Button from 'components/Button';
import Spacer from 'components/V2/Spacer/Spacer';

interface Props {
  isPrimaryInvestmentAvailable: boolean;
  minInvestment: number;
  investmentCurrency: string;
  investmentAmountAvailable: number;
  onInvestmentButtonClick: () => void;
  investmentDisabled: boolean;
}

const InvestButton: FC<Props> = (props) => {
  const intl = useIntl();
  const {
    onInvestmentButtonClick,
    isPrimaryInvestmentAvailable,
    minInvestment,
    investmentCurrency,
    investmentAmountAvailable,
    investmentDisabled,
  } = props;

  const minInvestmentFormatted = currencyFormat({
    input: minInvestment,
    currency: investmentCurrency,
    locale: intl.locale,
  });

  return (
    <>
      {isPrimaryInvestmentAvailable && (
        <>
          <Spacer height="huge" />
          <Button
            data-testid={TestIds.Invest}
            color="primary"
            disabled={investmentAmountAvailable <= 0 || investmentDisabled}
            block
            onClick={onInvestmentButtonClick}
          >
            {investmentAmountAvailable <= 0 ? (
              <Lang id="widgets.propertyInvestment.investButtonDisabled" />
            ) : (
              <Lang id="widgets.propertyInvestment.investButton" />
            )}
          </Button>
        </>
      )}
      {minInvestment > 0 && isPrimaryInvestmentAvailable && (
        <>
          <Spacer height="regular" />
          <MinimalInvestment>
            <Lang id="widgets.propertyInvestment.minInvestment" values={{ min: minInvestmentFormatted }} />
          </MinimalInvestment>
        </>
      )}
    </>
  );
};

const MinimalInvestment = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colorTokens.text.medium};
  margin-bottom: 0;
`;

export default InvestButton;
