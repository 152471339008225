import React, { FC } from 'react';
import styled from 'styled-components';

import { BorderColorsName } from '../../../styles/theme';

interface Props {
  className?: string;
  color: BorderColorsName;
}
const DividerHorizontal: FC<Props> = ({ className, color = 'faded' }) => (
  <Divider color={color} className={className} />
);

const Divider = styled.div<Props>`
  height: 1px;
  background-color: ${({ theme, color }) => theme.border[color as BorderColorsName]};
`;

export default DividerHorizontal;
