import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { IntlShape, useIntl } from 'react-intl';
import MediaQuery from 'react-responsive';

import { currencyFormat, numberWithDecimal } from '@investown/fe/common-utils';
import { CoreSDK } from '@investown/fe/api-sdk';

import Table from 'components/Table';
import LoadingWidget from 'components/V2/LoadingWidget/LoadingWidget';
import Heading from 'components/Typography/Heading';
import Spacer from 'components/Spacer';
import DividerHorizontal from 'components/V2/Divider/DividerHorizontal/DividerHorizontal';

enum FinancialAnalysisTableColumns {
  MetricName = 'metricName',
  MetricValue = 'metricValue',
}

interface Columns {
  [FinancialAnalysisTableColumns.MetricName]: string;
  [FinancialAnalysisTableColumns.MetricValue]: string;
}

interface DataSource extends Columns {
  key: string;
}

const FinancialAnalysisTable: FC<{ year: number; values: CoreSDK.PropertyFinancialDataObjectType[] }> = ({
  year,
  values,
}) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const [tableData, setTableData] = useState<DataSource[]>();

  const columns = [
    {
      title: year.toString(),
      dataIndex: FinancialAnalysisTableColumns.MetricName,
    },
    {
      dataIndex: FinancialAnalysisTableColumns.MetricValue,
    },
  ];

  useEffect(() => {
    const data: DataSource[] = [];
    if (values && values.length > 0) {
      values.map((row) =>
        data.push({
          key: row.metricName,
          [FinancialAnalysisTableColumns.MetricName]: row.metricName,
          [FinancialAnalysisTableColumns.MetricValue]: formatMetricValue({
            metricValue: row.metricValue,
            unit: row.unit || '',
            intl,
          }),
        })
      );

      setTableData(data);
    }
  }, [setTableData, intl.locale, values, intl]);

  if (values.length === 0) {
    return <LoadingWidget />;
  }
  return tableData ? (
    <div>
      <MediaQuery minWidth={theme.breakpoints.medium}>
        <StyledTable columns={columns} dataSource={tableData} rowHoverable={false} />
      </MediaQuery>
      <MediaQuery maxWidth={theme.breakpoints.medium}>
        <Heading>{year}</Heading>
        <Spacer height="extraLarge" />
        {tableData.map((property) => (
          <>
            <TableRow key={year}>
              <TableColumn isName>{property[FinancialAnalysisTableColumns.MetricName]}</TableColumn>
              <TableColumn>{property[FinancialAnalysisTableColumns.MetricValue]}</TableColumn>
            </TableRow>
            <Spacer height="medium" />
          </>
        ))}
        <Spacer height="large" />
        <DividerHorizontal />
        <Spacer height="large" />
      </MediaQuery>
    </div>
  ) : null;
};

function formatMetricValue({
  metricValue,
  unit,
  intl,
}: {
  metricValue: number;
  unit: string;
  intl: IntlShape;
}): string {
  switch (unit) {
    case 'CZK':
      return currencyFormat({ input: metricValue, currency: unit, locale: intl.locale });
    case '%':
      return `${numberWithDecimal(metricValue * 100, 1)} %`;
    default:
      return metricValue.toString();
  }
}

const StyledTable = styled(Table)`
  td:nth-child(2) {
    text-align: right;
  }
  padding-bottom: 16px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TableColumn = styled.div<{ isName?: boolean }>`
  ${({ isName }) =>
    isName &&
    `
    padding-right: 32px;
  `}
`;

export default FinancialAnalysisTable;
