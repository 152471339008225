import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PATH_ABILITY_TO_BEAR_LOSS, PATH_INVESTOR_QUESTIONNAIRE } from 'routes/routesPaths';
import OnboardingBanner, { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';
import { ReactComponent as Document } from 'assets/images/icons/onboardingIcons/document.svg';

interface Props {
  inflectedFirstName: string;
  isMissingSNZ?: boolean;
  direction: OnboardingBannerDirection;
}

function CompletionOfInvestmentSuitability({ inflectedFirstName, isMissingSNZ, direction }: Props) {
  const intl = useIntl();
  const history = useHistory();

  return (
    <OnboardingBanner
      direction={direction}
      heading={intl.formatMessage(
        { id: 'widgets.completionOfInvestmentSuitability.heading' },
        { salutation: inflectedFirstName }
      )}
      description={intl.formatMessage({ id: 'widgets.completionOfInvestmentSuitability.description' })}
      buttonText={intl.formatMessage({ id: 'widgets.completionOfInvestmentSuitability.button' })}
      buttonOnClick={() => history.push(isMissingSNZ ? PATH_ABILITY_TO_BEAR_LOSS : PATH_INVESTOR_QUESTIONNAIRE)}
      icon={<Document />}
    />
  );
}

export default CompletionOfInvestmentSuitability;
