export enum AnalyticsAction {
  AUTOINVEST_STEP = 'autoinvest_step',
  AUTOINVEST_BANK_DETAILS_COPIED = 'autoinvest_bank_details_copied',
  AUTOINVEST_ACTIVATED = 'autoinvest_activated',
  AUTOINVEST_DEACTIVATED = 'autoinvest_deactivated',
  AUTOINVEST_PARAMETERS_CHANGED = 'autoinvest_parameters_changed',
  ABILITY_TO_BEAR_LOSS_START = 'ability_to_bear_loss_start',
  ABILITY_TO_BEAR_LOSS_ANSWERED = 'ability_to_bear_loss_answered',
  ABILITY_TO_BEAR_LOSS_REOPENED = 'ability_to_bear_loss_reopened',
  ABILITY_TO_BEAR_LOSS_COMPLETE = 'ability_to_bear_loss_complete',
  ADD_MONEY_OVERVIEW_CLICK = 'add_money_overview_click',
  BANK_REDIRECT_OVERVIEW_CLICK = 'bank_redirect_overview_click',
  BIOMETRIC_PROFILE_SELECT = 'biometric_profile_select',
  CALCULATOR_OVERVIEW_CLICK = 'calculator_overview_click',
  CANCEL_ALL_SECONDARY_MARKET_OFFERS = 'cancel_all_secondary_market_offers',
  DOCS_PROFILE_CLICK = 'docs_profile_click',
  DOTS_OVERVIEW_CLICK = 'dots_overview_click',
  FIRST_INVESTMENT = 'user_first_investment',
  FIX_VERIFY_OVERVIEW_CLICK = 'fix_verify_overview_click',
  HISTORY_OVERVIEW_CLICK = 'history_overview_click',
  HISTORY_OVERVIEW_MODAL_CLICK = 'history_overview_modal_click',
  INVEST_INCREASE_PROPERTY_DETAIL_CLICK = 'invest_increase_property_detail_click',
  INVEST_OVERVIEW = 'invest_overview',
  INVEST_PROPERTY_DETAIL_CLICK = 'invest_property_detail_click',
  INVEST_WITHDRAW_PROPERTY_DETAIL_CLICK = 'invest_withdraw_property_detail_click',
  LANGUAGE_PROFILE_CLICK = 'language_profile_click',
  LOGIN = 'login',
  LOGIN_ERROR = 'login_error',
  LOGIN_START = 'login_start',
  LOGOUT_DIALOG_CANCEL = 'logout_dialog_cancel',
  LOGOUT_DIALOG_CONFIRM = 'logout_dialog_confirm',
  LOGOUT_DIALOG_SHOW = 'logout_dialog_show',
  NOTIFICATIONS_MODAL_REQUEST_BUTTON_CLICK = 'notifications_modal_request_button_click',
  NOTIFICATIONS_MODAL_SUSPEND_BUTTON_CLICK = 'notifications_modal_suspend_button_click',
  NOTIFICATIONS_PROFILE_SELECT = 'notifications_profile_select',
  NOTIFICATIONS_REQUEST_GRANTED = 'notifications_request_granted',
  NOTIFICATIONS_REQUEST_DENIED = 'notifications_request_denied',
  PASSWORD_PROFILE_CLICK = 'password_profile_click',
  PROFILE_BUTTON_CLICK = 'profile_button_click',
  PROFILE_PREMIUM_WIDGET_CLICK = 'profile_premium_widget_click',
  PROFILE_RATEUS_CLICK = 'profile_rateus_click',
  PROFIT_OVERVIEW_CLICK = 'profit_overview_click',
  PROPERTIES_TOGGLE_SELECT = 'properties_toggle_select',
  PROPERTY_DETAIL_BACK_CLICK = 'property_detail_back_click',
  PROPERTY_DETAIL_CLICK = 'property_detail_click',
  PROPS_MINE_SELECT_CLICK = 'props_mine_select_click',
  PROPS_PREMIUM_MODAL_CLOSE = 'props_premium_modal_close',
  PROPS_PREMIUM_MODAL_MORE_CLICK = 'props_premium_modal_more_click',
  PROPS_PREMIUM_MODAL_SHOWN = 'props_premium_modal_shown',
  PROPS_PRIMARY_SELECT_CLICK = 'props_primary_select_click',
  PROPS_SECONDARY_SELECT_CLICK = 'props_secondary_select_click',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  SELECT_ITEM = 'select_item',
  SELL_PROPERTY_DETAIL_CLICK = 'sell_property_detail_click',
  SETTINGS_OTHER_TOGGLE_ANIMATIONS_CONFETTI = 'settings_other_toggle_animations_confetti',
  SHOW_NEW_OVERVIEW_CLICK = 'show_new_overview_click',
  SIGN_UP = 'sign_up',
  SIGN_UP_ERROR = 'sign_up_error',
  SIGN_UP_EMAIL_COMPLETE = 'sign_up_email_complete',
  SIGN_UP_NAME_COMPLETE = 'sign_up_name_complete',
  SIGN_UP_PASSWORD_COMPLETE = 'sign_up_password_complete',
  SIGN_UP_START = 'sign_up_start',
  STARTUP_IS_PREMIUM = 'startup_is_premium',
  STARTUP_LANGUAGE = 'startup_language',
  STARTUP_NOTIFICATIONS = 'startup_notifications',
  STARTUP_OS_SCHEME = 'startup_os_scheme',
  SUPPORT_EMAIL_PROFILE_CLICK = 'support_email_profile_click',
  SUPPORT_PHONE_PROFILE_CLICK = 'support_phone_profile_click',
  VERIFICATION_ADDRESS_COMPLETE = 'verification_address_complete',
  VERIFICATION_BANK_ACCOUNT_COMPLETE = 'verification_bank_account_complete',
  VERIFICATION_DOCUMENTS_CAPTURE_COMPLETE = 'verification_documents_capture_complete',
  VERIFICATION_DOCUMENTS_CAPTURE_START = 'verification_documents_capture_start',
  VERIFICATION_FIRST_PHASE_COMPLETE = 'verification_first_phase_complete',
  VERIFICATION_PEP_COMPLETE = 'verification_pep_complete',
  VERIFICATION_PERSONALIDENTIFIER_COMPLETE = 'verification_personalidentifier_complete',
  VERIFICATION_PHONE_START = 'verification_phone_start',
  VERIFICATION_PHONE_COMPLETE = 'verification_phone_complete',
  VERIFICATION_QUESTIONNAIRE_ANSWERED = 'verification_questionnaire_answered',
  VERIFICATION_QUESTIONNAIRE_COMPLETE = 'verification_questionnaire_complete',
  VERIFICATION_QUESTIONNAIRE_REOPENED = 'verification_questionnaire_reopened',
  VERIFICATION_QUESTIONNAIRE_START = 'verification_questionnaire_start',
  VERIFICATION_QUESTIONNAIRE_UNSUITABLE = 'verification_questionnaire_unsuitable',
  VERIFICATION_QUESTIONNAIRE_P2_COMPLETE = 'verification_questionnaire_p2_complete',
  VERIFICATION_QUESTIONNAIRE_P2_ANSWERED = 'verification_questionnaire_p2_answered',
  VERIFICATION_QUESTIONNAIRE_P2_REOPENED = 'verification_questionnaire_p2_reopened',
  VERIFICATION_QUESTIONNAIRE_P2_START = 'verification_questionnaire_p2_start',
  VERIFICATION_SECOND_PHASE_START = 'verification_second_phase_start',
  VERIFICATION_SECOND_PHASE_COMPLETED = 'verification_second_phase_completed',
  VERIFICATION_SOF_COMPLETE = 'verification_sof_complete',
  VERIFICATION_START = 'verification_start',
  VERIFICATION_STEP = 'verification_step',
  VERIFICATION_UPGRADE_COMPLETE = 'verification_upgrade_complete',
  VERIFICATION_UPGRADE_START = 'verification_upgrade_start',
  VERIFY_OVERVIEW_CLICK = 'verify_overview_click',
  WEALTH_OVERVIEW_CLICK = 'wealth_overview_click',
  WITHDRAW = 'withdraw',
  WITHDRAW_OVERVIEW_MODAL_CLICK = 'withdraw_overview_modal_click',
  INVEST = 'invest',
  SCREEN_VIEW = 'screen_view',
  SCREEN_APP_VIEW = 'screen_app_view',
  REGISTRATION_STEP = 'registration_step',
  VIEW_ITEM = 'view_item',
  ERROR = 'error',
  PASSWORD_RESET = 'password_reset',
  LOGOUT = 'logout',
  INVITE_FRIENDS = 'invite_friends',
  LEAD = 'lead',
  NOTIFICATION_PREFERENCES = 'notification_preferences',
  REFERRAL_ICON_CLICKED = 'referral_icon_clicked',
  PROJECT_DETAIL_TAB_OPENED = 'project_detail_tab_opened',
}
