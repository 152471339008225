import React from 'react';
import { UseQueryResult } from 'react-query';

import { UserDetailsDTO, UserLevelsConfigDTO, useUserDetails } from '@investown/fe/api-sdk';

import { useUserLevelAndAllLevels } from 'hooks/useUserLevelAndAllLevels';

export type WithUserDetailsAndLevelsProps = {
  bonusYieldForUser: number | undefined;
  userLevels: {
    data: UserLevelsConfigDTO | undefined;
    isLoading: boolean;
    isError: unknown;
    isIdle: boolean;
  };
  userDetails?: UseQueryResult<UserDetailsDTO, unknown>;
  hasVipProjectsAccess: boolean;
};

const withUserDetailsAndLevels = <P extends WithUserDetailsAndLevelsProps>(
  WrappedComponent: React.ComponentType<P>,
  useUserDetailsQueryProps?: {
    refetchOnMount?: boolean | 'always';
    refetchInterval?: number | false;
  }
) => {
  const WithBonusYieldForUser: React.FC<Omit<P, keyof WithUserDetailsAndLevelsProps>> = (props) => {
    const { refetchOnMount, refetchInterval } = useUserDetailsQueryProps || {};
    const userDetails = useUserDetails({ refetchOnMount, refetchInterval });
    const { currentUserLevel, userLevels } = useUserLevelAndAllLevels(userDetails.data);

    const enhancedProps = {
      ...(props as unknown as P),
      bonusYieldForUser: currentUserLevel ? currentUserLevel.bonusYield : undefined,
      userLevels,
      userDetails,
      hasVipProjectsAccess: currentUserLevel ? currentUserLevel.vipProjectsAccess : false,
    };

    return <WrappedComponent {...enhancedProps} />;
  };

  return WithBonusYieldForUser;
};

export default withUserDetailsAndLevels;
