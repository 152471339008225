import React, { FC } from 'react';
import styled from 'styled-components';

import { CoreSDK } from '@investown/fe/api-sdk';

import FinancialAnalysisTable from './PropertyFinancialAnalysis/FinancialAnalysisTable';
import FinancialAnalysisTableLoadingSkeleton from './PropertyFinancialAnalysis/FinancialAnalysisTableLoadingSkeleton';
import FinancialAnalysisTableEmpty from './PropertyFinancialAnalysis/FinancialAnalysisTableEmpty';

import Lang from 'util/IntlMessages';
import Spacer from 'components/Spacer';
import Typography from 'components/V2/Typography/Typography';
import Markdown from 'components/V2/Markdown/Markdown';

interface Props {
  isLoading: boolean;
  data: CoreSDK.PropertyFinancialAnalysisQuery | undefined;
}

const PropertyFinancialAnalysis: FC<Props> = ({ isLoading, data }) => {
  const Header = () => (
    <HeaderWrapper>
      <Typography variant="displayXSRegular" color="strong">
        <Lang id="property.tabs.financialAnalysis" />
      </Typography>
    </HeaderWrapper>
  );

  if (isLoading) {
    return (
      <>
        <FinancialAnalysisTableLoadingSkeleton />
      </>
    );
  }

  if (!data || !data.propertyFinancialAnalysis) {
    return (
      <>
        <Header />
        <FinancialAnalysisTableEmpty />
      </>
    );
  }

  const { propertyFinancialAnalysis } = data;

  return (
    <>
      <Header />
      {propertyFinancialAnalysis.description && <Markdown>{propertyFinancialAnalysis.description}</Markdown>}
      {propertyFinancialAnalysis.financialData.length > 0 &&
        propertyFinancialAnalysis.financialData.map((table) => (
          <>
            <Spacer height="medium" />
            <FinancialAnalysisTable key={table.year} year={table.year} values={table.values} />
          </>
        ))}
    </>
  );
};

const HeaderWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

export default PropertyFinancialAnalysis;
