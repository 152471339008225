import React, { FC } from 'react';

import {
  UserDetailsDTO,
  UserVerificationDataDTO,
  UserVerificationStatus,
  useUserTimestamps,
} from '@investown/fe/api-sdk';
import { getNewContractAvailability, isSuitabilityQuestionnaireExpired } from '@investown/fe/common-utils';

import InvestmentContract from '../Steps/InvestmentContract';
import { OnboardingCallbackFnParams } from '../helpers';

import StartVerification from 'containers/Widgets/Onboarding/Steps/StartVerification';
import VerificationInProgress from 'containers/Widgets/Onboarding/Steps/VerificationInProgress';
import Declined from 'containers/Widgets/Onboarding/Steps/Declined';
import WaitingForApproval from 'containers/Widgets/Onboarding/Steps/WaitingForVerification';
import MoneyDeposit from 'containers/Widgets/Onboarding/Steps/MoneyDeposit';
import { getAppLanguageLocalVariable } from 'lngProvider';
import RequiredDocuments from 'containers/Widgets/Onboarding/Steps/LegalEntity/RequiredDocuments';
import CompletionOfInvestmentSuitability from 'containers/Widgets/Onboarding/Steps/CompletionOfInvestmentSuitability';
import Spacer from 'components/Spacer';
import { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';

interface OnboardingStepsProps {
  userDetails?: UserDetailsDTO;
  userVerificationData?: UserVerificationDataDTO;
  direction: OnboardingBannerDirection;
  isProjectWithOldContract?: boolean;
  isProjectBlockedForUser?: boolean;
  showDepositBanner?: boolean;
  callbackFn: (params: OnboardingCallbackFnParams) => void;
}

const OnboardingSteps: FC<OnboardingStepsProps> = ({
  userDetails,
  userVerificationData,
  isProjectWithOldContract,
  isProjectBlockedForUser,
  direction,
  showDepositBanner = true,
  callbackFn,
}) => {
  const userLocale = getAppLanguageLocalVariable();
  const { data: userTimestamps, isLoading: userTimestampsIsLoading } = useUserTimestamps({});
  let banner = null;

  const isNewContractAvailable = getNewContractAvailability({ userDetailsData: userDetails, userVerificationData });
  const showNewContractDocumentation = isNewContractAvailable && !isProjectWithOldContract && !isProjectBlockedForUser;

  if (userDetails) {
    const salutation = userLocale === 'cs' ? userDetails.inflectedName : userDetails.firstName;

    switch (userDetails.verificationStatus) {
      case UserVerificationStatus.NotVerified:
        banner = <StartVerification inflectedFirstName={salutation} direction={direction} />;
        break;

      case UserVerificationStatus.InProgress:
        banner = (
          <VerificationInProgress
            inflectedFirstName={salutation}
            isLegalEntity={!!userDetails.companyId}
            direction={direction}
          />
        );
        break;

      case UserVerificationStatus.WaitingForApproval:
        if (userDetails.companyId) {
          banner = <RequiredDocuments direction={direction} />;
        } else {
          banner = <WaitingForApproval inflectedFirstName={salutation} direction={direction} />;
        }
        break;

      case UserVerificationStatus.Declined:
        // eslint-disable-next-line no-case-declarations
        const declineReasons = userLocale === 'cs' ? userDetails.declineReasons?.cs : userDetails.declineReasons?.en;
        banner = <Declined inflectedFirstName={salutation} reason={declineReasons?.join(', ')} direction={direction} />;
        break;

      case UserVerificationStatus.Verified:
        if (!userTimestampsIsLoading && isSuitabilityQuestionnaireExpired({ userVerificationData, userTimestamps })) {
          banner = <CompletionOfInvestmentSuitability inflectedFirstName={salutation} direction={direction} />;
        } else if (!userTimestampsIsLoading && !userTimestamps?.abilityToBearLossQuestionnaire.endedAt) {
          banner = (
            <CompletionOfInvestmentSuitability inflectedFirstName={salutation} isMissingSNZ direction={direction} />
          );
        } else if (showNewContractDocumentation) {
          banner = (
            <InvestmentContract
              inflectedFirstName={salutation}
              direction={direction}
              isLegalEntity={!!userDetails.companyId}
              callbackFn={(params) => callbackFn(params)}
            />
          );
        } else if (!userDetails.madeDeposit && showDepositBanner) {
          banner = <MoneyDeposit inflectedFirstName={salutation} direction={direction} />;
        }
        break;
    }
  }

  return banner ? (
    <>
      {direction === 'column' && <Spacer height="huge" />}
      {banner}
      {direction === 'row' && <Spacer height="extraLarge" />}
    </>
  ) : null;
};

export default OnboardingSteps;
