import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

interface Props extends WithChildren {
  enabled?: boolean;
  flex?: boolean;
}

/**
 * Renders children using fade in effect.
 * When `enabled` prop is set to false children are also rendered but with zero opacity.
 * When `enabled` is set to true the fade in effect starts.
 */
const FadeIn: FC<Props> = ({ children, enabled = true, flex }) =>
  enabled ? <FadeInKeyframe flex={flex}>{children}</FadeInKeyframe> : <ZeroOpacity>{children}</ZeroOpacity>;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FadeInKeyframe = styled.div<{ flex?: boolean }>`
  & > *:first-child {
    animation-name: ${fadeIn};
    animation-duration: 800ms;
    animation-fill-mode: both;
    transition-timing-function: ease-out;
  }
  ${({ flex }) =>
    flex &&
    `
    flex: 1
  `}
`;

const ZeroOpacity = styled.div`
  opacity: 0;
`;

export default FadeIn;
