import React, { FC } from 'react';

import Icon, { IconProps } from '..';

import { ReactComponent as IconSVG } from 'assets/images/icons/info-in-circle.svg';

const TooltipInfo: FC<IconProps> = (props) => {
  const { color, fillColor, hoverColor, className, width, heightInherit } = props;

  return (
    <Icon
      color={color}
      fillColor={fillColor}
      hoverColor={hoverColor}
      className={className}
      width={width}
      heightInherit={heightInherit}
    >
      <IconSVG />
    </Icon>
  );
};

export default TooltipInfo;
