import React, { FC } from 'react';
import AntdTable from 'antd/es/table';
import { ColumnProps } from 'antd/es/table/interface';
import styled from 'styled-components';
import { PaginationConfig } from 'antd/lib/table';

interface Props {
  columns: ColumnProps<unknown>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSource: Record<any, any>[];
  className?: string;
  pagination?: PaginationConfig | false;
  rowHoverable?: boolean;
}

const Table: FC<Props> = ({ columns, dataSource, className, pagination, rowHoverable = true }) => (
  <StyledAntdTable
    columns={columns}
    dataSource={dataSource}
    size="middle"
    pagination={pagination || false}
    className={className}
    rowHoverable={rowHoverable}
  />
);

const StyledAntdTable = styled(AntdTable)<Props>`
  .ant-table-thead > tr > th {
    background-color: ${({ theme }) => theme.color.contentBackground};
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  /* antd library adds hover to rows by default */
  ${({ rowHoverable }) =>
    rowHoverable
      ? undefined
      : `
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: transparent;
    }`}
`;

export default Table;
