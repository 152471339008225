import React, { FC } from 'react';

import { SurfaceColorName } from '@investown/fe/common-utils';

import { SpacingSizeName } from 'styles/theme';
import LayoutContainer from 'components/V2/LayoutContainer/LayoutContainer';
import LayoutContainerContent from 'components/V2/LayoutContainerContent/LayoutContainerContent';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import Loading from 'components/V2/Loading/Loading';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';

export interface WidgetProps {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  loading?: boolean;
  backgroundColor?: SurfaceColorName | string;
  padding?: SpacingSizeName;
  width?: string;
  height?: string;
}

const Widget: FC<WidgetProps> = ({
  children,
  className,
  width = 'auto',
  height,
  title,
  loading,
  backgroundColor,
  padding = 'extraLarge',
}) => (
  <LayoutContainer
    className={className}
    width={width}
    height={height}
    padding={padding}
    backgroundColor={backgroundColor}
  >
    <LayoutContainerContent isColumn noPadding>
      {loading ? (
        <FlexRow alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.Center}>
          <Loading width="30px" height="30px" />
        </FlexRow>
      ) : (
        <>
          {title && (
            <>
              <Typography variant="bodyXLRegular" color="medium">
                {title}
              </Typography>
              <Spacer height="extraLarge" />
            </>
          )}
          {children}
        </>
      )}
    </LayoutContainerContent>
  </LayoutContainer>
);

export default Widget;
