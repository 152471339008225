import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { SurfaceColorName } from '@investown/fe/common-utils';

import { BorderRadiusName, SpacingSizeName } from 'styles/theme';

interface LayoutContainerProps {
  className?: string;
  children: ReactNode;
  width?: string;
  height?: string;
  borderRadius?: BorderRadiusName;
  backgroundColor?: SurfaceColorName | string;
  padding?: SpacingSizeName;
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({
  className,
  children,
  width,
  height,
  borderRadius = 'extraLarge',
  backgroundColor = 'background',
  padding,
}) => (
  <StyledLayoutContainer
    width={width}
    height={height}
    borderRadius={borderRadius}
    backgroundColor={backgroundColor}
    padding={padding}
    className={`layout-container ${className || ''}`}
  >
    {children}
  </StyledLayoutContainer>
);

const StyledLayoutContainer = styled.div<{
  width?: string;
  height?: string;
  borderRadius: BorderRadiusName;
  backgroundColor: SurfaceColorName | string;
  padding?: SpacingSizeName;
}>`
  border-radius: ${({ theme, borderRadius }) => theme.borderRadius[borderRadius]};
  background-color: ${({ theme, backgroundColor }) =>
    theme.colorTokens.surface[backgroundColor as SurfaceColorName] ?? backgroundColor};
  padding: ${({ theme, padding }) => (padding ? theme.spacing[padding] : 0)};
  display: flex;
  position: relative;
  width: ${({ width }) => width || 'inherit'};
  min-width: ${({ width }) => width || 'inherit'};
  height: ${({ height }) => height || 'auto'};
  min-height: ${({ height }) => height || 'auto'};
`;

export default LayoutContainer;
