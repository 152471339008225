/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import { theme } from 'styles/theme';
import Button from 'components/Button';
import Arrow from 'components/Icons/ChevronForwardSharp';
import Lang from 'util/IntlMessages';

type ExpandoItemProps = {
  text: string | React.ReactNode;
  collapsedHeight?: number;
  shouldCollapse?: boolean;
  expandingOff?: boolean;
};

const Expando: React.FC<ExpandoItemProps> = ({ text, collapsedHeight = 150, shouldCollapse, expandingOff }) => {
  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState(collapsedHeight);
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (shouldCollapse && expanded) {
      setExpanded(false);
      setHeight(collapsedHeight);
    }
  }, [shouldCollapse, expanded, collapsedHeight]);

  useEffect(() => {
    if (expandingOff) {
      setExpanded(true);
      setHeight(collapsedHeight);
    }
  }, [collapsedHeight, expandingOff]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setHeight(expanded ? contentRef.current.scrollHeight : collapsedHeight);
    }
  }, [collapsedHeight, expanded, shouldCollapse]);

  return (
    <Container aria-expanded={expanded}>
      <Content height={height} expanded={expanded}>
        <div ref={contentRef}>
          <Spacer height="medium" />
          <Typography variant="bodyBASERegular" color="medium">
            {text}
          </Typography>
        </div>
      </Content>
      {!expandingOff && (
        <>
          <Spacer height="large" />
          <ButtonWrapper>
            <StyledButton color="link" onClick={() => setExpanded(!expanded)}>
              <StyledArrow
                color={theme.colorTokens.text.brand}
                expanded={expanded}
                fillColor="transparent"
                hoverColor="transparent"
              />
              <Typography variant="labelSMMedium" color="brand">
                <Lang id={expanded ? 'global.showLess' : 'global.showMore'} />
              </Typography>
            </StyledButton>
          </ButtonWrapper>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
`;

const Content = styled.div<{ height: number; expanded: boolean }>`
  max-height: ${({ height }) => height}px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;

  &:before {
    ${({ expanded }) => (expanded ? null : `content: ''`)};
    width: 100%;
    height: 100%;
    opacity: ${({ expanded }) => (expanded ? 0 : 1)};
    transition: all 0.3s ease;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 0, white);
    z-index: 1;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  min-height: 16px;
`;

const StyledArrow = styled(Arrow)<{ expanded: boolean }>`
  transform: ${({ expanded }) => `rotate(${expanded ? -90 : 90}deg)`};
  width: 24px;
  > svg {
    margin-right: 0;
  }
`;

export default Expando;
