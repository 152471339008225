import React from 'react';
import styled from 'styled-components';

import { CoreSDK } from '@investown/fe/api-sdk';

import Tag, { TagColor } from 'components/V2/Tag/Tag';
import Lang from 'util/IntlMessages';
import { getExternalURL } from 'constants/ExternalUrl';
import Tooltip, { TooltipPlacement } from 'components/V2/Tooltip/Tooltip';

function LinkString(chunks: string) {
  return (
    <a data-testid="rating-label-risk-link" href={getExternalURL('Risk')} target="_blank">
      {chunks}
    </a>
  );
}

interface Props {
  riskCategory: CoreSDK.RiskCategory;
  size?: 'default' | 'small';
  tooltipPosition?: TooltipPlacement;
}

function RatingLabel({ riskCategory, size, tooltipPosition = 'bottom' }: Props) {
  const { ratingLetter, tagColor } = mapRiskCategoryToRatingLetter(riskCategory);

  if (!ratingLetter || !tagColor) {
    return null;
  }

  return (
    <Tooltip
      position={tooltipPosition}
      textComponent={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Lang id="property.riskCategoryTag.tooltip" values={{ a: LinkString }} />
        </span>
      }
    >
      <StyledTag color={tagColor} size={size}>
        {ratingLetter}
      </StyledTag>
    </Tooltip>
  );
}

export function mapRiskCategoryToRatingLetter(riskCategory: CoreSDK.RiskCategory): {
  ratingLetter: string | null;
  tagColor: TagColor | null;
} {
  switch (riskCategory) {
    case CoreSDK.RiskCategory.A:
      return { ratingLetter: 'A', tagColor: 'success' };
    case CoreSDK.RiskCategory.AMinus:
      return { ratingLetter: 'A-', tagColor: 'success' };
    case CoreSDK.RiskCategory.APlus:
      return { ratingLetter: 'A+', tagColor: 'success' };
    case CoreSDK.RiskCategory.B:
      return { ratingLetter: 'B', tagColor: 'warning' };
    case CoreSDK.RiskCategory.BMinus:
      return { ratingLetter: 'B-', tagColor: 'warning' };
    case CoreSDK.RiskCategory.BPlus:
      return { ratingLetter: 'B+', tagColor: 'warning' };
    case CoreSDK.RiskCategory.C:
      return { ratingLetter: 'C', tagColor: 'error' };
    case CoreSDK.RiskCategory.CMinus:
      return { ratingLetter: 'C-', tagColor: 'error' };
    case CoreSDK.RiskCategory.CPlus:
      return { ratingLetter: 'C+', tagColor: 'error' };
    default:
      return { ratingLetter: null, tagColor: null };
  }
}

const StyledTag = styled(Tag)`
  width: 32px;
  height: 32px;
  padding-left: unset;
  padding-right: unset;
  justify-content: center;
`;

export default React.memo(RatingLabel);
