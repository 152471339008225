/* eslint-disable @typescript-eslint/no-shadow */
import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Minus from 'components/V2/Icons/Minus';
import Plus from 'components/V2/Icons/Plus';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import { theme } from 'styles/theme';

type AccordionItemProps = {
  question: string;
  answer: string;
  isOpen: boolean;
  onClick: () => void;
};

const AccordionItem: React.FC<AccordionItemProps> = ({ question, answer, isOpen, onClick }) => {
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLParagraphElement>(null);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <AccordionItemWrapper role="button" tabIndex={0} onClick={onClick} aria-expanded={isOpen}>
      <AccordionItemTitle>
        <Typography variant="labelLGMedium" color="strong">
          {question}
        </Typography>
        <IconWrapper isOpen={isOpen} aria-label={isOpen ? 'Collapse' : 'Expand'}>
          {isOpen ? <Minus color={theme.colorTokens.text.brand} /> : <Plus color={theme.colorTokens.text.brand} />}
        </IconWrapper>
      </AccordionItemTitle>
      <AccordionItemDescription height={height}>
        <div ref={contentRef}>
          <Spacer height="medium" />
          <Typography variant="bodyBASERegular" color="medium">
            {answer}
          </Typography>
        </div>
      </AccordionItemDescription>
    </AccordionItemWrapper>
  );
};

type AccordionProps = {
  items: { id: number; question: string; answer: string }[];
  defaultOpenIndex?: number | null;
};

const Accordion: React.FC<AccordionProps> = ({ items, defaultOpenIndex = 0 }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(defaultOpenIndex);

  const handleClick = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <>
      {items.map((item, index) => (
        <AccordionItem
          key={item.id}
          question={item.question}
          answer={item.answer}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </>
  );
};

const AccordionItemWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.large} ${theme.spacing.large} 0`};
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.stroke.medium};
`;

const AccordionItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AccordionItemDescription = styled.div<{ height: number }>`
  max-height: ${(props) => props.height}px;
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const IconWrapper = styled.div<{ isOpen?: boolean }>`
  display: flex;
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '180' : 0)}deg);
  height: 24px;
`;

export default Accordion;
