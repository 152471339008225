/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { calculatePercentWithRound, currencyFormat } from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import HouseCross from 'components/V2/Icons/HouseCross';
import MoneyIcon from 'components/V2/Icons/Money';
import Typography from 'components/V2/Typography/Typography';
import ProgressBar from 'components/V2/ProgressBar/ProgressBar';
import Spacer from 'components/V2/Spacer/Spacer';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import ThreeDotsIcon from 'components/V2/Icons/ThreeDots';
import { theme } from 'styles/theme';
import Lang from 'util/IntlMessages';
import DividerHorizontal from 'components/V2/Divider/DividerHorizontal/DividerHorizontal';

interface Props {
  showProgressBar?: boolean;
  currentUsersTotalInvestment: number;
  investmentCurrency: string;
  currentYield?: number;
  expectedYield?: number;
  investmentOfferDropdownButton?: {
    labelTranslationKey: string;
    action: () => void;
  };
  withdrawFromInvestmentDropdownButton?: {
    labelTranslationKey: string;
    action: () => void;
  };
  bonusYieldPaidOut?: number;
}

const MyInvestment: FC<Props> = ({
  showProgressBar,
  currentUsersTotalInvestment,
  investmentCurrency,
  currentYield,
  expectedYield,
  investmentOfferDropdownButton,
  withdrawFromInvestmentDropdownButton,
  bonusYieldPaidOut,
}) => {
  const intl = useIntl();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleInvestmentOfferDropdownButtonClick = useCallback(() => {
    investmentOfferDropdownButton?.action();
  }, [investmentOfferDropdownButton]);

  const handleWithdrawFromInvestmentDropdownButtonClick = useCallback(() => {
    withdrawFromInvestmentDropdownButton?.action();
  }, [withdrawFromInvestmentDropdownButton]);

  const hasRepaymentData = currentYield !== undefined && expectedYield !== undefined;

  let percent: number | undefined;
  if (hasRepaymentData && expectedYield > 0) {
    percent = calculatePercentWithRound({ repaid: currentYield, total: expectedYield, round: 'down' });
  }

  return (
    <Container>
      <StyledFlexRow alignHorizontal={AlignDirections.SpaceBetween} alignVertical={AlignDirections.Center}>
        <LeftSide>
          <Typography variant="labelSMRegular" color="medium">
            <Lang id="widgets.myInvestment.title" />
          </Typography>
          {(investmentOfferDropdownButton || withdrawFromInvestmentDropdownButton) && (
            <DropdownButton
              ref={buttonRef}
              onClick={toggleDropdown}
              data-testid={TestIds.WithdrawFromInvestmentDropdown}
            >
              <ThreeDotsIcon />
              <DropdownContent ref={dropdownRef} className={dropdownVisible ? 'show' : ''}>
                {investmentOfferDropdownButton && (
                  <DropdownItem
                    hoverTextColor={theme.colorTokens.text.brand}
                    onClick={handleInvestmentOfferDropdownButtonClick}
                    data-testid={TestIds.OfferInvestment}
                  >
                    <MoneyIcon
                      color={theme.colorTokens.text.strong}
                      hoverColor={theme.colorTokens.text.brand}
                      fillColor="transparent"
                    />
                    <Spacer width="regular" />
                    <Typography variant="labelSMRegular" color="strong" noWrap>
                      <Lang id={investmentOfferDropdownButton.labelTranslationKey} />
                    </Typography>
                  </DropdownItem>
                )}
                {withdrawFromInvestmentDropdownButton && (
                  <DropdownItem
                    hoverBackgroundColor={theme.colorTokens.button.danger.default}
                    onClick={handleWithdrawFromInvestmentDropdownButtonClick}
                    data-testid={TestIds.WithdrawFromInvestment}
                  >
                    <HouseCross
                      color={theme.colorTokens.utility.error.strong}
                      hoverColor={theme.colorTokens.utility.error.strong}
                      fillColor="transparent"
                    />
                    <Spacer width="regular" />
                    <Typography variant="labelSMRegular" color="error" noWrap>
                      <Lang id={withdrawFromInvestmentDropdownButton.labelTranslationKey} />
                    </Typography>
                  </DropdownItem>
                )}
              </DropdownContent>
            </DropdownButton>
          )}
        </LeftSide>
        <Typography variant="labelBASEMedium" color="strong">
          {currencyFormat({
            input: currentUsersTotalInvestment,
            currency: investmentCurrency,
            locale: intl.locale,
          })}
        </Typography>
      </StyledFlexRow>
      {showProgressBar && percent !== undefined && hasRepaymentData && (
        <Body>
          <Spacer height="large" />
          <StyledFlexRow>
            <ProgressBar percent={percent} />
          </StyledFlexRow>
          <Spacer height="large" />
          <StyledFlexRow alignHorizontal={AlignDirections.SpaceBetween} alignVertical={AlignDirections.Center}>
            <Typography variant="labelSMRegular" color="medium">
              <Lang id="widgets.myInvestment.paidOut" />
            </Typography>
            <RightSide>
              <Typography variant="labelSMRegular" color="strong">
                {currencyFormat({
                  input: currentYield,
                  currency: investmentCurrency,
                  locale: intl.locale,
                  showCurrency: false,
                })}
              </Typography>
              <Spacer width="small" />
              <Typography variant="labelSMRegular" color="medium">
                /
              </Typography>
              <Spacer width="small" />
              <Typography variant="labelSMRegular" color="medium">
                {currencyFormat({
                  input: expectedYield,
                  currency: investmentCurrency,
                  locale: intl.locale,
                })}
              </Typography>
            </RightSide>
          </StyledFlexRow>
          {bonusYieldPaidOut !== undefined && (
            <>
              <Spacer height="large" />
              <DividerHorizontal />
              <Spacer height="large" />
              <StyledFlexRow alignHorizontal={AlignDirections.SpaceBetween} alignVertical={AlignDirections.Center}>
                <Typography variant="labelSMRegular" color="medium">
                  <Lang id="widgets.myInvestment.bonusYieldPaidOut" />
                </Typography>
                <Typography variant="labelSMRegular" color="strong">
                  {currencyFormat({
                    input: bonusYieldPaidOut,
                    currency: investmentCurrency,
                    locale: intl.locale,
                  })}
                </Typography>
              </StyledFlexRow>
            </>
          )}
        </Body>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.surface.subtle};
  border: 1px solid ${({ theme }) => theme.colorTokens.surface.medium};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => `${theme.spacing.large} 0`};
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DropdownButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
`;

const DropdownContent = styled.div`
  display: block;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(32px);
  position: absolute;
  top: -16px;
  left: 12px;
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  transition: all 300ms ease;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  box-shadow: 0px 24px 32px 0px ${({ theme }) => theme.colorTokens.surface.medium};

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(40px);
    transition: all 300ms ease;
  }
`;

const DropdownItem = styled.div<{ hoverTextColor?: string; hoverBackgroundColor?: string }>`
  padding: ${({ theme }) => `${theme.spacing.regular} ${theme.spacing.large}`};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.small};

  &:hover {
    ${({ hoverTextColor }) =>
      hoverTextColor &&
      `
    span {
      color: ${hoverTextColor};
    }
    svg{
      path {
      stroke: ${hoverTextColor};
    }
  }
    `}
    ${({ hoverBackgroundColor }) => hoverBackgroundColor && `background-color: ${hoverBackgroundColor};`}
  }
`;

const StyledFlexRow = styled(FlexRow)`
  padding: ${({ theme }) => `0 ${theme.spacing.large}`};
`;

export default MyInvestment;
