import React from 'react';
import styled from 'styled-components';

import Spacer from 'components/V2/Spacer/Spacer';
import Typography from 'components/V2/Typography/Typography';
import { TypographyName } from 'styles/theme';

interface Props {
  testID?: string;
  backgroundColor: string;
  text: React.ReactNode;
  textColor?: string;
  textFont?: TypographyName;
  icon?: React.ReactNode;
  verticalAlign?: 'flex-start' | 'center' | 'flex-end';
  fullWidth?: boolean;
  withoutPadding?: boolean;
  variant?: 'regular' | 'slim';
  style?: React.CSSProperties;
}

function InfoLabel({
  testID,
  icon,
  backgroundColor,
  text,
  textColor,
  textFont = 'bodyBASERegular',
  verticalAlign = 'flex-start',
  fullWidth = false,
  withoutPadding = false,
  variant = 'regular',
  style,
}: Props) {
  return (
    <Wrapper
      data-testid={testID}
      backgroundColor={backgroundColor}
      verticalAlign={verticalAlign}
      textColor={textColor}
      fullWidth={fullWidth}
      withoutPadding={withoutPadding}
      variant={variant}
      style={style}
    >
      {icon && (
        <>
          {icon}
          <Spacer width="large" />
        </>
      )}
      {text && <Typography variant={textFont}>{text}</Typography>}
    </Wrapper>
  );
}

const Wrapper = styled.div<{
  backgroundColor: string;
  textColor?: string;
  verticalAlign?: 'flex-start' | 'center' | 'flex-end';
  fullWidth: boolean;
  withoutPadding?: boolean;
  variant: 'regular' | 'slim';
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ textColor }) => `color: ${textColor}`};
  padding: ${({ withoutPadding, variant, theme }) => {
    if (withoutPadding) return 0;
    return variant === 'slim' ? '10px' : theme.spacing.large;
  }};
  border-radius: ${(props) => props.theme.borderRadius.small};
  display: flex;
  justify-content: ${({ fullWidth }) => (fullWidth ? 'flex-start' : 'space-between')};
  align-items: ${({ verticalAlign }) => verticalAlign};
  max-width: 100%;
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
  white-space: pre-line;
`;

export default InfoLabel;
