import { IntlShape } from 'react-intl';

import { intlLocaleCrossPlatform } from '@investown/fe/common-utils';

interface HandleValueFormatingParams {
  value: string | undefined;
  type: string;
  intl: IntlShape;
}
export const handleValueFormating = ({ value, type, intl }: HandleValueFormatingParams) => {
  if (!value) {
    return undefined;
  }

  if (type === 'number') {
    let numericValue = value.replace(/[^0-9.,]/g, ''); // Keep only numbers, dots, and commas

    const isLocaleUsingComma = intlLocaleCrossPlatform(intl.locale) !== 'en';

    if (isLocaleUsingComma) {
      // For locales using commas as decimal separators (e.g., cs)
      // Replace any dots with commas
      numericValue = numericValue.replace(/\./g, ',');
    } else {
      // For English locale
      numericValue = numericValue.replace(/,/g, '');
    }

    if (!numericValue) {
      return undefined;
    }
    // Split the number into integer and decimal parts based on the current locale
    const [integerPart, decimalPart] = isLocaleUsingComma ? numericValue.split(',') : numericValue.split('.');

    // Format the integer part based on the locale
    const formattedInteger = new Intl.NumberFormat(intl.locale, {
      style: 'decimal',
      useGrouping: true,
    }).format(parseFloat(integerPart));

    // Reconstruct the formatted value, preserving the decimal part with the correct separator
    const decimalSeparator = isLocaleUsingComma ? ',' : '.';
    const formattedValue =
      decimalPart !== undefined ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;

    // Limit to 2 decimal places, if necessary
    const limitedDecimalsValue = decimalPart
      ? formattedValue.slice(0, formattedValue.indexOf(decimalSeparator) + 3)
      : formattedValue;
    return limitedDecimalsValue;
  }
  return value;
};
