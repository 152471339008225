import React, { FC, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

export interface IconProps extends WithChildren {
  color?: string;
  className?: string;
  width?: string;
  fillColor?: string;
}

const Icon: FC<IconProps> = ({ color, fillColor, className, width, children }) => {
  const theme = useContext(ThemeContext);

  // Sometimes the theme is not available, so we need to handle this case
  if (!theme && (!color || !fillColor)) return null;

  return (
    <IconHolder color={color} fillColor={fillColor} className={className} width={width}>
      {children}
    </IconHolder>
  );
};

const IconHolder = styled.span<{ color?: string; fillColor?: string; width?: string }>`
  display: flex;

  &:hover svg {
    fill: ${({ fillColor, theme }) => fillColor || theme.colorTokens.text.brand};
    color: ${({ color, theme }) => color || theme.colorTokens.text.brand};
  }

  svg {
    height: intrinsic; // old deprecated callback for safari browsers - fixing 100% height bug/feature on iOS
    fill: ${({ color, theme }) => color || theme.colorTokens.text.strong};
    color: ${({ color, theme }) => color || theme.colorTokens.text.strong};
    width: ${({ width, theme }) => width || theme.icons.size.medium};
  }
`;

export default Icon;
