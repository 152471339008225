import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { currencyFormat, defaultWalletCurrency, returnOnInvestmentFloor } from '@investown/fe/common-utils';

import Spacer from 'components/V2/Spacer/Spacer';
import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';
import FlowerImage from 'assets/images/icons/flower.png';

interface Props {
  amount?: number;
  investmentLengthInMonths?: number;
  annualPercentageYield?: number;
  endPercentageBonus?: number;
}

const BoldString = (chunks: string) => (
  <Typography variant="labelBASESemiBold" color="success">
    {chunks}
  </Typography>
);

function ReturnOnInvest({
  amount = 0,
  investmentLengthInMonths,
  annualPercentageYield,
  endPercentageBonus = 0,
}: Props) {
  const intl = useIntl();

  if (!investmentLengthInMonths || !annualPercentageYield || isNaN(amount) || amount === 0) {
    return null;
  }

  const returnOnInvestment = returnOnInvestmentFloor({
    amount,
    annualPercentageYield,
    endPercentageBonus,
    investmentLengthInMonths,
  });

  if (returnOnInvestment === 0) {
    return null;
  }
  return (
    <>
      <ReturnOnInvestWrapper>
        <Image>
          <img height="67px" src={FlowerImage} alt="flower" />
        </Image>
        <Spacer width="20" />
        <Typography color="success" variant="labelBASERegular" numberOfLines={2}>
          <Lang
            id="widgets.returnOnInvestment.description"
            values={{
              value: currencyFormat({
                input: returnOnInvestment,
                currency: defaultWalletCurrency,
                locale: intl.locale,
              }),
              b: BoldString,
            }}
          />
        </Typography>
      </ReturnOnInvestWrapper>
      <Spacer height="massive" />
    </>
  );
}

const ReturnOnInvestWrapper = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.utility.success.subtle};
  padding-right: ${({ theme }) => theme.spacing.regular};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  position: relative;
  width: 100%;
  height: 70px;
`;

const Image = styled.div`
  position: relative;
  align-self: flex-start;
  width: 70px;
  min-width: 70px;
  height: 70px;
  min-height: 70px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ReturnOnInvest;
