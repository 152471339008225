import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { CoreSDK } from '@investown/fe/api-sdk';
import {
  formatAddress,
  useAnalytics,
  projectDetailTabOpenedAction,
  ProjectDetailTabs,
} from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import TabPropertyFinancialAnalysis from './TabPropertyFinancialAnalysis';
import RelatedProjectsList from './RelatedProjects/RelatedProjectsList';

import Lang from 'util/IntlMessages';
import Spacer from 'components/Spacer';
import Typography from 'components/V2/Typography/Typography';
import FlexRow from 'components/V2/Grid/FlexRow/FlexRow';
import DividerHorizontal from 'components/V2/Divider/DividerHorizontal/DividerHorizontal';
import Expando from 'components/V2/Expando/Expando';
import Markdown from 'components/V2/Markdown/Markdown';
import Dropdown from 'components/V2/Dropdown/Dropdown';
import usePropertyFinancialAnalysis from 'hooks/usePropertyFinancialAnalysis';

interface Props {
  borrowers: CoreSDK.PropertyInvestmentRoundObjectType['borrowers'];
  investmentRoundType: CoreSDK.PropertyInvestmentRoundType;
  slug: string;
}

const PropertyBorrower: FC<Props> = ({ borrowers, investmentRoundType, slug }) => {
  const intl = useIntl();
  const [selectedBorrowerId, setSelectedBorrowerId] = useState(borrowers[0]?.companyIdentifier);
  const [collapseExpando, setCollapseExpando] = useState(false);
  const selectedBorrower = borrowers.find((borrower) => borrower.companyIdentifier === selectedBorrowerId);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const borrowersMap = borrowers.map((borrower) => ({
    value: borrower.companyIdentifier,
    label: borrower.companyName,
  }));

  const { data: financialAnalysisData, isLoading } = usePropertyFinancialAnalysis({
    slug,
    borrowerId:
      borrowers.length > 1 && selectedBorrower ? selectedBorrower.companyIdentifier : borrowers[0].companyIdentifier,
  });

  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.trackEvent(
      projectDetailTabOpenedAction({
        path: location.pathname,
        tab: ProjectDetailTabs.Partner,
      })
    );
  }, [analytics, location]);

  return (
    <>
      {borrowers.length > 1 && (
        <>
          <Typography variant="labelBASERegular" color="subtle">
            <Lang id="property.detail.selectBorrower" />
          </Typography>
          <Spacer height="large" />
          <Dropdown
            id="probertyBorrower"
            data-testid={TestIds.PropertyBorrower}
            name={intl.formatMessage({ id: 'property.detail.selectBorrower' })}
            type="subtle-slim"
            defaultActiveFirstOption
            options={borrowersMap}
            onChange={(borrower) => {
              setSelectedBorrowerId(borrower.value as string);
              setCollapseExpando(true);
              if (selectRef.current) {
                selectRef.current.blur();
              }
              setTimeout(() => {
                setCollapseExpando(false);
              }, 500);
            }}
            isWithArrow
          />
        </>
      )}
      <Spacer height="extraLarge" />
      {selectedBorrower && (
        <>
          <Typography variant="displayXSRegular" color="strong">
            {selectedBorrower.companyName}
          </Typography>
          <Spacer height="extraLarge" />
          <FlexRow>
            <CompanyInfoTitle>
              <Typography variant="bodyBASERegular" color="subtle">
                <Lang id="property.detail.companyIdentifier" />
              </Typography>
            </CompanyInfoTitle>
            <Typography variant="bodyBASERegular" color="strong">
              {selectedBorrower.companyIdentifier}
            </Typography>
          </FlexRow>
          <Spacer height="small" />
          <FlexRow>
            <CompanyInfoTitle>
              <Typography variant="bodyBASERegular" color="subtle">
                <Lang id="property.detail.companyAddress" />
              </Typography>
            </CompanyInfoTitle>
            <Typography variant="bodyBASERegular" color="strong">
              {formatAddress(selectedBorrower.address)}
            </Typography>
          </FlexRow>
          <Spacer height="small" />
          <FlexRow>
            <CompanyInfoTitle>
              <Typography variant="bodyBASERegular" color="subtle">
                <Lang id="property.detail.companyRegisterEntry" />
              </Typography>
            </CompanyInfoTitle>
            <Typography variant="bodyBASERegular" color="strong">
              {selectedBorrower.registerEntry}
            </Typography>
          </FlexRow>
          <Spacer height="massive" />
          <DividerHorizontal />
          <Spacer height="massive" />
          <Expando shouldCollapse={collapseExpando} text={<Markdown>{selectedBorrower.description}</Markdown>} />
          <Spacer height="large" />
          <Expando
            shouldCollapse={collapseExpando}
            text={<TabPropertyFinancialAnalysis isLoading={isLoading} data={financialAnalysisData} />}
            expandingOff={!isLoading && (!financialAnalysisData || !financialAnalysisData.propertyFinancialAnalysis)}
          />
          <Spacer height="medium" />
          <Spacer height="massive" />
        </>
      )}
      <Typography variant="displayXSRegular" color="strong">
        <Lang id="property.detail.relatedProjects" />
      </Typography>
      {investmentRoundType === CoreSDK.PropertyInvestmentRoundType.Crowdfunding ? (
        <>
          <Spacer height="large" />
          <RelatedProjectsList
            slug={slug}
            borrowerId={borrowers.length > 1 ? selectedBorrower?.companyIdentifier : undefined}
          />
        </>
      ) : (
        <>
          <Spacer height="large" />
          <Typography variant="bodyBASERegular" color="subtle">
            <Lang id="empty.relatedProjectsList.description" />
          </Typography>
        </>
      )}
    </>
  );
};

const CompanyInfoTitle = styled.div`
  min-width: 72px;
`;

export default PropertyBorrower;
