import React, { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useMutation } from 'react-query';

import { cancelSecondaryMarketOffers } from '@investown/fe/api-sdk';

import Lang from 'util/IntlMessages';
import Modal from 'components/V2/Modal/Modal';
import Typography from 'components/V2/Typography/Typography';
import Button from 'components/Button';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import Spacer from 'components/Spacer';
import { captureSentryError } from 'util/sentry';

interface Props {
  visible: boolean;
  onClose: () => void;
  onConfirmCancelAllSecondaryMarketOffers: () => void;
  investmentRoundId: string;
}

const SecondaryMarketOffersCancelModal: FC<Props> = ({
  visible,
  onClose,
  onConfirmCancelAllSecondaryMarketOffers,
  investmentRoundId,
}) => {
  const intl = useIntl();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: cancelAllSecondaryMarketOffers } = useMutation(cancelSecondaryMarketOffers);

  const cancelAllOffers = useCallback(() => {
    setIsLoading(true);
    cancelAllSecondaryMarketOffers(
      {
        investmentRoundId,
      },
      {
        onSuccess() {
          setIsLoading(false);
          onConfirmCancelAllSecondaryMarketOffers();
        },
        onError: (error) => {
          captureSentryError('Error while canceling all secondary market offers', error);
          setIsLoading(false);
          setIsError(true);
        },
      }
    );
  }, [cancelAllSecondaryMarketOffers, investmentRoundId, onConfirmCancelAllSecondaryMarketOffers]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      header={intl.formatMessage({ id: 'secondaryMarketOfferCancelModal.title' })}
    >
      <Container>
        {isError ? (
          <Typography variant="labelBASERegular" color="strong">
            <Lang id="secondaryMarketOfferCancelModal.error" />
          </Typography>
        ) : (
          <FlexColumn>
            <FlexRow alignHorizontal={AlignDirections.FlexStart} alignVertical={AlignDirections.Center}>
              <Typography variant="labelBASERegular" color="strong">
                <Lang id="secondaryMarketOfferCancelModal.description" />
              </Typography>
            </FlexRow>
            <Spacer height="huge" />
            <FlexRow alignHorizontal={AlignDirections.FlexEnd} alignVertical={AlignDirections.Center}>
              <StyledButton color="primary" outline size="large" onClick={onClose} disabled={isLoading}>
                <Lang id="secondaryMarketOfferCancelModal.back" />
              </StyledButton>
              <Spacer width="regular" />
              <StyledButton
                color="primary"
                size="large"
                onClick={cancelAllOffers}
                loading={isLoading}
                disabled={isLoading}
              >
                <Lang id="secondaryMarketOfferCancelModal.confirm" />
              </StyledButton>
            </FlexRow>
          </FlexColumn>
        )}
      </Container>
    </Modal>
  );
};

const Container = styled(FlexRow)`
  width: 486px;
`;

const StyledButton = styled(Button)`
  margin: 0;
`;

export default SecondaryMarketOffersCancelModal;
