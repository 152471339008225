import React, { ReactNode } from 'react';

import { ErrorBoundary } from '@investown/fe/common-utils';

import ErrorWidget from '../ErrorWidget';

interface Props {
  children?: ReactNode | Element;
}

const WidgetErrorBoundary: React.FC<Props> = ({ children }) => (
  <ErrorBoundary fallbackComponent={({ error }) => <ErrorWidget error={error as Error} />}>{children}</ErrorBoundary>
);

export default WidgetErrorBoundary;
