import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconSVG } from '@investown/fe/ui-utils/icons/ionicons/arrowUp/arrow-up-outline.svg';

import Icon, { IconProps } from 'components/V2/Icons';

export interface ArrowProps extends IconProps {
  direction: 'left' | 'right' | 'up' | 'down';
}

const Arrow: FC<ArrowProps> = (props) => {
  const { direction, color, fillColor, hoverColor, className, width } = props;

  return (
    <StyledIcon
      direction={direction}
      color={color}
      fillColor={fillColor}
      hoverColor={hoverColor}
      className={className}
      width={width}
    >
      <IconSVG />
    </StyledIcon>
  );
};

const StyledIcon = styled(Icon)<{ direction: 'left' | 'right' | 'up' | 'down' }>`
  ${({ direction }) => {
    switch (direction) {
      case 'left':
        return 'transform: rotate(-90deg)';
      case 'right':
        return 'transform: rotate(90deg)';
      case 'down':
        return 'transform: rotate(180deg)';
    }
  }}
`;

export default Arrow;
