import React, { FC } from 'react';
import styled from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

import { ColorsName } from 'styles/theme';

interface Props extends WithChildren {
  color?: ColorsName;
  className?: string;
}

const Heading: FC<Props> = ({ children, color = 'textColor', className }) => (
  <StyledHeading className={className} $color={color}>
    {children}
  </StyledHeading>
);

const StyledHeading = styled.h2<{ $color: ColorsName }>`
  margin-bottom: 0;
  font-weight: ${(props) => props.theme.font.weight.medium};
  color: ${(props) => props.theme.color[props.$color]};
`;

export default Heading;
