/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import styled from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

import { AlignDirections } from '../FlexRow/FlexRow';

interface Props extends WithChildren {
  className?: string;
  alignVertical?: AlignDirections;
  alignHorizontal?: AlignDirections;
  width?: number | string;
}

const FlexColumn: FC<Props> = ({ className, alignVertical, alignHorizontal, width, children }) => (
  <Column className={className} alignVertical={alignVertical} alignHorizontal={alignHorizontal} width={width}>
    {children}
  </Column>
);
const Column = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ width }) => width && `max-width: ${width};`}
  ${({ alignVertical }) => alignVertical && `justify-content: ${alignVertical};`}

  ${({ alignHorizontal }) => alignHorizontal && `align-items: ${alignHorizontal};`}
`;

export default FlexColumn;
