/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { currencyFormat, defaultWalletCurrency, getCurrencyFormatParts } from '@investown/fe/common-utils';

import Spacer from 'components/V2/Spacer/Spacer';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import InfoLabel from 'components/V2/InfoLabel/InfoLabel';
import AlertTriangle from 'components/V2/Icons/AlertTriangle';
import Button from 'components/Button';
import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';
import { theme } from 'styles/theme';
import Plus from 'components/V2/Icons/Plus';
import { PATH_WALLET } from 'routes/routesPaths';

interface AvailableMoneyProps {
  amount: number;
  currency: string;
  availableBalance: number;
  investBalance?: number;
  maxInvestment: number;
  minInvestment?: number;
  onClick: (amount: number) => void;
  fullWidth?: boolean;
}

const BoldString = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="labelSMMedium">{children}</Typography>
);

const AvailableMoney: FC<AvailableMoneyProps> = ({
  amount,
  currency,
  availableBalance = 0,
  investBalance = 0,
  onClick,
  maxInvestment,
  minInvestment,
  fullWidth = false,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const formatParts = getCurrencyFormatParts({
    input: amount,
    currency: currency || defaultWalletCurrency,
    locale: intl.locale,
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const isOverMaxAmount = availableBalance > maxInvestment;

  const insufficientFundsAmount = investBalance - availableBalance;
  const insufficientFunds = insufficientFundsAmount > 0;

  const minInvestmentInsufficientFundsAmount = minInvestment !== undefined ? minInvestment - availableBalance : 0;
  const minInvestmentInsufficientFunds = minInvestmentInsufficientFundsAmount > 0;

  const handleOnClick = useCallback(() => {
    onClick(amount);
    setIsSubmitted(true);
  }, [onClick, amount]);

  const handleOnClickAddMoney = useCallback(() => {
    history.push(PATH_WALLET);
  }, [history]);

  return (
    <Container
      alignVertical={AlignDirections.Center}
      alignHorizontal={AlignDirections.SpaceBetween}
      fullWidth={fullWidth}
    >
      <FlexColumn>
        <FlexRow alignVertical={AlignDirections.Center}>
          <FlexColumn alignVertical={AlignDirections.FlexStart}>
            <Typography variant="labelSMRegular" color="medium">
              <Lang id="property.investmentPopUp.availableMoney.title" />
            </Typography>
            <Spacer height="small" />
            <FlexRow alignVertical={AlignDirections.Baseline} alignHorizontal={AlignDirections.FlexStart}>
              <Typography variant="body2XLMedium" color="strong">
                {formatParts.value}
              </Typography>
              <Spacer width="small" />
              <Typography variant="bodyLGRegular" color="strong">
                {formatParts.currency}
              </Typography>
            </FlexRow>
          </FlexColumn>
          {insufficientFunds || minInvestmentInsufficientFunds ? (
            <StyledButton color="primary" size="small" onClick={handleOnClickAddMoney}>
              <Plus
                width="16px"
                color={theme.colorTokens.text.invertedStrong}
                fillColor="transparent"
                hoverColor={theme.colorTokens.text.invertedStrong}
              />
              <Lang id="property.investmentPopUp.availableMoney.button.addMoney" />
            </StyledButton>
          ) : (
            <StyledButton color="primary" outline size="medium" onClick={handleOnClick}>
              <Typography variant="buttonBASEMedium" color="brand">
                <Lang id="property.investmentPopUp.availableMoney.button" />
              </Typography>
            </StyledButton>
          )}
        </FlexRow>
        {(insufficientFunds || minInvestmentInsufficientFunds) &&
          showWarning({
            insufficientFundsAmount,
            minInvestmentInsufficientFunds,
            minInvestmentInsufficientFundsAmount,
            intl,
          })}
        {isSubmitted && isOverMaxAmount && showMaxInvestmentWarning({ maxInvestment, intl })}
      </FlexColumn>
    </Container>
  );
};

function showWarning({
  minInvestmentInsufficientFunds,
  insufficientFundsAmount,
  minInvestmentInsufficientFundsAmount,
  intl,
}: {
  insufficientFundsAmount: number;
  minInvestmentInsufficientFunds: boolean;
  minInvestmentInsufficientFundsAmount: number;
  intl: IntlShape;
}) {
  return (
    <>
      <Spacer height="10" />
      <FlexRow>
        <InfoLabel
          icon={
            <AlertTriangle
              color={theme.colorTokens.utility.warning.strong}
              fillColor="transparent"
              hoverColor={theme.colorTokens.utility.warning.strong}
            />
          }
          backgroundColor={theme.colorTokens.utility.warning.subtle}
          text={
            <Lang
              id={
                minInvestmentInsufficientFunds
                  ? 'property.investmentPopUp.availableMoney.missingMinimumMoney'
                  : 'property.investmentPopUp.availableMoney.missingMoney'
              }
              values={{
                amount: currencyFormat({
                  input: minInvestmentInsufficientFunds
                    ? minInvestmentInsufficientFundsAmount
                    : insufficientFundsAmount,
                  currency: defaultWalletCurrency,
                  locale: intl.locale,
                }),
                b: BoldString,
              }}
            />
          }
          verticalAlign="center"
          textFont="labelSMRegular"
          textColor={theme.colorTokens.utility.warning.strong}
        />
      </FlexRow>
    </>
  );
}

function showMaxInvestmentWarning({ maxInvestment, intl }: { maxInvestment: number; intl: IntlShape }) {
  return (
    <>
      <Spacer height="10" />
      <FlexRow>
        <InfoLabel
          icon={
            <AlertTriangle
              color={theme.colorTokens.utility.warning.strong}
              fillColor="transparent"
              hoverColor={theme.colorTokens.utility.warning.strong}
            />
          }
          backgroundColor={theme.colorTokens.utility.warning.subtle}
          text={
            <Lang
              id="property.investmentPopUp.availableMoney.maxInvestment"
              values={{
                amount: currencyFormat({
                  input: maxInvestment,
                  currency: defaultWalletCurrency,
                  locale: intl.locale,
                }),
                b: BoldString,
              }}
            />
          }
          verticalAlign="center"
          textFont="labelSMRegular"
          textColor={theme.colorTokens.utility.warning.strong}
          fullWidth
        />
      </FlexRow>
    </>
  );
}

const Container = styled(FlexRow)<{ fullWidth: boolean }>`
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '462px;')};
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.extraLarge}`};
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  border: ${({ theme }) => `1px solid ${theme.colorTokens.stroke.medium}`};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const StyledButton = styled(Button)`
  margin: 0;
  max-width: 104px;
  &:focus,
  &:hover {
    span svg {
      color: ${({ theme }) => theme.colorTokens.text.invertedStrong};
      path{
        stroke: ${({ theme }) => theme.colorTokens.text.invertedStrong};
      }
    }
`;

export default AvailableMoney;
