import React, { FC } from 'react';

import { ReactComponent as IconSVG } from '@investown/fe/ui-utils/icons/ionicons/chevronBack/chevron-back.svg';

import Icon, { IconProps } from 'components/Icons';

const ChevronBack: FC<IconProps> = (props) => {
  const { color, className, width } = props;

  return (
    <Icon color={color} className={className} width={width}>
      <IconSVG />
    </Icon>
  );
};

export default ChevronBack;
