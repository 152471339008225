/* eslint-disable import/no-duplicates */
import React from 'react';

import { removeLeadingNonDigit } from './regexp';

interface Props {
  months: number;
  userLocale: string | null;
  timeFormat?: 'short' | 'long';
  doNotTrim?: boolean;
}

interface GetInvestmentInMonthsProps {
  months: number;
  userLocale: string | null;
  timeFormat?: 'short' | 'long';
  doNotTrim?: boolean;
}

export const InvestmentLengthInMonths: React.FC<Props> = ({ months, userLocale, timeFormat, doNotTrim }) => (
  <>{getInvestmentInMonths({ months, userLocale, timeFormat, doNotTrim })}</>
);

export const getInvestmentInMonths = ({
  months,
  userLocale,
  timeFormat = 'long',
  doNotTrim,
}: GetInvestmentInMonthsProps): string => {
  const availableuserLocales: { [key: string]: string } = {
    cs: 'cs',
    en: 'en',
  };
  const userLocaleTwoChar = userLocale?.split('-')[0];

  const locale =
    userLocaleTwoChar && availableuserLocales[userLocaleTwoChar] ? availableuserLocales[userLocaleTwoChar] : 'en';

  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto', style: timeFormat });

  let investmentLengthInMonths = formatter.format(months, 'month');

  if (!doNotTrim) {
    // Remove leading non-digit characters (e.g. "ends in 6 mo" => "6 mo")
    investmentLengthInMonths = removeLeadingNonDigit(investmentLengthInMonths);
  }

  return investmentLengthInMonths;
};
