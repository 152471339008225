import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';

import { CoreSDK, getProperty, QueryKeys, refetchIntervals } from '@investown/fe/api-sdk';

import OfferInvestmentForm from './OfferInvestmentForm';

import Modal, { Props as ModalProps } from 'components/V2/Modal/Modal';

type ModalPropsPicked = Pick<ModalProps, 'onClose' | 'visible'>;

interface OfferInvestmentToSecondaryMarketModalProps extends ModalPropsPicked {
  slug: string;
  repaymentStatus?: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
}

const OfferInvestmentToSecondaryMarketModal: FC<OfferInvestmentToSecondaryMarketModalProps> = ({
  onClose,
  visible,
  slug,
  repaymentStatus,
}) => {
  const intl = useIntl();

  const queryData = useQuery<CoreSDK.PropertyQuery, Error, CoreSDK.PropertyQuery, [string, { slug: string }]>(
    [QueryKeys.Property, { slug }],
    ({ queryKey: [, variables] }) => getProperty(variables.slug),
    {
      enabled: visible,
      refetchInterval: refetchIntervals.thirtySeconds,
    }
  );

  return (
    <Modal
      minWidth={542}
      maxWidth={542}
      header={intl.formatMessage({ id: 'secondaryMarketOfferInvestmentModal.title' })}
      visible={visible}
      onClose={onClose}
    >
      {queryData.data && queryData.data.property.currentInvestmentRound && (
        <OfferInvestmentForm
          investmentRoundId={queryData.data.property.currentInvestmentRound.id}
          hasBeenProlongated={queryData.data.property.currentInvestmentRound.hasBeenProlongated}
          investmentCurrency={queryData.data.property.currentInvestmentRound.investmentCurrency}
          currentUsersTotalInvestment={queryData.data.property.currentInvestmentRound.currentUsersTotalInvestment}
          investmentRoundType={queryData.data.property.currentInvestmentRound.type}
          onModalClose={onClose}
          repaymentStatus={repaymentStatus}
        />
      )}
    </Modal>
  );
};

export default OfferInvestmentToSecondaryMarketModal;
