/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import {
  currencyFormat,
  defaultWalletCurrency,
  deFormattedStringToFloat,
  generateUUID,
  minOfferInvestmentAmount,
  percentFormat,
  RemoteConfigParameter,
  translateCurrency,
  translateInvestmentError,
  valueToPercent,
} from '@investown/fe/common-utils';
import {
  ApiError,
  ApiErrorCode,
  getActiveSecondaryMarketRemainingOffersSums,
  offerInvestment,
  CoreSDK,
  QueryKeys,
  refetchIntervals,
} from '@investown/fe/api-sdk';
import { calculateRemainingYield } from '@investown/fe/ui-utils/properties';
import { TestIds } from '@investown/fe/test-utils/testIds';

import OfferInvestmentSuccess from './OfferInvestmentSuccess';

import Button from 'components/Button';
import Lang from 'util/IntlMessages';
import ErrorWithImage from 'components/Errors/ErrorWithImage';
import { secondaryMarketOfferCreatedAction, secondaryMarketOfferErrorAction } from 'appRedux/secondaryMarket/actions';
import Spacer from 'components/V2/Spacer/Spacer';
import LoadingWidget from 'components/V2/LoadingWidget/LoadingWidget';
import WalletWidget from 'components/V2/WalletWidget/WalletWidget';
import InfoLabel from 'components/V2/InfoLabel/InfoLabel';
import TooltipInfo from 'components/V2/Icons/TooltipInfo';
import { theme } from 'styles/theme';
import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import errorEmptyStates from 'constants/ErrorEmptyStates';
import { captureSentryError } from 'util/sentry';
import DividerHorizontal from 'components/V2/Divider/DividerHorizontal/DividerHorizontal';
import Typography from 'components/V2/Typography/Typography';
import Check from 'components/V2/Icons/Check';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import { getJSONValue, getNumberValue, getNumberValueOrUndefined } from 'util/firebase';
import { getAppLanguageLocalVariable } from 'lngProvider';
import { useInvestedPropertyRoundRepaymentStatistics } from 'containers/Property/helpers';
import {
  maxDecimalDigitsHF,
  maxSecondaryMarketOfferValueHF,
  minPropertyInvestmentValueHF,
  sanitizedValue,
} from 'constants/ValidationRules';
import { handleValueFormating } from 'components/V2/Input/helpers';
import Input from 'components/V2/Input/Input';
import Alert from 'components/V2/Alert/Alert';

interface OfferInvestmentFormProps {
  investmentRoundId: CoreSDK.PropertyInvestmentRoundObjectType['id'];
  hasBeenProlongated: CoreSDK.PropertyInvestmentRoundObjectType['hasBeenProlongated'];
  investmentCurrency: CoreSDK.PropertyInvestmentRoundObjectType['investmentCurrency'];
  currentUsersTotalInvestment: CoreSDK.PropertyInvestmentRoundObjectType['currentUsersTotalInvestment'];
  onModalClose: () => void;
  investmentRoundType: CoreSDK.PropertyInvestmentRoundObjectType['type'];
  repaymentStatus?: CoreSDK.PropertyInvestmentRoundObjectType['repaymentStatus'];
}

const MIN_OFFER_FEE = 0.01;
type SupportedLocale = 'en' | 'cs';
function getLocalizedLink(chunks: string, url: Record<SupportedLocale, string>) {
  const userLocale = getAppLanguageLocalVariable() as SupportedLocale;
  const localizedUrl = url[userLocale];

  return (
    <a data-testid="offer-investment-form-link" href={localizedUrl} target="_blank" rel="noopener noreferrer">
      {chunks}
    </a>
  );
}

const OfferInvestmentForm: FC<OfferInvestmentFormProps> = ({
  investmentCurrency,
  investmentRoundId,
  hasBeenProlongated,
  onModalClose,
  investmentRoundType,
  repaymentStatus,
}) => {
  const intl = useIntl();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setFocus,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: '',
    },
  });
  const earlySaleInvestmentMinFee = getNumberValueOrUndefined(RemoteConfigParameter.EarlySaleInvestmentMinFee);
  const earlySaleInvestmentFeeRate = getNumberValue(RemoteConfigParameter.EarlySaleInvestmentFeeRate);

  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const [offerSubmittedSuccessfully, setOfferSubmittedSuccessfully] = useState(false);
  const [offerSubmitError, setOfferSubmitError] = useState(false);
  const [forcedValue, setForcedValue] = useState<string | undefined>(undefined);
  const { url: delayedProjectStepsUrl }: { url: Record<SupportedLocale, string> } = getJSONValue(
    RemoteConfigParameter.DelayedProjectStepsUrl
  );
  const repaymentStatusesWithInfoLinks = [
    CoreSDK.PropertyInvestmentRoundRepaymentStatus.Delayed,
    CoreSDK.PropertyInvestmentRoundRepaymentStatus.Collection,
  ];

  const { url: projectSecurityBlogPostUrl }: { url: Record<SupportedLocale, string> } = getJSONValue(
    RemoteConfigParameter.ProjectSecurityBlogPostUrl
  );

  const { interval: earlySaleInvestmentValidIntervalInDays } = getJSONValue<{ interval: Record<string, string> }>(
    RemoteConfigParameter.EarlySaleInvestmentValidIntervalInDays
  );

  const dispatch = useDispatch();

  const activeOffers = useQuery<
    CoreSDK.ActiveSecondaryMarketRemainingOffersSumsQuery,
    Error,
    CoreSDK.ActiveSecondaryMarketRemainingOffersSumsQuery,
    [string, { investmentRoundId: string }]
  >(
    [QueryKeys.ActiveSecondaryMarketRemainingOffersSums, { investmentRoundId }],
    ({ queryKey: [, { investmentRoundId: id }] }) => getActiveSecondaryMarketRemainingOffersSums(id),
    {
      refetchInterval: refetchIntervals.tenSeconds,
    }
  );
  const inputValue = getValues().amount ? deFormattedStringToFloat(getValues().amount, intl) : 0;
  const calculatedInvestSellFee =
    !!inputValue && inputValue > 0
      ? Math.max(earlySaleInvestmentMinFee ?? MIN_OFFER_FEE, inputValue * earlySaleInvestmentFeeRate)
      : 0;

  const formattedCalculatedInvestSellFee = currencyFormat({
    input: calculatedInvestSellFee,
    currency: defaultWalletCurrency,
    locale: intl.locale,
  });

  const { data: repaymentStatistics } = useInvestedPropertyRoundRepaymentStatistics({
    propertyInvestmentRoundId: investmentRoundId,
  });

  const { mutate: submitOffer, isLoading: submitOfferIsLoading } = useMutation(offerInvestment, {
    onError: async (error) => {
      captureSentryError('Error submitting offer', error);
      if (error instanceof ApiError) {
        if (error.code === ApiErrorCode.AmountNotAvailableError) {
          dispatch(secondaryMarketOfferErrorAction());
          setApiError(intl.formatMessage({ id: 'errors.offerInvestment.AmountNotAvailableError' }));
        } else if (error.code === ApiErrorCode.FeatureTurnedOffError) {
          setApiError(intl.formatMessage({ id: 'errors.offerInvestment.FeatureTurnedOffError' }));
        } else if (error.code === ApiErrorCode.UserIsLockedError) {
          setApiError(intl.formatMessage({ id: 'errors.offerInvestment.UserIsLockedError' }));
        } else if (error.code) {
          // Rest of the error code translations is the same as in the investment process
          setApiError(translateInvestmentError(intl, error.code));
        } else {
          setApiError(intl.formatMessage({ id: 'errors.unknown' }));
        }
      } else {
        console.error(error);
      }
    },
  });

  const handleSellAllClick = useCallback(
    (amount: number) => {
      setForcedValue(
        handleValueFormating({
          value: String(amount),
          type: 'number',
          intl,
        })
      );
      setValue(
        'amount',
        handleValueFormating({
          value: String(amount),
          type: 'number',
          intl,
        }) as string
      );
      trigger();
      setFocus('amount');
    },
    [intl, setFocus, setValue, trigger]
  );

  const onSubmit = useCallback<SubmitHandler<{ amount: string }>>(
    (data, event) => {
      if (event) {
        event.preventDefault();
      }
      submitOffer(
        {
          offerAmount: deFormattedStringToFloat(data.amount, intl),
          investmentRoundId,
          transactionId: generateUUID(),
        },
        {
          onSuccess: () => {
            dispatch(secondaryMarketOfferCreatedAction());
            setOfferSubmittedSuccessfully(true);
          },
          onError: () => {
            setOfferSubmitError(true);
          },
        }
      );
    },
    [submitOffer, intl, investmentRoundId, dispatch]
  );

  const percentOffered = useMemo(() => {
    const amountAvailableToSell = activeOffers?.data?.activeSecondaryMarketOffersSums.availableForOfferingAmount.amount;

    if (!inputValue || !amountAvailableToSell) {
      return;
    }

    return valueToPercent({
      value: inputValue > amountAvailableToSell ? amountAvailableToSell : inputValue,
      total: amountAvailableToSell,
      fraction: 2,
    });
  }, [activeOffers?.data?.activeSecondaryMarketOffersSums.availableForOfferingAmount.amount, inputValue]);

  if (activeOffers.isError) {
    return <ErrorWithImage error={activeOffers.error} />;
  }

  if (activeOffers.isLoading || activeOffers.isIdle) {
    return (
      <LoadingWrapper>
        <LoadingWidget />
      </LoadingWrapper>
    );
  }

  if (offerSubmitError) {
    return (
      <ErrorWrapper>
        <ErrorEmptyState content={errorEmptyStates.errorOfferInvestmentOnMarketplace} />
      </ErrorWrapper>
    );
  }

  if (!earlySaleInvestmentMinFee) {
    return (
      <ErrorWrapper>
        <ErrorEmptyState content={errorEmptyStates.errorFetchData} />
      </ErrorWrapper>
    );
  }

  return offerSubmittedSuccessfully ? (
    <OfferInvestmentSuccess closeModal={onModalClose} />
  ) : (
    <OfferInvestmentFormHolder onSubmit={handleSubmit(onSubmit)}>
      <WalletWidget
        amount={activeOffers.data.activeSecondaryMarketOffersSums.availableForOfferingAmount.amount}
        currency={investmentCurrency}
        onClick={handleSellAllClick}
        fullWidth
      />
      <Spacer height="extraLarge" />
      <FormItem>
        <Controller
          name="amount"
          control={control}
          rules={{
            validate: {
              required: (value) => {
                if (!value) {
                  return minPropertyInvestmentValueHF(intl, minOfferInvestmentAmount, investmentCurrency)(0);
                }
              },
              minValue: (value) => {
                if (sanitizedValue(value)) {
                  return minPropertyInvestmentValueHF(
                    intl,
                    minOfferInvestmentAmount,
                    investmentCurrency
                  )(deFormattedStringToFloat(value, intl));
                }
              },
              maxValue: (value: string) => {
                if (sanitizedValue(value)) {
                  const validationResponse = maxSecondaryMarketOfferValueHF({
                    intl,
                    max: activeOffers.data.activeSecondaryMarketOffersSums.availableForOfferingAmount.amount,
                    currency: activeOffers.data.activeSecondaryMarketOffersSums.availableForOfferingAmount.currency,
                  })(deFormattedStringToFloat(value, intl));

                  if (validationResponse !== true) {
                    return validationResponse.message;
                  }

                  return true;
                }
                return false;
              },
              maxDecimalDigits: (value) => {
                if (sanitizedValue(value)) {
                  return maxDecimalDigitsHF(intl, 2)(deFormattedStringToFloat(value, intl));
                }
              },
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              id="amount"
              data-testid={TestIds.Amount}
              unit={translateCurrency(intl, investmentCurrency)}
              type="number"
              inputMode="decimal"
              autoFocus
              important
              defaultValue="0"
              forceValue={forcedValue}
              min={minOfferInvestmentAmount}
              step="any"
              name={intl.formatMessage({ id: 'secondaryMarketOfferInvestmentForm.inputPlaceholder' })}
              placeholder="0"
              autoComplete="nope"
              onChange={(e) => {
                setForcedValue(undefined);
                field.onChange(handleValueFormating({ value: e.target.value, type: 'number', intl }));
                trigger();
              }}
              hasError={!!fieldState.error}
              errorText={fieldState.error?.message}
            />
          )}
        />
        {!errors.amount && <Spacer height="extraLarge" />}
      </FormItem>
      {repaymentStatistics?.investedPropertyRoundRepaymentStatistics ? (
        <>
          <Spacer height="huge" />
          <Typography variant="bodyBASESemiBold" color="strong">
            {currencyFormat({
              input: calculateRemainingYield(repaymentStatistics, percentOffered)?.yield || 0,
              currency: investmentCurrency,
              locale: intl.locale,
            })}
          </Typography>
          <Typography variant="bodyXSRegular" color="subtle">
            <Lang id="property.investmentOfferPopUp.delayedProjectInfo.expectedYield" />
          </Typography>
          <Spacer height="24" />
        </>
      ) : null}
      {repaymentStatusesWithInfoLinks.includes(repaymentStatus as CoreSDK.PropertyInvestmentRoundRepaymentStatus) ? (
        <>
          <Spacer height="8" />
          <DividerHorizontal />
          <Spacer height="32" />
          <Typography variant="bodyBASESemiBold" color="strong">
            <Lang id="property.investmentOfferPopUp.delayedProjectInfo.heading" />
          </Typography>
          <Spacer height="regular" />
          <FlexRow alignVertical={AlignDirections.Center}>
            <Check width="24px" />
            <Spacer width="14" />
            <Typography variant="bodyBASERegular" color="strong">
              <Lang
                id="property.investmentOfferPopUp.delayedProjectInfo.firstRow"
                values={{ a: (chunks: string) => getLocalizedLink(chunks, delayedProjectStepsUrl) }}
              />
            </Typography>
          </FlexRow>
          <Spacer height="medium" />
          <FlexRow alignVertical={AlignDirections.Center}>
            <Check width="24px" />
            <Spacer width="14" />
            <Typography variant="bodyBASERegular" color="strong">
              <Lang
                id="property.investmentOfferPopUp.delayedProjectInfo.secondRow"
                values={{ a: (chunks: string) => getLocalizedLink(chunks, projectSecurityBlogPostUrl) }}
              />
            </Typography>
          </FlexRow>
          <Spacer height="52" />
        </>
      ) : null}
      <InfoLabel
        icon={
          <TooltipInfo
            color={theme.colorTokens.icon.brand}
            hoverColor={theme.colorTokens.icon.brand}
            fillColor="transparent"
            width="24px"
            heightInherit
          />
        }
        fullWidth
        backgroundColor={theme.colorTokens.surface.brandFaded25}
        text={
          <>
            <Lang
              id="property.investmentOfferPopUp.feeValidity"
              values={{ validInterval: earlySaleInvestmentValidIntervalInDays[getAppLanguageLocalVariable()] }}
            />
            {!hasBeenProlongated ? (
              <>
                <br />
                <Lang
                  id="property.investmentOfferPopUp.feeInformation"
                  values={{
                    feePercentage: percentFormat({ input: earlySaleInvestmentFeeRate, locale: intl.locale }),
                    feeAmount: formattedCalculatedInvestSellFee,
                  }}
                />
              </>
            ) : null}
          </>
        }
      />
      <Spacer height="extraLarge" />
      <FormItem>
        <Button
          htmlType="submit"
          color="primary"
          block
          loading={submitOfferIsLoading}
          disabled={submitOfferIsLoading || !!errors.amount}
          testID="secondaryMarketOfferInvestmentFormSubmit"
        >
          <Lang id="secondaryMarketOfferInvestmentForm.button" />
        </Button>
      </FormItem>
      {apiError && (
        <>
          <Spacer height="small" />
          <Alert message={apiError} type="error" />
        </>
      )}
      {investmentRoundType === CoreSDK.PropertyInvestmentRoundType.Legacy && (
        <>
          <Spacer height="extraLarge" />
          <InfoLabel
            icon={
              <TooltipInfo
                color={theme.colorTokens.icon.brand}
                hoverColor={theme.colorTokens.icon.brand}
                fillColor="transparent"
                width="24px"
              />
            }
            backgroundColor={theme.colorTokens.surface.brandFaded25}
            text={<Lang id="property.investmentOfferPopUp.disclaimer" />}
          />
        </>
      )}
    </OfferInvestmentFormHolder>
  );
};

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${({ theme }) => theme.colorTokens.surface.background};

  & > div:first-child {
    max-width: 320px;
  }
`;

const OfferInvestmentFormHolder = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FormItem = styled.div`
  margin: 0;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export default OfferInvestmentForm;
