import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';

import Spinner from '../LoadingSpinner';

import { theme } from 'styles/theme';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'large' | 'medium' | 'small';
  variant?: 'primary' | 'secondary' | 'danger';
  loading?: boolean;
};

type RequiredSize = Required<Pick<ButtonProps, 'size'>>['size'];
type SizeConfig = {
  [k in RequiredSize]: {
    padding: string;
    fontSize: string;
    fontWeight: number;
    lineHeight: string;
    radius: string;
    iconGap: string;
  };
};

const configSizes: SizeConfig = {
  large: {
    padding: `${theme.spacing.regular} ${theme.spacing.larger}`,
    fontSize: theme.typography.buttonLGMedium.size,
    fontWeight: theme.typography.buttonLGMedium.weight,
    lineHeight: theme.typography.buttonLGMedium.lineHeight,
    radius: theme.borderRadius.small,
    iconGap: theme.spacing.medium,
  },
  medium: {
    padding: `11px ${theme.spacing.large}`,
    fontSize: theme.typography.buttonBASEMedium.size,
    fontWeight: theme.typography.buttonBASEMedium.weight,
    lineHeight: theme.typography.buttonBASEMedium.lineHeight,
    radius: theme.borderRadius.small,
    iconGap: theme.spacing.medium,
  },
  small: {
    padding: `7px ${theme.spacing.regular}`,
    fontSize: theme.typography.buttonSMMedium.size,
    fontWeight: theme.typography.buttonSMMedium.weight,
    lineHeight: theme.typography.buttonBASEMedium.lineHeight,
    radius: theme.borderRadius.small,
    iconGap: '6px',
  },
};

type ButtonState = 'default' | 'hover' | 'disabled';
type RequiredVariant = Required<Pick<ButtonProps, 'variant'>>['variant'];
type ColorConfig = {
  [k in RequiredVariant]: {
    [s in ButtonState]: {
      color: string;
      background: string;
    };
  };
};

const configColors: ColorConfig = {
  primary: {
    default: {
      color: theme.colorTokens.text.invertedStrong,
      background: theme.colorTokens.button.primary.default,
    },
    hover: {
      color: theme.colorTokens.text.invertedStrong,
      background: theme.colorTokens.button.primary.hoverAndPressed,
    },
    disabled: {
      color: theme.colorTokens.text.invertedStrong,
      background: theme.colorTokens.button.primary.disabled,
    },
  },
  secondary: {
    default: {
      color: theme.colorTokens.text.brand,
      background: theme.colorTokens.button.secondary.default,
    },
    hover: {
      color: theme.colorTokens.text.brand,
      background: theme.colorTokens.button.secondary.hoverAndPressed,
    },
    disabled: {
      color: theme.colorTokens.text.brandDisabled,
      background: theme.colorTokens.button.secondary.disabled,
    },
  },
  danger: {
    default: {
      color: theme.colorTokens.text.error,
      background: theme.colorTokens.button.danger.default,
    },
    hover: {
      color: theme.colorTokens.text.error,
      background: theme.colorTokens.button.danger.hoverAndPressed,
    },
    disabled: {
      color: theme.colorTokens.text.errorDisabled,
      background: theme.colorTokens.button.danger.disabled,
    },
  },
};

function Button({
  variant = 'primary',
  size = 'large',
  loading = false,
  type = 'button',
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) {
  return (
    <StyledButton colorConfig={configColors[variant]} sizeConfig={configSizes[size]} type={type} {...rest}>
      {loading ? <StyledSpinner iconMargin={configSizes[size].iconGap} size={configSizes[size].lineHeight} /> : null}
      {children}
    </StyledButton>
  );
}

export default Button;

type StyledButtonConfigProps = {
  sizeConfig: SizeConfig[RequiredSize];
  colorConfig: ColorConfig[RequiredVariant];
};

const StyledButton = styled.button<StyledButtonConfigProps>`
  display: flex;
  padding: ${({ sizeConfig }) => sizeConfig.padding};
  font-size: ${({ sizeConfig }) => sizeConfig.fontSize};
  font-weight: ${({ sizeConfig }) => sizeConfig.fontWeight};
  line-height: ${({ sizeConfig }) => sizeConfig.lineHeight};
  color: ${({ colorConfig }) => colorConfig.default.color};
  background: ${({ colorConfig }) => colorConfig.default.background};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ colorConfig }) => colorConfig.default.background};
  border-radius: ${({ sizeConfig }) => sizeConfig.radius};
  cursor: pointer;

  transition: background-color 200ms ease-in-out;

  &:hover {
    color: ${({ colorConfig }) => colorConfig.hover.color};
    background: ${({ colorConfig }) => colorConfig.hover.background};
    border-color: ${({ colorConfig }) => colorConfig.hover.background};
  }
  &:disabled {
    color: ${({ colorConfig }) => colorConfig.disabled.color};
    background: ${({ colorConfig }) => colorConfig.disabled.background};
    border-color: ${({ colorConfig }) => colorConfig.disabled.background};
    cursor: default;
  }
`;

const StyledSpinner = styled(Spinner)<{ size: string; iconMargin: string }>`
  margin-inline-end: ${({ iconMargin }) => iconMargin};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
