import React from 'react';
import { SingleASTNode } from '@khanacademy/simple-markdown';
import styled from 'styled-components';

import { isUrlValid } from '@investown/fe/common-utils';

import Typography from 'components/V2/Typography/Typography';

const ParseItem = ({
  node,
  key,
  isNested,
}: {
  node: SingleASTNode;
  key: string;
  isNested?: boolean;
}): React.ReactNode => {
  const { type, level, content, target, items, ordered } = node;

  switch (type) {
    case 'text':
      if (isNested) {
        return <React.Fragment key={key}>{content}</React.Fragment>;
      }
      return (
        <Typography variant="bodyBASERegular" color="medium" key={key}>
          {content}
        </Typography>
      );
    case 'heading':
      if (level === 1) {
        return (
          <HeaderWrapper key={key}>
            <Typography variant="displayMDRegular" color="strong" key={key}>
              {content.map((item: SingleASTNode, subKey: number) =>
                ParseItem({ node: item, key: `${key}_${subKey}`, isNested: true })
              )}
            </Typography>
          </HeaderWrapper>
        );
      }
      if (level === 2) {
        return (
          <HeaderWrapper key={key}>
            <Typography variant="displaySMRegular" color="strong" key={key}>
              {content.map((item: SingleASTNode, subKey: number) =>
                ParseItem({ node: item, key: `${key}_${subKey}`, isNested: true })
              )}
            </Typography>
          </HeaderWrapper>
        );
      }
      return (
        <HeaderWrapper key={key}>
          <Typography variant="displayXSRegular" color="strong" key={key}>
            {content.map((item: SingleASTNode, subKey: number) =>
              ParseItem({ node: item, key: `${key}_${subKey}`, isNested: true })
            )}
          </Typography>
        </HeaderWrapper>
      );
    case 'blockQuote':
      return (
        <ParagraphWrapper key={key}>
          <Typography variant="bodyBASERegular" color="subtle" key={key}>
            {content.map((item: SingleASTNode, subKey: number) => ParseItem({ node: item, key: `${key}_${subKey}` }))}
          </Typography>
        </ParagraphWrapper>
      );
    case 'paragraph':
      return (
        <ParagraphWrapper key={key}>
          <Typography variant="bodyBASERegular" color="strong" key={key}>
            {content.map((item: SingleASTNode, subKey: number) => ParseItem({ node: item, key: `${key}_${subKey}` }))}
          </Typography>
        </ParagraphWrapper>
      );
    case 'em':
      return (
        <Typography variant="bodyBASERegular" color="strong" key={key} isItalic>
          {content.map((item: SingleASTNode, subKey: number) =>
            ParseItem({ node: item, key: `${key}_${subKey}`, isNested: true })
          )}
        </Typography>
      );
    case 'strong':
      return (
        <Typography variant="bodyBASESemiBold" color="strong" key={key}>
          {content.map((item: SingleASTNode, subKey: number) =>
            ParseItem({ node: item, key: `${key}_${subKey}`, isNested: true })
          )}
        </Typography>
      );
    case 'link':
      if (isUrlValid(target)) {
        return (
          <a data-testid={`markdown-link-${key}`} href={target} target="_blank" key={key}>
            <Typography variant="bodyBASERegular" color="brand">
              {content.map((item: SingleASTNode, subKey: number) =>
                ParseItem({ node: item, key: `${key}_${subKey}`, isNested: true })
              )}
            </Typography>
          </a>
        );
      }
      return (
        <Typography variant="bodyBASERegular" color="strong" key={key}>
          {content.map((item: SingleASTNode, subKey: number) =>
            ParseItem({ node: item, key: `${key}_${subKey}`, isNested: true })
          )}
        </Typography>
      );
    case 'list': {
      const ListTag = ordered ? 'ol' : 'ul';
      return (
        <ListWrapper key={key}>
          <ListTag key={key}>
            {items.map((subItems: SingleASTNode[], subKey: number) => (
              <Typography variant="bodyBASERegular" color="strong" key={`${key}_${subKey.toString()}`}>
                <ListItem>
                  {subItems.map((item: SingleASTNode, itemKey: number) =>
                    ParseItem({ node: item, key: `${key}_${itemKey}`, isNested: true })
                  )}
                </ListItem>
              </Typography>
            ))}
          </ListTag>
        </ListWrapper>
      );
    }
    default:
      return null;
  }
};

const HeaderWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.huge};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const ParagraphWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const ListWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

const ListItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

export default ParseItem;
