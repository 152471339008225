import confetti from 'canvas-confetti';

import { randomInRange } from '@investown/fe/common-utils';

type FireworksFireReturn = Promise<null> | null | void;
interface FireworksCallback {
  fire: () => FireworksFireReturn;
}

const useFireworks = (): FireworksCallback => {
  const fire = (): FireworksFireReturn => {
    const duration = 2 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
    // Define the interval which repeatably run confetti on random places

    const interval: number = window.setInterval(() => {
      // set some interval constants
      const timeLeft = animationEnd - Date.now();
      const particleCount = 50 * (timeLeft / duration);

      // Clear interval when animation time ends
      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      // since particles fall down, start a bit higher than random
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
    }, 250);
  };

  return { fire };
};

export default useFireworks;
