import React, { FC } from 'react';
import styled from 'styled-components';

import Lang from 'util/IntlMessages';
import Button from 'components/Button';
import SuccessIcon from 'components/AnimatedIcons/SuccessIcon';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';

interface SuccessfulInvestmentProps {
  closeModal: () => void;
}

const InvestmentOfferSuccess: FC<SuccessfulInvestmentProps> = ({ closeModal }) => (
  <Wrapper>
    <SuccessIcon />
    <Spacer height="extraLarge" />
    <Typography variant="displaySMMedium" color="strong">
      <Lang id="secondaryMarketOfferInvestmentForm.success.title" />
    </Typography>
    <Spacer height="large" />
    <Typography variant="bodyBASERegular" color="subtle">
      <Lang id="secondaryMarketOfferInvestmentForm.success.description" />
    </Typography>
    <Spacer height="massive" />
    <StyledButton color="primary" onClick={closeModal} block>
      <Lang id="secondaryMarketOfferInvestmentForm.success.button" />
    </StyledButton>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  margin: auto;

  h1 {
    margin-bottom: ${(props) => props.theme.margins.huge};
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  max-width: 300px;
  margin-top: ${(props) => props.theme.margins.huge};
`;

export default InvestmentOfferSuccess;
