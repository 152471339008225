import React, { FC } from 'react';
import styled from 'styled-components';

import { StrokeColorName } from '@investown/fe/common-utils';

interface Props {
  className?: string;
  color?: StrokeColorName;
  offsetLeft?: string;
  offsetRight?: string;
}
const DividerHorizontal: FC<Props> = ({ className, color, offsetLeft, offsetRight }) => (
  <Divider color={color} className={className} offsetLeft={offsetLeft} offsetRight={offsetRight} />
);

const Divider = styled.div<Props>`
  height: 1px;
  background-color: ${({ theme, color }) => theme.colorTokens.stroke[color || 'medium']};
  ${({ offsetRight }) => offsetRight && `margin-right: ${offsetRight};`};
  ${({ offsetLeft }) => offsetLeft && `margin-left: ${offsetLeft};`};
`;

export default DividerHorizontal;
