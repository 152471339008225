import React, { FC } from 'react';
import styled from 'styled-components';

import { TestIds } from '@investown/fe/test-utils/testIds';

import Lang from 'util/IntlMessages';
import Button from 'components/Button';
import SuccessIcon from 'components/AnimatedIcons/SuccessIcon';
import Spacer from 'components/Spacer';
import Paragraph from 'components/Typography/Paragraph';

interface SuccessfulInvestmentProps {
  closeModal: () => void;
}

const OrderPlacedSuccessfully: FC<SuccessfulInvestmentProps> = ({ closeModal }) => (
  <Wrapper>
    <h1>
      <Lang id="property.asyncInvestmentOrderPopUp.success.heading" />
    </h1>
    <SuccessIcon />
    <StyledButton data-testid={TestIds.CloseSuccesfullInvestment} color="primary" onClick={closeModal} block>
      <Lang id="property.asyncInvestmentOrderPopUp.success.button" />
    </StyledButton>
    <Spacer height="large" />
    <StyledParagraph fontSize="mini" fontWeight="regular" color="textFaded">
      <Lang id="property.asyncInvestmentOrderPopUp.success.additionalInfo" />
    </StyledParagraph>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  margin: auto;

  h1 {
    margin-bottom: ${(props) => props.theme.margins.huge};
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  max-width: 300px;
  margin-top: ${(props) => props.theme.margins.huge};
`;

const StyledParagraph = styled(Paragraph)`
  text-align: left;
`;

export default OrderPlacedSuccessfully;
