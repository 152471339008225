import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { getWallet, CoreSDK, QueryKeys } from '@investown/fe/api-sdk';
import { currencyFormat, getSellerNameForSecondaryMarketOffers } from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import Typography from 'components/V2/Typography/Typography';
import UnstyledButton from 'components/V2/Button/UnstyledButton';
import Spacer from 'components/V2/Spacer/Spacer';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import FlexRow from 'components/V2/Grid/FlexRow/FlexRow';

interface Props {
  secondaryMarketItem: CoreSDK.SecondaryMarketItem;
  isInvestDisabled: boolean;
  onItemClick: (secondaryMarketItem: CoreSDK.SecondaryMarketItem) => void;
}

const SecondaryMarketOffersModalItem: FC<Props> = ({ secondaryMarketItem, isInvestDisabled, onItemClick }) => {
  const intl = useIntl();
  const wallet = useQuery(QueryKeys.Wallet, getWallet);

  const handleOnClick = useCallback(() => {
    onItemClick(secondaryMarketItem);
  }, [onItemClick, secondaryMarketItem]);

  const sellerName = getSellerNameForSecondaryMarketOffers(secondaryMarketItem.seller);

  return (
    <Row>
      <Column>
        <Typography variant="labelBASEMedium" color="strong">
          {currencyFormat({
            input: secondaryMarketItem.investmentAmountAvailable,
            currency: secondaryMarketItem.investmentCurrency,
            locale: intl.locale,
          })}
        </Typography>
        <Spacer height="2" />
        <Typography variant="labelSMRegular" color="medium">
          {sellerName}
        </Typography>
      </Column>
      {wallet.data && (
        <SpacelessButton
          data-testid={TestIds.OpenSecondaryMarketBidDetail}
          color="link"
          disabled={isInvestDisabled}
          onClick={handleOnClick}
        >
          <Typography variant="labelBASERegular" color="brand">
            {intl.formatMessage({ id: 'secondaryMarketOfferModal.showOffer' })}
          </Typography>
        </SpacelessButton>
      )}
    </Row>
  );
};

const Row = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.large} 0`};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.bodyBackground};
  min-height: 50px;
`;

const Column = styled(FlexColumn)`
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
`;

const SpacelessButton = styled(UnstyledButton)`
  margin: 0;
`;

export default SecondaryMarketOffersModalItem;
