import React, { FC, useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { ThemeContext } from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { getSecondaryMarketItems, CoreSDK, QueryKeys, queryStaleTimes } from '@investown/fe/api-sdk';
import { useManagedData } from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import SecondaryMarketOffersModalItem from './SecondaryMarketOffersModalItem';
import SecondaryMarketOffersModalItemDetail from './SecondaryMarketOffersModalItemDetail';

import Spacer from 'components/Spacer';
import ErrorText from 'components/Errors/ErrorText';
import { ProjectStatus } from 'containers/Dashboard/ProjectList/model';
import Typography from 'components/V2/Typography/Typography';
import ChevronBack from 'components/Icons/ChevronBack';
import CloseOutlineIcon from 'components/Icons/CloseOutlineIcon';
import ModalV3, { CloseButton } from 'components/V2/Modal/ModalV3';
import Loading from 'components/V2/Loading/Loading';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';

interface Props {
  visible: boolean;
  onClose: () => void;
  investmentRoundId: string;
  requestRefetchOnBid: () => void;
  isProjectWithOldContract: boolean;
}

const SecondaryMarketOffersModal: FC<Props> = ({
  visible,
  onClose,
  investmentRoundId,
  requestRefetchOnBid,
  isProjectWithOldContract,
}) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const [isAnythingLoading, setIsAnythingLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CoreSDK.SecondaryMarketItem | null>(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isBidSubmitted, setIsBidSubmitted] = useState(false);
  const scrollableTarget = 'secondaryMarketOffersScrollableTarget';

  const modalContentMinHeight = isProjectWithOldContract ? '622px' : '643px';

  const { pagination } = useManagedData<ProjectStatus>({
    columns: ['value'],
    defaultOptions: {
      pagination: {
        pageSize: 30,
      },
    },
  });

  const handleRequestRefetchOnBid = useCallback(() => {
    setIsBidSubmitted(true);
    requestRefetchOnBid();
  }, [requestRefetchOnBid]);

  const results = useInfiniteQuery<CoreSDK.SecondaryMarketItemsQuery, Error, CoreSDK.SecondaryMarketItemsQuery>(
    [QueryKeys.SecondaryMarketItems, investmentRoundId],
    ({ pageParam = 0 }) =>
      getSecondaryMarketItems({
        pageRequest: { page: pageParam, perPage: pagination.pageSize },
        investmentRoundId,
      }),
    {
      staleTime: queryStaleTimes.infinite,
      getNextPageParam: (lastPage, pages) => (lastPage.items.length < pagination.pageSize ? undefined : pages.length),
    }
  );

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: results.isLoading,
    hasNextPage: !!results.hasNextPage,
    onLoadMore: results.fetchNextPage,
    rootMargin: '0px 0px 400px 0px',
  });

  const handleItemClick = (item: CoreSDK.SecondaryMarketItem) => {
    setSelectedItem(item); // Set the selected bid when clicked
    setTimeout(() => {
      setIsDetailVisible(true); // Show bid detail
    }, 500);
  };

  const handleCloseDetail = () => {
    setIsDetailVisible(false); // Hide bid detail
    setTimeout(() => {
      setSelectedItem(null); // Reset selected bid with delay to have animation
    }, 500);
  };

  const handleCloseModal = () => {
    setSelectedItem(null); // Reset selected bid
    setIsDetailVisible(false);
    onClose(); // Reset selected item to close detail view
  };

  if (results.isLoading || results.isIdle) {
    return (
      <>
        <Spacer height="extraLarge" />
        <Loading />
      </>
    );
  }

  if (results.isError) {
    return (
      <>
        <Spacer height="extraLarge" />
        <ErrorText error={results.error} />
      </>
    );
  }

  const secondaryMarketItems = results.data.pages.map((query) => query.items).flat();

  const ListHeader = ({ header }: { header: string }) => (
    <ListHead>
      <DetailHeadLeft>
        <Typography variant="displayXSMedium" color="strong">
          {header}
        </Typography>
      </DetailHeadLeft>
      <CloseButton onClick={handleCloseModal} data-testid={TestIds.CloseModal}>
        <span>
          <CloseOutlineIcon color={theme.colorTokens.icon.medium} />
        </span>
      </CloseButton>
    </ListHead>
  );

  const DetailHeader = ({ secondaryMarketItem }: { secondaryMarketItem: CoreSDK.SecondaryMarketItem | null }) => {
    if (!secondaryMarketItem) {
      return null;
    }
    const sellerName = secondaryMarketItem.seller?.companyName
      ? secondaryMarketItem.seller.companyName
      : `${secondaryMarketItem.seller?.firstName} ${secondaryMarketItem.seller?.lastName}`;

    return (
      <DetailHead>
        <DetailHeadLeft>
          {!isBidSubmitted && (
            <>
              <BackButton onClick={handleCloseDetail} data-testid={TestIds.CloseSecondaryMarketBidDetail}>
                <ChevronBack color={theme.colorTokens.icon.medium} width="16px" />
              </BackButton>
              <Spacer width="12px" />
            </>
          )}
          <Typography variant="displayXSMedium" color="strong">
            {sellerName}
          </Typography>
        </DetailHeadLeft>
        <CloseButton onClick={handleCloseModal} data-testid={TestIds.CloseModal}>
          <span>
            <CloseOutlineIcon color={theme.colorTokens.icon.medium} />
          </span>
        </CloseButton>
      </DetailHead>
    );
  };

  return (
    <ModalV3
      visible={visible}
      onClose={onClose}
      closeModalOnBackdropClick={false}
      header={[
        <ListHeader key={0} header={intl.formatMessage({ id: 'secondaryMarketOfferModal.header' })} />,
        <DetailHeader key={1} secondaryMarketItem={selectedItem} />,
      ]}
      maxWidth={542}
      minWidth={542}
      currentPage={isDetailVisible && !!selectedItem ? 1 : 0}
    >
      <FullWidthColumn modalContentMinHeight={modalContentMinHeight}>
        <ScrollableArea id={scrollableTarget} modalContentMinHeight={modalContentMinHeight}>
          {secondaryMarketItems &&
            secondaryMarketItems.map((item) => (
              <SecondaryMarketOffersModalItem
                key={item.id}
                secondaryMarketItem={item}
                isInvestDisabled={isAnythingLoading}
                onItemClick={handleItemClick}
              />
            ))}
          <div ref={infiniteScrollRef} />
          {results.isLoading && <Loading />}
        </ScrollableArea>
      </FullWidthColumn>
      <DetailContainer isSelectedItem={!!selectedItem} modalContentMinHeight={modalContentMinHeight}>
        {selectedItem && (
          <SecondaryMarketOffersModalItemDetail
            investmentRoundId={investmentRoundId}
            secondaryMarketItem={selectedItem}
            onLoading={setIsAnythingLoading}
            isInvestDisabled={isAnythingLoading}
            requestRefetchOnBid={handleRequestRefetchOnBid}
            onCloseDetail={handleCloseDetail}
            onCloseModal={handleCloseModal}
            isProjectWithOldContract={isProjectWithOldContract}
          />
        )}
      </DetailContainer>
    </ModalV3>
  );
};

const FullWidthColumn = styled(FlexColumn)<{ modalContentMinHeight: string }>`
  width: 100%;
  position: relative;
  min-height: ${({ modalContentMinHeight }) => modalContentMinHeight};
`;

const ScrollableArea = styled.div<{ modalContentMinHeight: string }>`
  overflow: auto;
  width: 100%;
  height: auto;
  min-height: 273px;
  max-height: ${({ modalContentMinHeight }) => modalContentMinHeight};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: calc(100vh - 80px);
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 11 */

  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

const DetailContainer = styled.div<{ isSelectedItem: boolean; modalContentMinHeight: string }>`
  position: relative;
  width: 100%;
  min-width: 100%;
  min-height: ${({ modalContentMinHeight }) => modalContentMinHeight};
  height: 100%;
  background: ${({ theme }) => theme.colorTokens.surface.card};
`;

const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DetailHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DetailHeadLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const BackButton = styled.div`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
`;

export default SecondaryMarketOffersModal;
