import React, { FC } from 'react';
import styled from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

interface Props extends WithChildren {
  onClick: () => void;
  className?: string;
}

const InvisibleButton: FC<Props> = ({ onClick, className, children, ...rest }) => (
  <StyledButton type="button" className={className} onClick={onClick} {...rest}>
    {children}
  </StyledButton>
);

export default InvisibleButton;

const StyledButton = styled.button`
  display: flex;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  align-items: center;
`;
