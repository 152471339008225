import React, { FC } from 'react';
import styled from 'styled-components';

import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/Spacer';
import Button from 'components/Button';

export type OnboardingBannerDirection = 'column' | 'row';

export interface Props {
  direction: OnboardingBannerDirection;
  heading: string;
  description: string;
  icon?: React.ReactNode;
  buttonText?: string;
  buttonOnClick?: () => void;
}
const OnboardingBanner: FC<Props> = ({ direction = 'row', heading, description, icon, buttonText, buttonOnClick }) => (
  <Container direction={direction}>
    {icon && icon}
    {direction === 'column' && <Spacer height="extraLarge" />}
    {direction === 'row' && <Spacer width="extraLarge" />}
    <TextContainer>
      <Typography variant="labelLGMedium">{heading}</Typography>
      <Spacer height="small" />
      <Typography variant="bodyBASERegular">{description}</Typography>
    </TextContainer>
    {direction === 'column' && buttonText && <Spacer height="extraLarge" />}
    {buttonText && (
      <ButtonContainer isColumn={direction === 'column'}>
        <StyledButton
          isColumn={direction === 'column'}
          data-testid="onboarding-banner-button"
          size="large"
          color="inverted"
          onClick={buttonOnClick}
        >
          <Typography variant="buttonLGMedium">{buttonText}</Typography>
        </StyledButton>
      </ButtonContainer>
    )}
  </Container>
);

const Container = styled.div<Pick<Props, 'direction'>>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colorTokens.gradient.marketing.start} 0%, ${theme.colorTokens.gradient.marketing.end} 100%)`};
  color: ${({ theme }) => theme.colorTokens.text.invertedStrong};
  padding: ${({ theme }) => theme.spacing.extraLarge}
    ${({ theme, direction }) => (direction === 'column' ? theme.spacing.large : theme.spacing.huge)};
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  align-items: center;
  justify-content: ${({ direction }) => (direction === 'column' ? 'center' : 'flex-start')};
  text-align: ${({ direction }) => (direction === 'column' ? 'center' : 'left')};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonContainer = styled.div<{ isColumn: boolean }>`
  display: flex;
  justify-content: ${({ isColumn }) => (isColumn ? 'center' : 'flex-end')};
  flex: 1;
  ${({ isColumn }) => isColumn && 'width: 100%'};
`;

const StyledButton = styled(Button)<{ isColumn: boolean }>`
  width: ${({ isColumn }) => (isColumn ? '100%' : 'auto')};
`;

export default OnboardingBanner;
