import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { OnboardingCallbackFnParams } from '../../helpers';

import OnboardingBanner, { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';
import { ReactComponent as Document } from 'assets/images/icons/onboardingIcons/document.svg';

interface InvestmentContractProps {
  inflectedFirstName: string;
  direction: OnboardingBannerDirection;
  isLegalEntity: boolean;
  callbackFn?: (params: OnboardingCallbackFnParams) => void;
}

const InvestmentContract: FC<InvestmentContractProps> = ({
  inflectedFirstName,
  direction,
  isLegalEntity,
  callbackFn,
}) => {
  const intl = useIntl();

  return (
    <>
      <OnboardingBanner
        direction={direction}
        heading={intl.formatMessage({ id: 'widgets.onboarding.investmentContract.heading' })}
        description={intl.formatMessage({
          id: `widgets.onboarding.investmentContract.description.${direction}`,
        })}
        buttonText={intl.formatMessage({ id: 'widgets.onboarding.investmentContract.button' })}
        // eslint-disable-next-line no-console
        buttonOnClick={() =>
          callbackFn &&
          callbackFn({
            componentName: 'InvestmentContract',
            inflectedFirstName,
            isLegalEntity,
          })
        }
        icon={<Document />}
      />
    </>
  );
};

export default InvestmentContract;
