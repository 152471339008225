import React, { FC } from 'react';

import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import LayoutContainerContent from 'components/V2/LayoutContainerContent/LayoutContainerContent';
import Spacer from 'components/V2/Spacer/Spacer';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import { theme } from 'styles/theme';

const SidebarLoadingSkeleton: FC = () => (
  <LayoutContainerContent isColumn>
    <FlexRow alignHorizontal={AlignDirections.SpaceBetween} alignVertical={AlignDirections.Center}>
      <LoadingSkeleton width={87} height={40} />

      <FlexRow alignHorizontal={AlignDirections.FlexEnd} alignVertical={AlignDirections.Center}>
        <LoadingSkeleton width={70} height={32} borderRadius={theme.borderRadius.extraLarge} />
        <Spacer width="8" />
        <LoadingSkeleton width={35} height={35} />
      </FlexRow>
    </FlexRow>

    <Spacer height="huge" />
    <Spacer height="huge" />

    <LoadingSkeleton width="100%" height={48} />

    <Spacer height="huge" />

    <FlexRow alignHorizontal={AlignDirections.Center}>
      <FlexColumn>
        <LoadingSkeleton width="100%" height={46} />
      </FlexColumn>
    </FlexRow>
    <Spacer height="small" />
    <FlexRow alignHorizontal={AlignDirections.Center}>
      <FlexColumn width="30%">
        <LoadingSkeleton />
      </FlexColumn>
    </FlexRow>

    <Spacer height="huge" />
    <Spacer height="huge" />

    {Array.from({ length: 8 }).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        <FlexRow alignHorizontal={AlignDirections.SpaceBetween}>
          <FlexColumn width="30%">
            <LoadingSkeleton />
          </FlexColumn>
          <FlexColumn width="40%">
            <LoadingSkeleton />
          </FlexColumn>
        </FlexRow>
        <Spacer height="regular" />
      </React.Fragment>
    ))}
  </LayoutContainerContent>
);

export default SidebarLoadingSkeleton;
