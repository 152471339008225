import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PATH_USER_VERIFICATION } from 'routes/routesPaths';
import useConfetti from 'hooks/useConfetti';
import { ConfettiTypes } from 'constants/ConfettiTypes';
import OnboardingBanner, { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';
import { ReactComponent as Person } from 'assets/images/icons/onboardingIcons/person.svg';

const StartVerification: FC<{ inflectedFirstName: string; direction: OnboardingBannerDirection }> = ({
  inflectedFirstName,
  direction,
}) => {
  const intl = useIntl();
  const confetti = useConfetti(ConfettiTypes.LeftToRightBomb);
  const userWasCelebratedByConfettiKey = 'userWasCelebratedByConfetti';
  const history = useHistory();

  useEffect(() => {
    const userWasCelebratedByConfetti = localStorage.getItem(userWasCelebratedByConfettiKey);
    if (userWasCelebratedByConfetti !== '1') {
      setTimeout(() => {
        confetti.fire();
        localStorage.setItem(userWasCelebratedByConfettiKey, '1');
      }, 1000);
    }
  });

  return (
    <OnboardingBanner
      direction={direction}
      heading={intl.formatMessage(
        { id: 'widgets.onboarding.startVerification.welcome' },
        { salutation: inflectedFirstName }
      )}
      description={intl.formatMessage({ id: 'widgets.onboarding.startVerification.description' })}
      buttonText={intl.formatMessage({ id: 'widgets.onboarding.startVerification.button' })}
      buttonOnClick={() => history.push(PATH_USER_VERIFICATION)}
      icon={<Person />}
    />
  );
};

export default StartVerification;
