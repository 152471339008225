import React, { FC, useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
  refetchIntervals,
  UserDetailsDTO,
  UserVerificationDataDTO,
  UserVerificationStatus,
  useUserTimestamps,
} from '@investown/fe/api-sdk';
import { numberWithSpaceSeparator, RemoteConfigParameter } from '@investown/fe/common-utils';

import { getAppLanguageLocalVariable } from 'lngProvider';
import Widget from 'components/Widget';
import Stepper from 'components/V2/Stepper/Stepper';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import House from 'assets/images/house.png';
import Lang from 'util/IntlMessages';
import {
  PATH_ABILITY_TO_BEAR_LOSS,
  PATH_INVESTOR_QUESTIONNAIRE,
  PATH_LEGAL_ENTITY_REQUIRED_DOCUMENTS,
  PATH_USER_VERIFICATION,
  PATH_WALLET,
} from 'routes/routesPaths';
import Button from 'components/V2/Button';
import InfoLabel from 'components/V2/InfoLabel/InfoLabel';
import TooltipInfo from 'components/V2/Icons/TooltipInfo';
import { getNumberValue } from 'util/firebase';

interface Props {
  userDetails: UserDetailsDTO;
  userVerificationData: UserVerificationDataDTO;
}

const BoldString = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="bodySMSemiBold" color="strong">
    {children}
  </Typography>
);

const OnboardingBanner: FC<Props> = ({ userDetails, userVerificationData }) => {
  const history = useHistory();
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const userLocale = getAppLanguageLocalVariable();
  const {
    data: userTimestamps,
    isLoading: userTimestampsIsLoading,
    refetch: refetchUserTimestamps,
  } = useUserTimestamps({
    refetchOnMount: 'always',
    refetchInterval: (data) =>
      userVerificationData &&
      userDetails.verificationStatus === UserVerificationStatus.Verified &&
      (userVerificationData.suitability === null || !data?.abilityToBearLossQuestionnaire?.endedAt)
        ? refetchIntervals.fiveSeconds
        : false,
  });
  const salutation = userLocale === 'cs' ? userDetails.inflectedName : userDetails.firstName;
  const addlegalEntityStepIfNeeded = userDetails.companyId ? 1 : 0;

  const numberOfUsers = getNumberValue(RemoteConfigParameter.NumberOfUsers);

  useEffect(() => {
    refetchUserTimestamps();
  }, [history, refetchUserTimestamps]);

  const steps = [
    { heading: intl.formatMessage({ id: 'widgets.onboarding.heading.registration' }) },
    {
      heading: intl.formatMessage({
        id: userDetails.companyId
          ? 'widgets.onboarding.heading.verification.legalEntity'
          : 'widgets.onboarding.heading.verification',
      }),
      description: intl.formatMessage({
        id: userDetails.companyId
          ? 'widgets.onboarding.heading.verification.legalEntity.description'
          : 'widgets.onboarding.heading.verification.description',
      }),
      content: (
        <Button onClick={() => history.push(PATH_USER_VERIFICATION)} data-testid="complete-verification-button">
          <Lang id="widgets.onboarding.completeVerification" />
        </Button>
      ),
    },
    ...(userDetails.companyId
      ? [
          {
            heading: intl.formatMessage({ id: 'widgets.onboarding.companyRequiredDocuments.heading' }),
            description: intl.formatMessage({ id: 'widgets.onboarding.companyRequiredDocuments.description' }),
            content: (
              <>
                <Spacer height="28" />
                <Button onClick={() => history.push(PATH_LEGAL_ENTITY_REQUIRED_DOCUMENTS)} data-testid="repair-button">
                  <Lang id="widgets.onboarding.companyRequiredDocuments.button" />
                </Button>
                <Spacer height="28" />
                <InfoLabel
                  icon={
                    <TooltipInfo
                      color={theme.colorTokens.icon.medium}
                      hoverColor={theme.colorTokens.icon.medium}
                      fillColor="transparent"
                      width="24px"
                      heightInherit
                    />
                  }
                  backgroundColor={theme.colorTokens.surface.background}
                  text={<Lang id="widgets.onboarding.companyRequiredDocuments.disclaimer" />}
                  textFont="bodySMRegular"
                  textColor={theme.colorTokens.text.subtle}
                  fullWidth
                  withoutPadding
                />
              </>
            ),
          },
        ]
      : []),
    {
      heading: intl.formatMessage({ id: 'widgets.onboarding.heading.investorQuestionnaire' }),
      description: intl.formatMessage({
        id: userDetails.companyId
          ? 'widgets.onboarding.heading.investorQuestionnaire.legalEntity.description'
          : 'widgets.onboarding.heading.investorQuestionnaire.description',
      }),
      content: (
        <Button onClick={() => history.push(PATH_INVESTOR_QUESTIONNAIRE)} data-testid="complete-questionnaire-button">
          <Lang id="widgets.onboarding.completeQuestionnaire" />
        </Button>
      ),
    },
    {
      heading: intl.formatMessage({ id: 'widgets.onboarding.heading.firstDeposit' }),
      content: (
        <Button onClick={() => history.push(PATH_WALLET)} data-testid="add-money-button">
          <Lang id="widgets.onboarding.addMoney" />
        </Button>
      ),
    },
  ];

  let activeStep = 0;
  if (userDetails) {
    switch (userDetails.verificationStatus) {
      case UserVerificationStatus.NotVerified:
        activeStep = 1;
        break;

      case UserVerificationStatus.InProgress:
        activeStep = 1;
        break;

      case UserVerificationStatus.WaitingForApproval:
        if (userDetails.companyId) {
          activeStep = 2;
        } else {
          steps[activeStep].content = (
            <InfoLabel
              icon={
                <TooltipInfo
                  color={theme.colorTokens.icon.brand}
                  hoverColor={theme.colorTokens.icon.brand}
                  fillColor="transparent"
                  width="24px"
                  heightInherit
                />
              }
              backgroundColor={theme.colorTokens.surface.brandFaded25}
              text={
                <Lang
                  id="widgets.onboarding.waitingForApproval.info"
                  values={{
                    b: BoldString,
                  }}
                />
              }
              textFont="bodySMRegular"
              textColor="strong"
              fullWidth
            />
          );
        }
        break;

      case UserVerificationStatus.Declined:
        // eslint-disable-next-line no-case-declarations
        const declineReasons = userLocale === 'cs' ? userDetails.declineReasons?.cs : userDetails.declineReasons?.en;
        activeStep = 1;
        steps[activeStep].content = (
          <>
            <InfoLabel
              icon={
                <TooltipInfo
                  color={theme.colorTokens.utility.error.strong}
                  hoverColor={theme.colorTokens.utility.error.strong}
                  fillColor="transparent"
                  width="24px"
                  heightInherit
                />
              }
              backgroundColor={theme.colorTokens.utility.error.subtle}
              text={
                <Lang
                  id="widgets.onboarding.declined.info"
                  values={{
                    declineReasons,
                    b: BoldString,
                  }}
                />
              }
              textFont="bodySMRegular"
              textColor="strong"
              fullWidth
            />
            <Spacer height="massive" />
            <Button onClick={() => history.push(PATH_USER_VERIFICATION)} data-testid="repair-button">
              <Lang id="widgets.onboarding.declined.button" />
            </Button>
          </>
        );
        break;

      case UserVerificationStatus.Verified:
        if (userVerificationData && userVerificationData.suitability === null) {
          activeStep = 2 + addlegalEntityStepIfNeeded;
        } else if (!userTimestampsIsLoading && !userTimestamps?.abilityToBearLossQuestionnaire.endedAt) {
          activeStep = 2 + addlegalEntityStepIfNeeded;
          steps[activeStep].content = (
            <Button onClick={() => history.push(PATH_ABILITY_TO_BEAR_LOSS)} data-testid="complete-questionnaire-button">
              <Lang id="widgets.onboarding.completeQuestionnaire" />
            </Button>
          );
        } else if (!userDetails.madeDeposit) {
          activeStep = 3 + addlegalEntityStepIfNeeded;
          steps[activeStep].content = (
            <Button onClick={() => history.push(PATH_WALLET)} data-testid="add-money-button">
              <Lang id="widgets.onboarding.addMoney" />
            </Button>
          );
        } else {
          return null;
        }
        break;
    }
  }

  return (
    <>
      <Widget>
        <Typography variant="displayMDMedium" color="black">
          <Lang id="dashboard.onboardingStepper.salutation" values={{ salutation }} />
        </Typography>
        <Spacer height="large" />
        <Typography variant="bodyBASERegular" color="black">
          <Lang
            id="dashboard.onboardingStepper.description"
            values={{ numberOfUsers: numberWithSpaceSeparator(numberOfUsers, intl.locale, true) }}
          />
        </Typography>
        <Spacer height="massive" />
        <Row>
          <Column>
            <Stepper steps={steps} activeStep={activeStep} />
            <Spacer height="extraLarge" />
            {steps[activeStep].content}
          </Column>
          {!userDetails.companyId && <Image src={House} alt="House" />}
        </Row>
      </Widget>
      <Spacer height="extraLarge" />
    </>
  );
};

const Image = styled.img`
  max-width: 310px;
  align-self: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default OnboardingBanner;
