import React, { FC, useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import Check from 'components/V2/Icons/Check';
import Spacer from 'components/V2/Spacer/Spacer';

type Status = 'active' | 'done' | 'todo';

interface Step {
  heading: string;
  description?: string;
}
interface StepperProps {
  steps: Step[];
  activeStep: number;
}

const Stepper: FC<StepperProps> = ({ steps, activeStep }) => {
  const theme = useContext(ThemeContext);

  const getStepStatus = useCallback((definedActiveStep: number, currentIndex: number): Status => {
    if (currentIndex === definedActiveStep) {
      return 'active';
    }

    if (currentIndex < definedActiveStep) {
      return 'done';
    }

    return 'todo';
  }, []);

  const stepsLength = steps.length;

  return (
    <StepsContainer>
      {steps.map((step, index) => {
        return (
          <React.Fragment key={`${step.heading} ${step.description}`}>
            <Step status={getStepStatus(activeStep, index)} key={`${step.heading} ${step.description}`}>
              <Container>
                <Circle>
                  {getStepStatus(activeStep, index) === 'active' ? <BlueRound /> : null}
                  {getStepStatus(activeStep, index) === 'done' ? (
                    <Check
                      color={theme.colorTokens.utility.success.strong}
                      hoverColor={theme.colorTokens.utility.success.strong}
                      width="16px"
                    />
                  ) : null}
                </Circle>
                <Spacer width="medium" />
                <Heading>{step.heading}</Heading>
                {stepsLength !== index + 1 ? <StepDivider /> : null}
              </Container>
              <DescContainer>
                <CirclePlaceholder />
                <Spacer width="medium" />
                <Description>{step.description}</Description>
              </DescContainer>
            </Step>
          </React.Fragment>
        );
      })}
    </StepsContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const DescContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Circle = styled.div`
  border: 1px solid;
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const CirclePlaceholder = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
`;

const Heading = styled.div`
  font-size: ${({ theme }) => theme.typography.bodyBASEMedium.size};
  font-weight: ${({ theme }) => theme.typography.bodyBASEMedium.weight};
  line-height: ${({ theme }) => theme.typography.bodyBASEMedium.lineHeight};
  white-space: nowrap;
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.typography.labelXSRegular.size};
  font-weight: ${({ theme }) => theme.typography.labelXSRegular.weight};
  line-height: ${({ theme }) => theme.typography.labelXSRegular.lineHeight};
  color: ${({ theme }) => theme.colorTokens.text.subtle};
`;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Step = styled.div<{ status?: Status }>`
  ${({ theme, status }) =>
    status === 'active' &&
    `
    color: ${theme.colorTokens.text.strong};

    ${Circle} {
      border-color: ${theme.colorTokens.text.brand};
    }
  `}

  ${({ theme, status }) =>
    status === 'done' &&
    `
    color: ${theme.colorTokens.utility.success.strong};

    ${Circle} {
      background-color: ${theme.colorTokens.utility.success.subtle};
      border-color: ${theme.colorTokens.utility.success.strong};
    }
  `}

  ${({ theme, status }) =>
    status === 'todo' &&
    `
    color: ${theme.colorTokens.text.disabled};

    ${Circle} {
      border-color: ${theme.colorTokens.stroke.medium};
    }

    ${Description} {
      color: ${theme.colorTokens.text.disabled};
    }
  `}
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StepDivider = styled.div`
  height: 1px;
  width: 40px;
  margin: 0 ${({ theme }) => theme.spacing.large};
  background-color: ${({ theme }) => theme.colorTokens.text.invertedMedium};
`;

const BlueRound = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${({ theme }) => theme.colorTokens.text.brand};
  border-radius: 50%;
`;

export default Stepper;
