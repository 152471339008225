/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import styled from 'styled-components';

import { CoreSDK } from '@investown/fe/api-sdk';
import { createImageResizeUrl } from '@investown/fe/common-utils';

import { theme } from 'styles/theme';
import Arrow from 'components/V2/Icons/Arrow';
import Spacer from 'components/V2/Spacer/Spacer';

interface PhotoGalleryProps {
  photos: Omit<CoreSDK.PropertyPhotoObjectType, 'name'>[];
}

const imageSize = {
  full: {
    width: 776,
    height: 459,
  },
  thumbnail: {
    width: 53,
    height: 40,
  },
};
const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
  };

  const selectImage = (index: number) => {
    setCurrentImageIndex(index);
  };

  return (
    <GalleryContainer>
      <Image
        src={createImageResizeUrl({
          originalUrl: photos[currentImageIndex].url,
          width: imageSize.full.width * 2,
          height: imageSize.full.height * 2,
        })}
        alt={`Project image ${currentImageIndex + 1}`}
      />
      <ArrowWrapper className="left" onClick={prevImage}>
        <Arrow
          direction="left"
          color={theme.colorTokens.surface.card}
          hoverColor={theme.colorTokens.surface.cardHover}
        />
      </ArrowWrapper>
      <ArrowWrapper className="right" onClick={nextImage}>
        <Arrow
          direction="right"
          color={theme.colorTokens.surface.card}
          hoverColor={theme.colorTokens.surface.cardHover}
        />
      </ArrowWrapper>
      <Spacer height="small" />
      <ThumbnailRow>
        {photos.map((image, index) => (
          <React.Fragment key={`${index.toString()}`}>
            <Thumbnail
              src={createImageResizeUrl({
                originalUrl: image.url,
                width: imageSize.thumbnail.width * 2,
                height: imageSize.thumbnail.height * 2,
              })}
              alt={`Project image thumbnail ${index + 1}`}
              onClick={() => selectImage(index)}
              className={currentImageIndex === index ? 'active' : ''}
              width={imageSize.thumbnail.width}
              height={imageSize.thumbnail.height}
            />
            {index !== photos.length - 1 && <Spacer width="small" />}
          </React.Fragment>
        ))}
      </ThumbnailRow>
    </GalleryContainer>
  );
};

const GalleryContainer = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  display: block;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const ArrowWrapper = styled.button`
  position: absolute;
  top: calc(50% - 40px);
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.colorTokens.surface.overlayBlack};
  backdrop-filter: blur(20px);
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.medium};
  z-index: 100;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorTokens.surface.overlayWhite};
    svg path {
      stroke: ${({ theme }) => theme.colorTokens.surface.inverted950};
    }
  }

  &.left {
    left: ${({ theme }) => theme.spacing.large};
  }

  &.right {
    right: ${({ theme }) => theme.spacing.large};
  }
`;

const ThumbnailRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Thumbnail = styled.img<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  transition: border 0.3s ease;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;

  &:hover,
  &.active {
    border-color: ${({ theme }) => theme.colorTokens.surface.brand};
  }
`;

export default PhotoGallery;
