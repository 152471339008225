import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { IntlShape, useIntl } from 'react-intl';

import { CoreSDK, useInfiniteInvestmentRoundActivities } from '@investown/fe/api-sdk';
import {
  isCustomActivity,
  useAnalytics,
  projectDetailTabOpenedAction,
  ProjectDetailTabs,
  getActivityType,
  getActivityTitle,
} from '@investown/fe/common-utils';

import Lang from 'util/IntlMessages';
import { getAppLanguageLocalVariable } from 'lngProvider';
import Timeline, { DataItem } from 'components/V2/Timeline/Timeline';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import errorEmptyStates from 'constants/ErrorEmptyStates';
import InvisibleButton from 'components/InvisibleButton';
import ChevronForward from 'components/Icons/ChevronForward';
import Markdown from 'components/V2/Markdown/Markdown';
import Switch from 'components/V2/Switch/Switch';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';

interface Props {
  investmentRoundId: string;
  currentUsersTotalInvestment: number;
  showWithAutomaticActivities: boolean;
}

const ACTIVITIES_PER_PAGE = 10;

export default function TabLoanActivity({
  investmentRoundId,
  currentUsersTotalInvestment,
  showWithAutomaticActivities,
}: Props) {
  const analytics = useAnalytics();
  const location = useLocation();
  const [installmentsFilterEnabled, setInstallmentsFilterEnabled] = useState<boolean>(true);
  const intl = useIntl();

  const handleInstallmentsSwitchChange = useCallback(
    (checked: boolean) => {
      setInstallmentsFilterEnabled(checked);
    },
    [setInstallmentsFilterEnabled]
  );

  const { data, isLoading, isError, fetchNextPage, hasNextPage } = useInfiniteInvestmentRoundActivities(
    investmentRoundId,
    {
      perPage: ACTIVITIES_PER_PAGE,
      filter: {
        sources: showWithAutomaticActivities
          ? [
              CoreSDK.PropertyInvestmentRoundActivitySource.InvestownActivity,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundOpen,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundReOpen,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundFullyInvested,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundFunded,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundNotFunded,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundEnded,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundProlonged,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundRepaymentStatusCollection,
              CoreSDK.PropertyInvestmentRoundActivitySource.PropertyInvestmentRoundRepaymentStatusDelayed,
              CoreSDK.PropertyInvestmentRoundActivitySource.InvestmentMade,
              CoreSDK.PropertyInvestmentRoundActivitySource.InvestmentWithdrawn,
              CoreSDK.PropertyInvestmentRoundActivitySource.InvestmentRepaid,
              CoreSDK.PropertyInvestmentRoundActivitySource.InvestmentPartiallyRepaid,
              CoreSDK.PropertyInvestmentRoundActivitySource.InvestmentOfferPurchased,
              CoreSDK.PropertyInvestmentRoundActivitySource.InvestmentOfferSold,
              ...(installmentsFilterEnabled
                ? [
                    CoreSDK.PropertyInvestmentRoundActivitySource.LoanInterestPaymentReceived,
                    CoreSDK.PropertyInvestmentRoundActivitySource.LateInterestFeePaymentReceived,
                    CoreSDK.PropertyInvestmentRoundActivitySource.StatutoryLateInterestFeePaymentReceived,
                  ]
                : []),
            ]
          : [CoreSDK.PropertyInvestmentRoundActivitySource.InvestownActivity],
      },
    }
  );

  const activities = useMemo(() => {
    const uniqueActivities = data?.pages.reduce((map, page, pageIndex) => {
      page.propertyInvestmentRoundActivities.forEach((activity, index) => {
        if (!map.has(activity.id)) {
          map.set(activity.id, mapActivityToTimelineDataItem(activity, intl, pageIndex * ACTIVITIES_PER_PAGE + index));
        }
      });
      return map;
    }, new Map());

    return Array.from((uniqueActivities ?? []).values());
  }, [data?.pages, intl]);

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    analytics.trackEvent(
      projectDetailTabOpenedAction({
        path: location.pathname,
        tab: ProjectDetailTabs.Activity,
      })
    );
  }, [analytics, location]);

  const renderSwitch = useMemo(() => {
    if (showWithAutomaticActivities && currentUsersTotalInvestment > 0) {
      return (
        <>
          <FlexRow alignVertical={AlignDirections.Center}>
            <Switch
              testID="activities-installments-filter"
              checked={installmentsFilterEnabled}
              onChange={handleInstallmentsSwitchChange}
            />
            <Spacer width="16" />
            <Typography variant="labelBASERegular" color="medium">
              <Lang id="property.detail.activity.showInstallments" />
            </Typography>
          </FlexRow>
          <Spacer height="40" />
        </>
      );
    }
    return null;
  }, [
    showWithAutomaticActivities,
    currentUsersTotalInvestment,
    installmentsFilterEnabled,
    handleInstallmentsSwitchChange,
  ]);

  if (isError || (!isLoading && activities.length === 0)) {
    return (
      <>
        {renderSwitch}
        <ErrorEmptyState content={errorEmptyStates.emptyPropertyActivity} />
      </>
    );
  }

  return (
    <>
      {renderSwitch}
      <Timeline data={activities} loading={isLoading} />
      <div ref={infiniteScrollRef} />
    </>
  );
}

export function mapActivityToTimelineDataItem(
  activity: CoreSDK.PropertyInvestmentRoundActivitiesQuery['propertyInvestmentRoundActivities'][0],
  intl: IntlShape,
  itemIndex: number
): DataItem {
  const { formatMessage } = intl;
  const locale = getAppLanguageLocalVariable();
  // Activity description for non-custom activity is AKA title
  const description = activity.description.find(({ lang }) => lang === locale)?.description ?? '';
  const title =
    getActivityTitle({ activity, locale }) ?? formatMessage({ id: 'property.detail.activity.currentState' });

  return {
    id: activity.id,
    time: activity.occurredAt,
    type: getActivityType(activity.type),
    content: isCustomActivity(activity.source) ? (
      <ExpandableActivity title={title} initialExpanded={itemIndex === 0}>
        <Markdown>{description || ''}</Markdown>
      </ExpandableActivity>
    ) : (
      <Typography variant="labelBASEMedium" color="strong">
        {description}
      </Typography>
    ),
  };
}

function ExpandableActivity(props: PropsWithChildren<{ title?: string; initialExpanded?: boolean }>) {
  const { formatMessage } = useIntl();
  const {
    title = formatMessage({ id: 'property.detail.activity.currentState' }),
    initialExpanded = false,
    children,
  } = props;
  const theme = useTheme();
  const [height, setHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(initialExpanded);
  const contentRef = useRef<HTMLDivElement>(null);
  const toggleExpanded = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setHeight(isExpanded ? contentRef.current.scrollHeight : 0);
    }
  }, [isExpanded]);

  return (
    <ExpandableActivityWrapper>
      <InvisibleButton onClick={toggleExpanded}>
        <Typography variant="labelBASEMedium" color="strong">
          {title}
        </Typography>
        <Spacer width="8" />
        <ExpandableIcon isExpanded={isExpanded}>
          <ChevronForward width="15px" color={theme.colorTokens.text.subtle} />
        </ExpandableIcon>
      </InvisibleButton>
      {isExpanded ? (
        <ExpandableActivityContent height={height}>
          <div ref={contentRef}>
            <Spacer height="8" />
            {children}
          </div>
        </ExpandableActivityContent>
      ) : null}
    </ExpandableActivityWrapper>
  );
}

const ExpandableIcon = styled.div<{ isExpanded: boolean }>`
  transform: rotate(${({ isExpanded }) => (isExpanded ? '270deg' : '90deg')});
`;

const ExpandableActivityWrapper = styled.div`
  flex-direction: column;
`;

const ExpandableActivityContent = styled.div<{ height: number }>`
  max-height: ${(props) => props.height}px;
  overflow: hidden;
  transition: max-height 0.3s ease;

  & .markdown-element {
    div {
      margin-bottom: 0;
    }
  }
`;
