import React, { FC } from 'react';
import styled from 'styled-components';
import { IntlShape, useIntl } from 'react-intl';
import Big from 'big.js';

import { CoreSDK } from '@investown/fe/api-sdk';
import { percentageAmountAvailableForInvestment } from '@investown/fe/ui-utils/properties';
import { currencyFormat, numberWithSpaceSeparator, percentFormat } from '@investown/fe/common-utils';

import ProgressBar from 'components/V2/ProgressBar/ProgressBar';
import Spacer from 'components/V2/Spacer/Spacer';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';

interface ProgressInfoProps {
  investmentRoundStatus: CoreSDK.PropertyInvestmentRoundStatus;
  investmentAmountAvailable: number;
  investmentAmountTotal: number;
  investmentRoundCurrency: string;
  numberOfInvestors: number;
}

const ProgressInfo: FC<ProgressInfoProps> = ({
  investmentRoundStatus,
  investmentRoundCurrency,
  investmentAmountTotal,
  numberOfInvestors,
  investmentAmountAvailable,
}) => {
  const intl: IntlShape = useIntl();

  const preciseAvailableForInvestmentPercentage = percentageAmountAvailableForInvestment({
    investmentAmountAvailable,
    investmentAmountTotal,
    fraction: 2,
  });

  const availableForInvestmentPercentage = percentageAmountAvailableForInvestment({
    investmentAmountAvailable,
    investmentAmountTotal,
    fraction: 0,
  });

  const availableForInvestmentPercentageBig = new Big(availableForInvestmentPercentage);
  const availableForInvestmentPercent = new Big(100).minus(availableForInvestmentPercentageBig).div(100);

  return (
    <ViewContainer>
      {investmentRoundStatus === CoreSDK.PropertyInvestmentRoundStatus.Open ? (
        <>
          <ProgressBar percent={100 - preciseAvailableForInvestmentPercentage} />
          <Spacer height="large" />
          <FlexRow alignHorizontal={AlignDirections.SpaceBetween}>
            <Row>
              <FlexColumn>
                <Typography variant="labelBASESemiBold" color="strong">
                  {percentFormat({
                    input: availableForInvestmentPercent.toString(),
                    locale: intl.locale,
                  })}
                </Typography>
                <Spacer height="small" />
                <Typography variant="labelXSRegular" color="subtle">
                  <Lang id="property.investmentRoundInfo.invested" />
                </Typography>
              </FlexColumn>
              <Spacer width="large" />
              <FlexColumn>
                <Typography variant="labelBASESemiBold" color="strong">
                  {numberWithSpaceSeparator(numberOfInvestors.toString(), intl.locale)}
                </Typography>
                <Spacer height="small" />
                <Typography variant="labelXSRegular" color="subtle">
                  <Lang id="property.investmentRoundInfo.numberOfInvestors" />
                </Typography>
              </FlexColumn>
            </Row>
            <Row>
              <FlexColumn>
                <Typography variant="labelBASESemiBold" color="strong">
                  {currencyFormat({
                    input: investmentAmountAvailable,
                    currency: investmentRoundCurrency,
                    locale: intl.locale,
                  })}
                </Typography>
                <Spacer height="small" />
                <Typography variant="labelXSRegular" color="subtle">
                  <Lang id="property.investmentRoundInfo.investmentAmountAvailable" />
                </Typography>
              </FlexColumn>
            </Row>
          </FlexRow>
        </>
      ) : (
        <FlexRow alignHorizontal={AlignDirections.FlexStart}>
          <Row>
            <FlexColumn>
              <Typography variant="labelBASESemiBold" color="strong">
                {percentFormat({
                  input: availableForInvestmentPercent.toString(),
                  locale: intl.locale,
                })}
              </Typography>
              <Spacer height="small" />
              <Typography variant="labelXSRegular" color="subtle" numberOfLines={1} noWrap>
                <Lang
                  id="property.investmentRoundInfo.investmentAmountTotal"
                  values={{
                    number: currencyFormat({
                      input: investmentAmountTotal,
                      currency: investmentRoundCurrency,
                      locale: intl.locale,
                    }),
                  }}
                />
              </Typography>
            </FlexColumn>
            <Spacer width="large" />
            <FlexColumn>
              <Typography variant="labelBASESemiBold" color="strong">
                {numberWithSpaceSeparator(numberOfInvestors.toString(), intl.locale)}
              </Typography>
              <Spacer height="small" />
              <Typography variant="labelXSRegular" color="subtle">
                <Lang id="property.investmentRoundInfo.numberOfInvestors" />
              </Typography>
            </FlexColumn>
          </Row>
        </FlexRow>
      )}
    </ViewContainer>
  );
};
const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
`;

export default ProgressInfo;
