import { IntlShape } from 'react-intl';
import { RegisterOptions } from 'react-hook-form';

import {
  currencyFormat,
  deFormattedStringToFloat,
  isValidCzechBirthNumber,
  minOfferInvestmentAmount,
} from '@investown/fe/common-utils';

export const maxIntegerValue = 2000000000; // not real max int value but for better ux is this not so geeky number

export const mustContainNumberHF = (intl: IntlShape) => (value: string | undefined) => {
  if (value === undefined || value.trim() === '') {
    return intl.formatMessage({ id: 'global.validation.missingAddress' });
  }
  const pattern = /\d/;
  return pattern.test(value) || intl.formatMessage({ id: 'global.validation.address.oneNumber' });
};

interface MaxInvestmentValueParams {
  intl: IntlShape;
  max: number;
  limitPerUser: number;
  alreadyInvested: number;
  currency: string;
  errorId?: string;
}

interface MaxSecondaryMarketOfferParams {
  intl: IntlShape;
  max: number;
  currency: string;
}

interface MinSecondaryMarketOfferParams {
  intl: IntlShape;
  min: number;
  currency: string;
}

interface EnoughBalanceParams {
  intl: IntlShape;
  userAvailableBalance: number;
}

const isNumberValueValid = (value: number | null | undefined): boolean => value !== null && value !== undefined;

export const czechBirthNumberHF = (intl: IntlShape): ((value: unknown) => true | string) => {
  const message = intl.formatMessage({ id: 'global.validation.czechBirthNumber' });

  return (value: unknown): true | string => {
    if (!value) {
      return true;
    }
    if (typeof value !== 'string') {
      return `Expected the value to be a string, but received: ${typeof value}`;
    }
    if (!isValidCzechBirthNumber(value)) {
      return message;
    }
    return true;
  };
};

export const minPropertyInvestmentValueHF = (intl: IntlShape, min: number, currency: string) => (value: number) => {
  if (deFormattedStringToFloat(value.toString(), intl) < min) {
    return intl.formatMessage(
      { id: 'property.investmentPopUp.minInvestmentAmount' },
      {
        min: currencyFormat({
          input: min,
          currency,
          locale: intl.locale,
        }),
      }
    );
  }
  return true;
};

export const maxInvestmentValueHF =
  ({
    intl,
    max,
    limitPerUser,
    alreadyInvested,
    currency,
    errorId = 'property.investmentPopUp.maxInvestmentAmount',
  }: MaxInvestmentValueParams) =>
  (value: number) => {
    if (!isNumberValueValid(value)) {
      return true;
    }
    const limit = Math.min(max, limitPerUser - alreadyInvested);
    if (deFormattedStringToFloat(value.toString(), intl) > limit) {
      return intl.formatMessage(
        { id: errorId },
        {
          formattedLimit: currencyFormat({
            input: limit,
            currency,
            locale: intl.locale,
          }),
        }
      );
    }
    return true;
  };

export const enoughAvailableBalanceHF =
  ({ intl, userAvailableBalance }: EnoughBalanceParams) =>
  (value: number) => {
    if (deFormattedStringToFloat(value.toString(), intl) > userAvailableBalance) {
      return intl.formatMessage({ id: 'errors.investment.InsufficientFundsError' });
    }
    return true;
  };

export const maxDecimalDigitsHF = (intl: IntlShape, maxDigits: number) => (value: number) => {
  if (!isNumberValueValid(value)) {
    return true;
  }
  const rounded = Math.round(value * Math.pow(10, maxDigits)) / Math.pow(10, maxDigits);
  if (rounded !== value) {
    return intl.formatMessage({ id: 'global.validation.maxDecimalDigits' }, { maxDecimalDigits: maxDigits });
  }
  return true;
};

export const minWithdrawalValueHF = (intl: IntlShape, min: number, currency: string) => (value: number) => {
  if (deFormattedStringToFloat(value.toString(), intl) < min) {
    return intl.formatMessage(
      { id: 'widgets.wallet.tabs.withdrawal.minWithdrawAmount' },
      {
        amount: currencyFormat({
          input: min,
          currency,
          locale: intl.locale,
        }),
      }
    );
  }
  return true;
};

export const maxWithdrawalValueHF = (intl: IntlShape, max: number, currency: string) => (value: number) => {
  if (deFormattedStringToFloat(value.toString(), intl) > max) {
    return intl.formatMessage(
      { id: 'widgets.wallet.tabs.withdrawal.maxWithdrawAmount' },
      {
        amount: currencyFormat({
          input: max,
          currency,
          locale: intl.locale,
        }),
      }
    );
  }
  return true;
};

export const maxSecondaryMarketOfferValueHF =
  ({ intl, max, currency }: MaxSecondaryMarketOfferParams) =>
  (value: number) => {
    const formattedLimit = currencyFormat({
      input: max,
      currency,
      locale: intl.locale,
    });
    if (deFormattedStringToFloat(value.toString(), intl) > max) {
      return {
        max,
        message: intl.formatMessage({ id: 'property.investmentOfferPopUp.maxOfferAmount' }, { formattedLimit }),
        type: 'number',
      };
    }
    return true;
  };

export const minSecondaryMarketOfferValueHF =
  ({ intl, min, currency }: MinSecondaryMarketOfferParams) =>
  (value: number) => {
    const formattedLimit = currencyFormat({
      input: minOfferInvestmentAmount,
      currency,
      locale: intl.locale,
    });
    if (deFormattedStringToFloat(value.toString(), intl) < min) {
      return {
        min: minOfferInvestmentAmount,
        message: intl.formatMessage({ id: 'property.investmentOfferPopUp.minOfferAmount' }, { formattedLimit }),
        type: 'number',
      };
    }
    return true;
  };

export const nonWhitespacePasswordHF = (intl: IntlShape) => (value: string) => {
  const pattern = new RegExp('^[\\S]+.*[\\S]+$');
  if (!pattern.test(value)) {
    return intl.formatMessage({ id: 'errors.password.notAllowedSymbols' });
  }
  return true; // or return true if validation is successful
};

export const requiredInputHF = (intl: IntlShape): RegisterOptions => ({
  required: {
    value: true,
    message: intl.formatMessage({ id: 'global.validation.required' }),
  },
});

export const minValueHF = (intl: IntlShape, min: number | undefined) => (value: number) => {
  if (min === undefined || deFormattedStringToFloat(value.toString(), intl) < min) {
    return intl.formatMessage({ id: 'global.validation.minValue' }, { minValue: min });
  }
  return true;
};

export const maxValueHF = (intl: IntlShape, max: number | undefined) => (value: number) => {
  if (max === undefined || deFormattedStringToFloat(value.toString(), intl) > max) {
    return intl.formatMessage({ id: 'global.validation.maxValue' }, { maxValue: max });
  }
  return true;
};

export const minValueWithCurrencyHF = (intl: IntlShape, min: number, currency: string) => (value: number) => {
  if (min === undefined || deFormattedStringToFloat(value.toString(), intl) < min) {
    return intl.formatMessage(
      { id: 'global.validation.minValue' },
      {
        minValue: currencyFormat({
          input: min,
          currency,
          locale: intl.locale,
        }),
      }
    );
  }
  return true;
};

export const maxValueWithCurrencyHF = (intl: IntlShape, max: number, currency: string) => (value: number) => {
  if (max === undefined || deFormattedStringToFloat(value.toString(), intl) > max) {
    return intl.formatMessage(
      { id: 'global.validation.maxValue' },
      {
        maxValue: currencyFormat({
          input: max,
          currency,
          locale: intl.locale,
        }),
      }
    );
  }
  return true;
};

export const sanitizedValue = (value: string | number | undefined): boolean => {
  return value !== undefined;
};
