import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { defaultWalletCurrency, getCurrencyFormatParts } from '@investown/fe/common-utils';

import Spacer from '../Spacer/Spacer';
import FlexColumn from '../Grid/FlexColumn/FlexColumn';
import FlexRow, { AlignDirections } from '../Grid/FlexRow/FlexRow';

import Button from 'components/Button';
import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';

interface WalletWidgetProps {
  amount: number;
  currency: string;
  onClick: (amount: number) => void;
  fullWidth?: boolean;
}
const WalletWidget: FC<WalletWidgetProps> = ({ amount, currency, onClick, fullWidth = false }) => {
  const intl = useIntl();
  const formatParts = getCurrencyFormatParts({
    input: amount,
    currency: currency || defaultWalletCurrency,
    locale: intl.locale,
  });

  const handleOnClick = useCallback(() => {
    onClick(amount);
  }, [onClick, amount]);

  return (
    <Container
      alignVertical={AlignDirections.Center}
      alignHorizontal={AlignDirections.SpaceBetween}
      fullWidth={fullWidth}
    >
      <FlexColumn alignVertical={AlignDirections.FlexStart}>
        <Typography variant="labelSMRegular" color="medium">
          <Lang id="secondaryMarketOfferInvestmentWalletWidget.title" />
        </Typography>
        <Spacer height="small" />
        <FlexRow alignVertical={AlignDirections.Baseline} alignHorizontal={AlignDirections.FlexStart}>
          <Typography variant="body2XLMedium" color="strong">
            {formatParts.value}
          </Typography>
          <Spacer width="small" />
          <Typography variant="bodyLGRegular" color="strong">
            {formatParts.currency}
          </Typography>
        </FlexRow>
      </FlexColumn>
      <StyledButton color="primary" block outline size="medium" onClick={handleOnClick}>
        <Lang id="secondaryMarketOfferInvestmentWalletWidget.button" />
      </StyledButton>
    </Container>
  );
};

const Container = styled(FlexRow)<{ fullWidth?: boolean }>`
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '462px;')};
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.extraLarge}`};
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  border: ${({ theme }) => `1px solid ${theme.colorTokens.stroke.medium}`};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const StyledButton = styled(Button)`
  margin: 0;
  max-width: 104px;
`;

export default WalletWidget;
