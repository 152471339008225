/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useContext, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { TestIds } from '@investown/fe/test-utils/testIds';
import { WithChildren } from '@investown/fe/common-utils';

import Typography from 'components/V2/Typography/Typography';
import CloseOutlineIcon from 'components/Icons/CloseOutlineIcon';

export interface Props extends WithChildren {
  visible: boolean;
  onClose: () => void;
  header?: string | React.ReactNode | string[] | React.ReactNode[];
  closeModalOnBackdropClick?: boolean;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  currentPage?: number;
}

const ModalV3: FC<Props> = (props) => {
  const {
    children,
    visible,
    onClose,
    header,
    minWidth,
    maxWidth,
    minHeight,
    currentPage = 0,
    closeModalOnBackdropClick = false,
  } = props;
  const theme = useContext(ThemeContext);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const disableHorizontalScroll = (event: WheelEvent) => {
    if (event.deltaX !== 0) {
      event.preventDefault();
    }
  };
  if (visible && modalRef.current) {
    modalRef.current.addEventListener('wheel', disableHorizontalScroll, { passive: false });
  }
  if (!visible && modalRef.current) {
    modalRef.current.removeEventListener('wheel', disableHorizontalScroll);
  }
  const handleScroll = () => {
    if (header && Array.isArray(header) && header.length > 1 && modalHeaderRef.current) {
      const container = modalHeaderRef.current;
      const containerWidth = container.offsetWidth;

      const targetScrollPosition = currentPage * containerWidth;

      container.scrollTo({
        left: targetScrollPosition,
        behavior: 'smooth',
      });
    }

    if (modalBodyRef.current) {
      const container = modalBodyRef.current;
      const containerWidth = container.offsetWidth;

      const targetScrollPosition = currentPage * containerWidth;

      container.scrollTo({
        left: targetScrollPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const pages = React.Children.toArray(children);

  return visible ? (
    <ModalContainer ref={modalRef} maxWidth={maxWidth}>
      <ModalWrapper minHeight={minHeight}>
        <ModalHeader ref={modalHeaderRef} currentPage={currentPage}>
          {header &&
            Array.isArray(header) &&
            header.length > 1 &&
            header.map((headerPage, index) => <HeadContainer key={index}>{headerPage}</HeadContainer>)}
          {header && !Array.isArray(header) && (
            <HeadContainer>
              <Typography variant="displayXSMedium" color="strong">
                {header}
              </Typography>
              <CloseButton onClick={onClose} data-testid={TestIds.CloseModal}>
                <CloseOutlineIcon color={theme.colorTokens.icon.medium} />
              </CloseButton>
            </HeadContainer>
          )}
        </ModalHeader>
        <ModalBody ref={modalBodyRef} currentPage={currentPage} minWidth={minWidth}>
          {pages.map((page, index) => (
            <PageContainer key={index}>{page}</PageContainer>
          ))}
        </ModalBody>
      </ModalWrapper>
      <ModalBackdrop onClick={closeModalOnBackdropClick ? onClose : undefined} />
    </ModalContainer>
  ) : null;
};

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colorTokens.surface.overlayBlack};
`;

const ModalContainer = styled.div<{ isShown?: boolean; maxWidth?: number }>`
  display: flex;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  transition: 300ms all;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
  z-index: 1000;
`;

const ModalWrapper = styled.div<{ minHeight?: number }>`
  position: relative;
  margin: 100px auto auto auto;
  min-width: 300px;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '300px;')};
  height: auto;
  max-height: calc(100vh - 20%);
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${({ theme }) => theme.colorTokens.surface.card};
  z-index: 1001;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  flex-direction: column;
  overflow-y: hidden;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.large};

  span {
    border-radius: 50%;
    background: ${({ theme }) => theme.colorTokens.surface.subtle};
    padding: ${({ theme }) => theme.spacing.medium};
  }
`;

const ModalHeader = styled.div<{ currentPage: number }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.extraLarge} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.stroke.medium};
  transition: transform 0.3s ease 0.2s;
  transform: ${({ currentPage }) => `translateX(-${currentPage})`};
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 11 */

  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

const ModalBody = styled.div<{ minWidth?: number; currentPage: number }>`
  display: flex;
  width: 100%;
  height: 100%;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  transition: transform 0.3s ease 0.2s;
  transform: ${({ currentPage }) => `translateX(-${currentPage})`};
  overflow-x: ${({ currentPage }) => (currentPage > 0 ? 'hidden' : 'auto')};
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 11 */

  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

const HeadContainer = styled.div`
  min-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing.massive}`};
`;

const PageContainer = styled.div`
  min-width: 100%;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.massive}`};
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default ModalV3;
