import React, { FC } from 'react';

import { ReactComponent as IconSVG } from 'assets/images/icons/danger-regular.svg';
import Icon, { IconProps } from 'components/V2/Icons';

type IconOptions = {
  variant: 'regular';
};

const Danger: FC<IconProps & IconOptions> = (props) => {
  const { color, hoverColor, className, width, variant } = props;

  return (
    <Icon color={color} fillColor="transparent" hoverColor={hoverColor} className={className} width={width}>
      {variant === 'regular' ? <IconSVG /> : null}
    </Icon>
  );
};

export default Danger;
