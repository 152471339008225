import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface LayoutContainerContentProps {
  children: ReactNode;
  isColumn?: boolean;
  noPadding?: boolean;
}

const LayoutContainerContent: React.FC<LayoutContainerContentProps> = ({ children, isColumn, noPadding }) => (
  <StyledLayoutContainerContent isColumn={isColumn} noPadding={noPadding} className="layout-container-content">
    {children}
  </StyledLayoutContainerContent>
);

const StyledLayoutContainerContent = styled.div<{ isColumn?: boolean; noPadding?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: ${({ theme, noPadding }) => (noPadding ? 0 : theme.spacing.huge)};
  ${({ isColumn }) =>
    isColumn &&
    `
    flex-direction: column;
  `}
`;

export default LayoutContainerContent;
