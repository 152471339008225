import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { CoreSDK, usePropertyInvestments } from '@investown/fe/api-sdk';

import MyInvestment from 'components/V2/MyInvestment/MyInvestment';
import Spacer from 'components/V2/Spacer/Spacer';

interface Props {
  status: CoreSDK.PropertyInvestmentRoundStatus;
  type: CoreSDK.PropertyInvestmentRoundType;
  investmentTermEnd?: number | null;
  currentUsersTotalInvestment: number;
  investmentCurrency: string;
  currentYield?: number;
  expectedYield?: number;
  onInvestmentOfferDropdownButtonClick: () => void;
  onWithdrawFromInvestmentDropdownButtonClick: () => void;
  bonusYieldPaidOut?: number;
}

const MyInvestmentWidget: FC<Props> = ({
  status,
  type,
  investmentTermEnd,
  currentUsersTotalInvestment,
  investmentCurrency,
  currentYield,
  expectedYield,
  onInvestmentOfferDropdownButtonClick,
  onWithdrawFromInvestmentDropdownButtonClick,
  bonusYieldPaidOut,
}) => {
  const showProgressBar =
    status === CoreSDK.PropertyInvestmentRoundStatus.Funded ||
    (status === CoreSDK.PropertyInvestmentRoundStatus.Unknown &&
      currentYield !== undefined &&
      expectedYield !== undefined);

  const { slug } = useParams<{ slug: string }>();

  const investmentsResult = usePropertyInvestments(slug);

  const isInvestmentRoundEnded = !!investmentTermEnd && investmentTermEnd < Date.now();

  const investmentOfferDropdownButton =
    (type === CoreSDK.PropertyInvestmentRoundType.Legacy && !isInvestmentRoundEnded) ||
    status === CoreSDK.PropertyInvestmentRoundStatus.Funded
      ? {
          labelTranslationKey: 'property.offerInvestment.button',
          action: onInvestmentOfferDropdownButtonClick,
        }
      : undefined;

  const withdrawFromInvestmentDropdownButton =
    investmentsResult.status === 'success' && investmentsResult.investments.canBeAnyWithdrawn
      ? {
          labelTranslationKey: 'property.withdrawFromInvestment.button',
          action: onWithdrawFromInvestmentDropdownButtonClick,
        }
      : undefined;

  if (currentUsersTotalInvestment === 0) {
    return null;
  }

  return (
    <>
      <Spacer height="huge" />
      <MyInvestment
        showProgressBar={showProgressBar}
        currentUsersTotalInvestment={currentUsersTotalInvestment}
        investmentCurrency={investmentCurrency}
        currentYield={currentYield}
        expectedYield={expectedYield}
        investmentOfferDropdownButton={investmentOfferDropdownButton}
        withdrawFromInvestmentDropdownButton={withdrawFromInvestmentDropdownButton}
        bonusYieldPaidOut={bonusYieldPaidOut}
      />
    </>
  );
};

export default MyInvestmentWidget;
