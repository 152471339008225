import { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';

import { randomInRange } from '@investown/fe/common-utils';

import { ConfettiTypes } from 'constants/ConfettiTypes';

type ConfettiFireReturn = Promise<null> | null | void;
interface ConfettiCallback {
  fire: () => ConfettiFireReturn;
}

const useConfetti = (type: ConfettiTypes): ConfettiCallback => {
  const [confettiConfig, setConffetiConfig] = useState<confetti.Options>();

  useEffect(() => {
    switch (type) {
      case ConfettiTypes.LeftToRightBomb:
        setConffetiConfig({
          angle: 30,
          spread: randomInRange(50, 100),
          particleCount: randomInRange(150, 300),
          origin: { y: 0.5, x: 0 },
          startVelocity: 80,
        });
        break;

      default:
        setConffetiConfig({
          angle: 90,
          spread: randomInRange(100, 150),
          particleCount: randomInRange(200, 350),
          origin: { y: 1, x: 0.5 },
          startVelocity: 70,
        });
        break;
    }
  }, [type]);

  const fire = (): ConfettiFireReturn => {
    if (confettiConfig) {
      return confetti(confettiConfig);
    }
  };

  return { fire };
};

export default useConfetti;
