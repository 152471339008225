import React, { FC } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Link } from 'react-router-dom';

import { usePartnerRelatedProjects } from '../../helpers';

import RelatedProjectsListSkeletonCard from './RelatedProjectsListSkeletonCard';

import Spacer from 'components/Spacer';
import LoadingWidget from 'components/V2/LoadingWidget/LoadingWidget';
import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import errorEmptyStates from 'constants/ErrorEmptyStates';
import ProjectListCardPartner from 'containers/Property/Tabs/RelatedProjects/ProjectListCardPartner';
import DividerHorizontal from 'components/V2/Divider/DividerHorizontal/DividerHorizontal';
import { createPropertyUrl } from 'util/urlUtils';
import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';

interface Props {
  slug: string;
  borrowerId?: string;
}

const pageSize = 10;

const RelatedProjectsList: FC<Props> = ({ slug, borrowerId }) => {
  const { data, isLoading, isIdle, isFetchingNextPage, isError, hasNextPage, fetchNextPage } =
    usePartnerRelatedProjects({
      pageSize,
      slug,
      borrowerId,
    });

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    rootMargin: '0px 0px 400px 0px',
  });

  if (isLoading || isIdle) {
    return (
      <>
        <Spacer height="extraLarge" />
        <LoadingWidget />
      </>
    );
  }

  if (isError) {
    return <ErrorEmptyState content={errorEmptyStates.errorFetchData} />;
  }

  const partnerRelatedItems = data.pages.map((query) => query.relatedProperties).flat();

  if (partnerRelatedItems.length === 0) {
    return (
      <>
        <Spacer height="large" />
        <Typography variant="bodyBASERegular" color="subtle">
          <Lang id="empty.relatedProjectsList.description" />
        </Typography>
      </>
    );
  }

  return (
    <>
      {partnerRelatedItems.map((project, index) => (
        <React.Fragment key={project.id}>
          <Link to={createPropertyUrl(project.slug)}>
            <ProjectListCardPartner project={project} />
          </Link>
          <Spacer height="extraLarge" />
          {index !== partnerRelatedItems.length - 1 && (
            <>
              <DividerHorizontal />
              <Spacer height="extraLarge" />
            </>
          )}
        </React.Fragment>
      ))}
      <div ref={infiniteScrollRef} />
      {isFetchingNextPage && <RelatedProjectsListSkeletonCard />}
    </>
  );
};

export default RelatedProjectsList;
