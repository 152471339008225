import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { CoreSDK } from '@investown/fe/api-sdk';
import { useAnalytics, projectDetailTabOpenedAction, ProjectDetailTabs } from '@investown/fe/common-utils';

import Accordion from 'components/V2/Accordion/Accordion';

interface Props {
  investmentRoundType: CoreSDK.PropertyInvestmentRoundType;
  isPropertyPremium?: boolean;
}

const TabPropertyFAQ: FC<Props> = ({ investmentRoundType, isPropertyPremium = false }) => {
  const intl = useIntl();
  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.trackEvent(
      projectDetailTabOpenedAction({
        path: location.pathname,
        tab: ProjectDetailTabs.FAQ,
      })
    );
  }, [analytics, location]);

  const { secondaryMarketOfferId } = useParams<{ secondaryMarketOfferId: string }>();
  const type = secondaryMarketOfferId ? 'secondary' : 'primary';
  const faqItems = [
    {
      id: 1,
      question: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.1.title`,
      }),
      answer: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.1.content${isPropertyPremium ? '.premium' : ''}`,
      }),
    },
    {
      id: 2,
      question: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.2.title`,
      }),
      answer: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.2.content`,
      }),
    },
    {
      id: 3,
      question: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.3.title`,
      }),
      answer: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.3.content`,
      }),
    },
    {
      id: 4,
      question: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.4.title`,
      }),
      answer: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.4.content`,
      }),
    },
    {
      id: 5,
      question: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.5.title`,
      }),
      answer: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.5.content${
          isPropertyPremium && !!secondaryMarketOfferId ? '.premium' : ''
        }`,
      }),
    },
    {
      id: 6,
      question: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.6.title`,
      }),
      answer: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.6.content`,
      }),
    },
    ...(investmentRoundType !== CoreSDK.PropertyInvestmentRoundType.Legacy
      ? [
          {
            id: 7,
            question: intl.formatMessage({
              id: `property.detail.faq.${type}.${investmentRoundType}.7.title`,
            }),
            answer: intl.formatMessage({
              id: `property.detail.faq.${type}.${investmentRoundType}.7.content`,
            }),
          },
        ]
      : []),
    {
      id: 8,
      question: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.8.title`,
      }),
      answer: intl.formatMessage({
        id: `property.detail.faq.${type}.${investmentRoundType}.8.content${
          isPropertyPremium && !secondaryMarketOfferId ? '.premium' : ''
        }`,
      }),
    },
  ];
  return <Accordion items={faqItems} />;
};
export default TabPropertyFAQ;
