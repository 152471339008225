import { getCurrentAppLocaleObject } from 'lngProvider';

interface ExternalUrlObject {
  Risk: string;
  Statistics: string;
  ExchangeRate: string;
  SignUpLandingPage: string;
  InvestmentContract: string;
}

const CsExternalUrls: ExternalUrlObject = {
  Risk: 'https://www.investown.cz/rating',
  Statistics: 'https://www.investown.cz/statistiky',
  ExchangeRate: 'https://www.csas.cz/cs/kurzovni-listek#/exchange/type/NONCASH/from/CZK/to/EUR',
  SignUpLandingPage: 'https://www.investown.cz',
  InvestmentContract: 'https://www.investown.cz/investicni-smlouva',
};

const EnExternalUrls: ExternalUrlObject = {
  Risk: 'https://www.investown.com/rating',
  Statistics: 'https://www.investown.com/statistics',
  ExchangeRate: 'https://www.csas.cz/en/kurzovni-listek#/exchange/type/NONCASH/from/CZK/to/EUR',
  SignUpLandingPage: 'https://www.investown.com',
  InvestmentContract: 'https://www.investown.cz/investicni-smlouva',
};

export const tagsToRemovePDFToolbars = '#toolbar=0&navpanes=0&scrollbar=0';

export function getExternalURL(url: keyof ExternalUrlObject, removePDFToolbars?: boolean): string {
  const currentAppLocale = getCurrentAppLocaleObject();
  let externalUrls: ExternalUrlObject;

  switch (currentAppLocale.locale) {
    case 'cs-CZ':
    case 'sk-SK':
      externalUrls = CsExternalUrls;
      break;
    default:
      externalUrls = EnExternalUrls;
      break;
  }

  if (removePDFToolbars) {
    return externalUrls[url] + tagsToRemovePDFToolbars;
  }
  return externalUrls[url];
}
