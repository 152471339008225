import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TextColorName, calculateBonusYield, percentFormat } from '@investown/fe/common-utils';

import PerAnnum from './PerAnnum';

import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import { TypographyName } from 'styles/theme';

interface PerAnnumBonusYieldProps {
  bonusYieldEligible: boolean;
  bonusYieldForUser: number | undefined;
  annualPercentageYield: number;
  layout: 'row' | 'column';
  valueFont?: TypographyName;
  valueColor?: TextColorName;
  suffixFont?: TypographyName;
  suffixColor?: TextColorName;
  strikeThroughFont?: TypographyName;
  strikeThroughColor?: TextColorName;
}

const PerAnnumBonusYield: FC<PerAnnumBonusYieldProps> = ({
  bonusYieldEligible,
  bonusYieldForUser,
  annualPercentageYield,
  layout,
  valueFont,
  valueColor,
  suffixFont,
  suffixColor,
  strikeThroughFont = 'labelSMMedium',
  strikeThroughColor = 'subtle',
}) => {
  const intl = useIntl();

  if (bonusYieldEligible && bonusYieldForUser !== undefined && bonusYieldForUser > 0) {
    const totalBonusYield = calculateBonusYield({
      annualPercentageYield,
      bonusYield: bonusYieldForUser,
    });
    return (
      <Container layout={layout}>
        <PerAnnum
          pa={totalBonusYield}
          valueFont={valueFont}
          valueColor={valueColor}
          suffixFont={suffixFont}
          suffixColor={suffixColor}
        />
        {layout === 'row' ? <Spacer width="small" /> : <Spacer height="medium" />}
        <Typography variant={strikeThroughFont} color={strikeThroughColor} isStrikeThrough>
          {percentFormat({
            input: annualPercentageYield,
            locale: intl.locale,
          })}
        </Typography>
      </Container>
    );
  }
  return (
    <PerAnnum
      pa={annualPercentageYield}
      valueFont={valueFont}
      valueColor={valueColor}
      suffixFont={suffixFont}
      suffixColor={suffixColor}
    />
  );
};

const Container = styled.div<{ layout: 'row' | 'column' }>`
  ${({ layout }) =>
    layout === 'row' &&
    `
  display: flex;
  flex-direction: row;
  align-items: baseline;
  `};
`;

export default PerAnnumBonusYield;
