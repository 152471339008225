import React, { FC } from 'react';
import styled from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

const InvestmentInfo: FC<WithChildren> = ({ children }) => <Container>{children}</Container>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default InvestmentInfo;
