import React from 'react';
import styled from 'styled-components';

import Spacer from 'components/V2/Spacer/Spacer';
import Typography from 'components/V2/Typography/Typography';

export type StatusLabelColor = 'primary' | 'error' | 'warning' | 'success' | 'default';
interface Props {
  color: StatusLabelColor;
  textLeft: React.ReactNode;
  textRight?: React.ReactNode;
  icon?: React.ReactNode;
  isThin?: boolean;
}

function StatusLabel({ icon, color, textLeft, textRight, isThin = false }: Props) {
  return (
    <>
      <Wrapper color={color} isThin={isThin}>
        <LeftSide>
          {icon && icon}
          <Spacer width="small" />
          {textLeft && <Typography variant="labelSMMedium">{textLeft}</Typography>}
        </LeftSide>
        {textRight && <Typography variant="labelSMRegular">{textRight}</Typography>}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div<{ color: Props['color']; isThin?: boolean }>`
  ${(props) => {
    switch (props.color) {
      case 'primary':
        return `
        color: ${props.theme.colorTokens.text.brand};
        background-color: ${props.theme.colorTokens.surface.brandFaded25};
        svg {
          color: ${props.theme.colorTokens.text.brand};
          path {
            stroke: ${props.theme.colorTokens.text.brand};
          }
        }
      `;
      case 'error':
        return `
        color: ${props.theme.colorTokens.text.error};
        background-color: ${props.theme.colorTokens.utility.error.subtle};
        svg {
          color: ${props.theme.colorTokens.text.error};
          path {
            stroke: ${props.theme.colorTokens.text.error};
          }
        }
      `;
      case 'warning':
        return `
        color: ${props.theme.colorTokens.text.warning};
        background-color: ${props.theme.colorTokens.utility.warning.subtle};
        svg {
          color: ${props.theme.colorTokens.text.warning};
          path {
            stroke: ${props.theme.colorTokens.text.warning};
          }
        }
      `;
      case 'success':
        return `
        color: ${props.theme.colorTokens.text.success};
        background-color: ${props.theme.colorTokens.utility.success.subtle};
        svg {
          color: ${props.theme.colorTokens.text.success};
          path {
            stroke: ${props.theme.colorTokens.text.success};
          }
        }
      `;
      case 'default':
        return `
        color: ${props.theme.colorTokens.text.medium};
        background-color: ${props.theme.colorTokens.surface.subtle};
        svg {
          color: ${props.theme.colorTokens.text.medium};
          path {
            stroke: ${props.theme.colorTokens.text.medium};
          }
        }
      `;
    }
  }};
  padding: ${(props) => props.theme.spacing.large};
  border-radius: ${(props) => props.theme.borderRadius.small};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  width: 100%;
  ${({ isThin }) => isThin && `max-height: 32px;`}
  svg {
    width: 16px;
    height: 16px;
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export default React.memo(StatusLabel);
