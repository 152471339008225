import React, { useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';

import { CoreSDK, isProjectExpired, isProjectOpen } from '@investown/fe/api-sdk';
import { currencyFormat, calculateBonusYield, numberWithDecimal, percentFormat } from '@investown/fe/common-utils';
import { RemainingTime } from '@investown/fe/ui-utils/RemainingTime';
import { InvestmentLengthInMonths } from '@investown/fe/ui-utils/InvestmentLengthInMonths';
import { PropertyPurpose } from '@investown/fe/ui-utils/PropertyPurpose';
import { isPropertyPremium } from '@investown/fe/ui-utils/properties';

import { getAppLanguageLocalVariable } from 'lngProvider';
import Spacer from 'components/Spacer';
import InvestmentInfoRow from 'components/V2/InvestmentInfo/InvestmentInfoRow';
import InvestmentInfo from 'components/V2/InvestmentInfo/InvestmentInfo';
import Lang from 'util/IntlMessages';
import Typography from 'components/V2/Typography/Typography';
import withUserDetailsAndLevels, { WithUserDetailsAndLevelsProps } from 'util/withUserDetailsAndLevels';
import PerAnnum from 'components/V2/PerAnnum/PerAnnum';
import ChevronDown from 'components/Icons/ChevronDown';

type Props = {
  property: CoreSDK.PropertyObjectType;
} & WithUserDetailsAndLevelsProps;

const InvestmentInfoWidget: React.FC<Props> = (props) => {
  const intl: IntlShape = useIntl();
  const { property, bonusYieldForUser } = props;
  const { city, countryIsoCode, investmentRound } = property;
  const userLocale = getAppLanguageLocalVariable();
  const [isMultiplePurposeVisible, setIsMultiplePurposeVisible] = useState(false);

  const {
    type,
    investmentCurrency,
    investmentAmountTotal,
    investmentTermEnd,
    legacyEndedAt,
    loanToValue,
    collateral,
    investmentLengthInMonths,
    availableForInvestmentTo,
    purposes,
  } = investmentRound;

  const isExpired = isProjectExpired(investmentRound.status);
  const isOpen = isProjectOpen(investmentRound.status);
  const isPremium = isPropertyPremium(investmentRound);

  // Move Other purpose to the end of the list
  const sortedPurposes = purposes.sort((a, b) => {
    if (a === CoreSDK.PropertyInvestmentRoundPurpose.Other) return 1;
    if (b === CoreSDK.PropertyInvestmentRoundPurpose.Other) return -1;
    return 0;
  });

  const totalBonusYield = calculateBonusYield({
    annualPercentageYield: investmentRound.annualPercentageYield,
    bonusYield: bonusYieldForUser ?? 0,
  });

  return (
    <>
      <Typography variant="labelLGRegular" color="strong">
        <Lang id="widgets.investmentRoundInfo.investment" />
      </Typography>
      <Spacer height="large" />
      <InvestmentInfo>
        <InvestmentInfoRow
          title="widgets.investmentRoundInfo.investmentYieldBasic"
          tooltip="widgets.investmentRoundInfo.investmentYieldBasic.tooltip"
        >
          <PerAnnum
            pa={investmentRound.annualPercentageYield}
            valueFont="labelBASERegular"
            valueColor="strong"
            suffixFont="labelBASERegular"
            suffixColor="strong"
          />
        </InvestmentInfoRow>
        <Spacer height="regular" />

        {isPremium && investmentRound.endPercentageBonus > 0 && (
          <>
            <InvestmentInfoRow
              title="widgets.investmentRoundInfo.adjustedYield"
              tooltip="widgets.investmentRoundInfo.adjustedYield.tooltip"
            >
              <PerAnnum
                pa={investmentRound.adjustedYield}
                valueFont="labelBASERegular"
                valueColor="strong"
                suffixFont="labelBASERegular"
                suffixColor="strong"
              />
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        <>
          <InvestmentInfoRow
            title="widgets.investmentRoundInfo.bonusYieldEligible"
            tooltip="widgets.investmentRoundInfo.bonusYieldEligible.tooltip"
          >
            <Lang id={investmentRound.bonusYieldEligible ? 'global.yes' : 'global.no'} />
          </InvestmentInfoRow>
          <Spacer height="regular" />
        </>

        {investmentRound.bonusYieldEligible && bonusYieldForUser !== undefined && (
          <>
            <InvestmentInfoRow
              title="widgets.investmentRoundInfo.investmentYieldBonus"
              tooltip="widgets.investmentRoundInfo.investmentYieldBonus.tooltip"
            >
              <PerAnnum
                pa={bonusYieldForUser}
                valueFont="labelBASERegular"
                valueColor="strong"
                suffixFont="labelBASERegular"
                suffixColor="strong"
              />
            </InvestmentInfoRow>
            <Spacer height="regular" />
            <InvestmentInfoRow
              title="widgets.investmentRoundInfo.investmentYieldTotal"
              tooltip="widgets.investmentRoundInfo.investmentYieldTotal.tooltip"
            >
              <PerAnnum
                pa={totalBonusYield}
                valueFont="labelBASERegular"
                valueColor="strong"
                suffixFont="labelBASERegular"
                suffixColor="strong"
              />
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {type === CoreSDK.PropertyInvestmentRoundType.Crowdfunding &&
          isOpen &&
          availableForInvestmentTo &&
          availableForInvestmentTo > Date.now() && (
            <>
              <InvestmentInfoRow
                title="widgets.investmentRoundInfo.timeLeft"
                tooltip="widgets.investmentRoundInfo.timeLeft.tooltip"
              >
                <RemainingTime date={availableForInvestmentTo} userLocale={userLocale} />
              </InvestmentInfoRow>
              <Spacer height="regular" />
            </>
          )}

        <InvestmentInfoRow
          title="widgets.investmentRoundInfo.availableTotal"
          tooltip="widgets.investmentRoundInfo.availableTotal.tooltip"
        >
          {currencyFormat({ input: investmentAmountTotal, currency: investmentCurrency, locale: intl.locale })}
        </InvestmentInfoRow>
        <Spacer height="regular" />

        {!investmentTermEnd && (
          <>
            <InvestmentInfoRow
              title="widgets.investmentRoundInfo.investmentLengthInMonths"
              tooltip="widgets.investmentRoundInfo.investmentLengthInMonths.tooltip"
            >
              <InvestmentLengthInMonths months={investmentLengthInMonths} userLocale={userLocale} />
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {investmentTermEnd && investmentTermEnd > Date.now() && !legacyEndedAt && !isExpired && (
          <>
            <InvestmentInfoRow
              title="widgets.investmentRoundInfo.investmentTermEnd"
              tooltip="widgets.investmentRoundInfo.investmentTermEnd.tooltip"
            >
              <RemainingTime date={investmentTermEnd} userLocale={userLocale} />
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        {loanToValue || loanToValue === 0 ? (
          <>
            <InvestmentInfoRow
              title="widgets.investmentRoundInfo.loanToValue"
              tooltip="widgets.investmentRoundInfo.loanToValue.tooltip"
            >
              {percentFormat({ input: numberWithDecimal(loanToValue, 3), locale: intl.locale })}
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        ) : null}

        {collateral && (
          <>
            <InvestmentInfoRow
              title="widgets.investmentRoundInfo.collateral"
              tooltip="widgets.investmentRoundInfo.collateral.tooltip"
            >
              {currencyFormat({ input: collateral, currency: investmentCurrency, locale: intl.locale })}
            </InvestmentInfoRow>
            <Spacer height="regular" />
          </>
        )}

        <InvestmentInfoRow
          title="property.investmentRoundInfo.purpose"
          tooltip="property.investmentRoundInfo.purpose.tooltip"
        >
          {purposes.length > 1 ? (
            <MultiplePurposes onClick={() => setIsMultiplePurposeVisible(!isMultiplePurposeVisible)}>
              {intl.formatMessage({ id: 'property.investmentRoundInfo.purpose.multiplePurposes' })}
              <Spacer width="small" />
              <Arrow width="15px" isMultiplePurposeVisible={isMultiplePurposeVisible} />
            </MultiplePurposes>
          ) : (
            <PropertyPurpose purpose={purposes[0]} />
          )}
        </InvestmentInfoRow>
        <AllPurposes variant="labelBASERegular" color="subtle" isMultiplePurposeVisible={isMultiplePurposeVisible}>
          <Spacer height="regular" />
          {sortedPurposes.map((purpose, index) => (
            <React.Fragment key={purpose}>
              {index > 0 && ', '}
              <PropertyPurpose purpose={purpose} />
            </React.Fragment>
          ))}
        </AllPurposes>

        <Spacer height="regular" />

        <InvestmentInfoRow
          title="widgets.investmentRoundInfo.countryIsoCode"
          tooltip="widgets.investmentRoundInfo.countryIsoCode.tooltip"
        >
          <Lang id={`global.country.${countryIsoCode}`} />
        </InvestmentInfoRow>

        <Spacer height="regular" />

        <InvestmentInfoRow title="widgets.investmentRoundInfo.city" tooltip="widgets.investmentRoundInfo.city.tooltip">
          {city}
        </InvestmentInfoRow>
      </InvestmentInfo>
    </>
  );
};

const AllPurposes = styled(Typography)<{ isMultiplePurposeVisible: boolean }>`
  display: ${({ isMultiplePurposeVisible }) => (isMultiplePurposeVisible ? 'flex' : 'none')};
  flex-direction: column;
`;

const MultiplePurposes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Arrow = styled(ChevronDown)<{ isMultiplePurposeVisible: boolean }>`
  transform: ${({ isMultiplePurposeVisible }) => (isMultiplePurposeVisible ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.2s;
`;

export default withUserDetailsAndLevels(InvestmentInfoWidget);
