import React, { FC } from 'react';
import styled from 'styled-components';

import { SurfaceColorName } from '@investown/fe/common-utils';

import { ProgressBarColorsName, SpacingSizeName } from 'styles/theme';

interface ProgressBarProps {
  percent: number;
  color?: ProgressBarColorsName;
  colorRemaining?: ProgressBarColorsName;
  gradientColors?: string[];
  colorTokenRemaining?: SurfaceColorName;
  height?: SpacingSizeName | string;
  isPrimary?: boolean;
  isAlwaysSuccess?: boolean;
  className?: string;
}

const ProgressBar: FC<ProgressBarProps> = ({
  percent,
  color = 'success',
  colorRemaining = 'background',
  gradientColors,
  colorTokenRemaining,
  height = 'small',
  isPrimary = false,
  isAlwaysSuccess = true,
  className,
}) => {
  const gradientBackground = gradientColors
    ? `background: linear-gradient(to right, ${gradientColors.join(', ')})`
    : undefined;

  return (
    <ViewContainer className={className}>
      <ViewProgress
        color={color}
        gradientBackground={gradientBackground}
        percent={percent}
        progress={percent}
        isPrimary={isPrimary}
        isAlwaysSuccess={isAlwaysSuccess}
        height={height}
      />
      <ViewProgress
        color={colorRemaining}
        colorToken={colorTokenRemaining}
        progress={100 - percent}
        percent={percent}
        height={height}
      />
    </ViewContainer>
  );
};
const ViewContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  overflow: hidden;
`;

const ViewProgress = styled.div<{
  progress: number;
  percent: number;
  color: ProgressBarColorsName;
  colorToken?: SurfaceColorName;
  gradientBackground?: string;
  height: SpacingSizeName | string;
  isPrimary?: boolean;
  isAlwaysSuccess?: boolean;
}>`
  ${({ theme, color, colorToken, gradientBackground, percent, isAlwaysSuccess, isPrimary }) => {
    switch (true) {
      case !!gradientBackground:
        return `${gradientBackground};
        border-radius: ${theme.borderRadius.extraLarge};
        `;

      case isPrimary:
      case !isAlwaysSuccess && percent === 100:
        return `background: linear-gradient(to right, ${theme.colorTokens.gradient.marketing.start}, ${theme.colorTokens.gradient.marketing.end});
        border-radius: ${theme.borderRadius.extraLarge};
        `;
      default:
        return `background-color: ${colorToken ? theme.colorTokens.surface[colorToken] : theme.progressBar[color]};`;
    }
  }}
  width: ${({ progress }) => progress}%;
  min-height: ${({ theme, height }) =>
    theme.spacing[height as SpacingSizeName] ? theme.spacing[height as SpacingSizeName] : `${height}px`};
`;

export default ProgressBar;
