import React, { FC } from 'react';
import styled from 'styled-components';

import { TextColorName } from '@investown/fe/common-utils';

import Lang from 'util/IntlMessages';
import Spacer from 'components/Spacer';
import Typography from 'components/V2/Typography/Typography';
import Button from 'components/Button';
import { TypographyName } from 'styles/theme';

export type ErrorEmptyStateProps = {
  content: {
    testID: string;
    image?: React.ReactElement;
    title?: string;
    titleFont?: TypographyName;
    titleColor?: TextColorName;
    description?: string;
    descriptionFont?: TypographyName;
    descriptionColor?: TextColorName;
    buttonText?: string;

    width?: number;
    buttonWidth?: number;
  };
  onClick?: () => void;
};

const ErrorEmptyState: FC<ErrorEmptyStateProps> = ({ content, onClick }) => {
  const {
    testID,
    image,
    title,
    description,
    buttonText,
    titleFont = 'displaySMMedium',
    titleColor = 'strong',
    descriptionFont = 'bodyBASERegular',
    descriptionColor = 'subtle',
    width = 420,
    buttonWidth,
  } = content;

  return (
    <Container data-testid={testID}>
      <Content className="errorTitle" width={width}>
        {image}
        {title && (
          <>
            <Spacer height="large" />
            <Typography variant={titleFont} color={titleColor}>
              <Lang id={title} />
            </Typography>
          </>
        )}
        {description && (
          <div className="errorDescription">
            <Spacer height="large" />
            <Typography variant={descriptionFont} color={descriptionColor}>
              <Lang id={description} />
            </Typography>
          </div>
        )}
        {buttonText && onClick && (
          <>
            <Spacer height="huge" />
            <StyledButton
              data-testid="error-empty-state-button"
              size="large"
              color="primary"
              onClick={onClick}
              width={buttonWidth}
            >
              <Lang id={buttonText} />
            </StyledButton>
          </>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colorTokens.surface.background};
  padding: ${({ theme }) => `${theme.spacing.huge} 0`};
`;

const Content = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: ${({ width }) => width}px;
  white-space: normal;
`;

const StyledButton = styled(Button)<{ width?: number }>`
  width: ${({ width }) => width}px;
`;

export default ErrorEmptyState;
