import React, { FC } from 'react';

import Icon, { IconProps } from '..';

import { ReactComponent as IconSVG } from 'assets/images/icons/alert-triangle.svg';

const AlertTriangle: FC<IconProps> = (props) => {
  const { color, fillColor, hoverColor, className, width } = props;

  return (
    <Icon color={color} fillColor={fillColor} hoverColor={hoverColor} className={className} width={width}>
      <IconSVG />
    </Icon>
  );
};

export default AlertTriangle;
