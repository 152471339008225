import React from 'react';
import { format, differenceInDays } from 'date-fns';
import { IntlShape, useIntl } from 'react-intl';

import { CoreSDK } from '@investown/fe/api-sdk';
import {
  PropertyEndedInvestmentRoundStatus,
  getEndedInvestmentRoundStatus,
  getPropertyInvestmentRoundStatusTranslationKey,
} from '@investown/fe/ui-utils/properties';
import { inflectTimeUnit } from '@investown/fe/common-utils';

import Lang from 'util/IntlMessages';
import StatusLabel, { StatusLabelColor } from 'components/V2/StatusLabel/StatusLabel';
import TrendingUp from 'components/V2/Icons/StatusLabel/TrendingUp';
import Minus from 'components/V2/Icons/StatusLabel/Minus';
import HouseCheck from 'components/V2/Icons/StatusLabel/HouseCheck';
import Warning from 'components/V2/Icons/StatusLabel/Warning';
import Danger from 'components/V2/Icons/StatusLabel/Danger';
import Tooltip from 'components/V2/Tooltip/Tooltip';
import Spacer from 'components/V2/Spacer/Spacer';

function RoundStatusWithTooltip({
  status,
  oldestUnpaidInstallmentDate,
  oldestUnpaidInstallmentDateWithGracePeriod,
  repaymentStatus,
  investmentTermEnd,
  legacyEndedAt,
  isThin,
}: {
  status: CoreSDK.PropertyInvestmentRoundStatus;
  oldestUnpaidInstallmentDate: string;
  oldestUnpaidInstallmentDateWithGracePeriod: string;
  repaymentStatus: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
  investmentTermEnd?: number | null;
  legacyEndedAt?: number | null;
  isThin?: boolean;
}) {
  const intl = useIntl();
  const now = new Date();
  const isInvestmentRoundEnded = !!investmentTermEnd && investmentTermEnd < now.getTime();
  const isLegacyEnded = !!legacyEndedAt && legacyEndedAt < now.getTime();
  const investmentRoundEndedStatus = getEndedInvestmentRoundStatus(status, isInvestmentRoundEnded, isLegacyEnded);
  const tagColorWithIcon = getTagColorWithIcon({ status, repaymentStatus, isLegacyEnded });
  let titleId = getPropertyInvestmentRoundStatusTranslationKey({ status, repaymentStatus, isInvestmentRoundEnded });

  if (investmentRoundEndedStatus) {
    titleId = `${titleId}.${investmentRoundEndedStatus}`;
  }
  const tooltipId = `${titleId}.tooltip`;

  if (tagColorWithIcon) {
    return (
      <>
        <Tooltip textComponent={<Lang id={tooltipId} defaultMessage={status} />}>
          <StatusLabel
            icon={tagColorWithIcon.icon}
            color={tagColorWithIcon.color}
            isThin={isThin}
            textLeft={<Lang id={titleId} defaultMessage={status} />}
            textRight={repaymentPartOfLabel({
              status,
              repaymentStatus,
              oldestUnpaidInstallmentDate,
              oldestUnpaidInstallmentDateWithGracePeriod,
              investmentRoundEndedStatus,
              intl,
            })}
          />
        </Tooltip>
        {!isThin && <Spacer height="large" />}
      </>
    );
  }
  return null;
}

function repaymentPartOfLabel({
  status,
  repaymentStatus,
  oldestUnpaidInstallmentDate,
  oldestUnpaidInstallmentDateWithGracePeriod,
  investmentRoundEndedStatus,
  intl,
}: {
  status: CoreSDK.PropertyInvestmentRoundStatus;
  repaymentStatus: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
  oldestUnpaidInstallmentDate?: string;
  oldestUnpaidInstallmentDateWithGracePeriod?: string;
  investmentRoundEndedStatus?: PropertyEndedInvestmentRoundStatus | undefined;
  intl: IntlShape;
}) {
  if (oldestUnpaidInstallmentDate && oldestUnpaidInstallmentDateWithGracePeriod) {
    const oldestUnpaidInstallment = new Date(oldestUnpaidInstallmentDate);
    const oldestUnpaidInstallmentWithGracePeriod = new Date(oldestUnpaidInstallmentDateWithGracePeriod);
    const daysOverdue = differenceInDays(new Date(), oldestUnpaidInstallment);
    const inflected = inflectTimeUnit({ number: daysOverdue, unit: 'day', splitToParts: true, locale: intl.locale });

    if (
      status === CoreSDK.PropertyInvestmentRoundStatus.Ended ||
      status === CoreSDK.PropertyInvestmentRoundStatus.NotFunded ||
      ((status === CoreSDK.PropertyInvestmentRoundStatus.Unknown ||
        status === CoreSDK.PropertyInvestmentRoundStatus.Funded) &&
        investmentRoundEndedStatus === PropertyEndedInvestmentRoundStatus.PaymentOfPrincipal)
    ) {
      return null;
    }

    switch (repaymentStatus) {
      case CoreSDK.PropertyInvestmentRoundRepaymentStatus.Collection:
      case CoreSDK.PropertyInvestmentRoundRepaymentStatus.Delayed:
        return (
          <Lang id="property.currentInvestmentRound.daysOverdue" values={{ days: daysOverdue, unit: inflected.unit }} />
        );
      default:
        return (
          <Lang
            id="property.currentInvestmentRound.nextPayout"
            values={{ date: format(oldestUnpaidInstallmentWithGracePeriod, 'd.M.yyyy') }}
          />
        );
    }
  }
  return null;
}

function getTagColorWithIcon({
  status,
  repaymentStatus,
  isLegacyEnded,
}: {
  status: CoreSDK.PropertyInvestmentRoundStatus;
  repaymentStatus: CoreSDK.PropertyInvestmentRoundRepaymentStatus;
  isLegacyEnded?: boolean;
}): { icon: React.ReactNode; color: StatusLabelColor } | undefined {
  switch (status) {
    case CoreSDK.PropertyInvestmentRoundStatus.FullyInvested:
      return { icon: <TrendingUp fillColor="transparent" hoverColor="primary" />, color: 'primary' };
    case CoreSDK.PropertyInvestmentRoundStatus.NotFunded:
      return { icon: <Minus fillColor="transparent" hoverColor="warning" />, color: 'warning' };
    case CoreSDK.PropertyInvestmentRoundStatus.Ended:
      return { icon: <HouseCheck fillColor="transparent" hoverColor="default" />, color: 'default' };
    case CoreSDK.PropertyInvestmentRoundStatus.Funded:
    case CoreSDK.PropertyInvestmentRoundStatus.Unknown:
      if (isLegacyEnded) {
        return { icon: <HouseCheck fillColor="transparent" hoverColor="default" />, color: 'default' };
      }
      switch (repaymentStatus) {
        case CoreSDK.PropertyInvestmentRoundRepaymentStatus.Delayed:
          return { icon: <Warning fillColor="transparent" hoverColor="warning" />, color: 'warning' };
        case CoreSDK.PropertyInvestmentRoundRepaymentStatus.Collection:
          return { icon: <Danger fillColor="transparent" hoverColor="error" />, color: 'error' };
        default:
          return { icon: <TrendingUp fillColor="transparent" hoverColor="success" />, color: 'success' };
      }
  }
}

export default RoundStatusWithTooltip;
