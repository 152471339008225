import React, { FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { TextColorName, percentFormat } from '@investown/fe/common-utils';

import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';
import Spacer from 'components/V2/Spacer/Spacer';
import { TypographyName } from 'styles/theme';

interface PerAnnumProps {
  pa: number;
  valueFont?: TypographyName;
  valueColor?: TextColorName;
  suffixFont?: TypographyName;
  suffixColor?: TextColorName;
}

const PerAnnum: FC<PerAnnumProps> = ({
  pa,
  valueFont = 'labelLGSemiBold',
  valueColor = 'strong',
  suffixFont = 'labelXSRegular',
  suffixColor = 'subtle',
}) => {
  const intl = useIntl();
  return (
    <Container>
      <Typography variant={valueFont} color={valueColor}>
        {percentFormat({
          input: pa,
          locale: intl.locale,
        })}
      </Typography>
      <Spacer width="small" />
      <Typography variant={suffixFont} color={suffixColor}>
        <Lang id="global.perAnnum" />
      </Typography>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

export default PerAnnum;
