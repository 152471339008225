import React, { FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { CoreSDK } from '@investown/fe/api-sdk';
import { currencyFormat } from '@investown/fe/common-utils';

import Button from 'components/Button';
import Lang from 'util/IntlMessages';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';

interface Props {
  secondaryMarketOffersInfo: CoreSDK.InvestmentRoundSecondaryMarketInfo;
  onShowSecondaryOffersClick: () => void;
}

const SecondaryMarketOffersSummary: FC<Props> = ({ secondaryMarketOffersInfo, onShowSecondaryOffersClick }) => {
  const intl = useIntl();

  return (
    <>
      <StyledButton color="primary" block onClick={onShowSecondaryOffersClick} testID="showSecondaryMarketOffers">
        <Lang id="widgets.investmentRoundInfo.secondaryMarketOffers.showButton" />
      </StyledButton>
      <Spacer height="regular" />
      <SecondaryMarketSummary>
        <SecondaryMarketSummaryItem>
          <Typography variant="labelBASESemiBold" color="strong">
            {secondaryMarketOffersInfo.offersCount}
          </Typography>
          <Spacer width="small" />
          <Typography variant="labelXSRegular" color="subtle">
            <Lang id="widgets.investmentRoundInfo.secondaryMarketOffers.offers" />
          </Typography>
        </SecondaryMarketSummaryItem>
        <Spacer width="large" />
        <SecondaryMarketSummaryItem>
          <Typography variant="labelBASESemiBold" color="strong">
            {currencyFormat({
              input: secondaryMarketOffersInfo.remainingAmountsSum.amount,
              currency: secondaryMarketOffersInfo.remainingAmountsSum.currency,
              locale: intl.locale,
            })}
          </Typography>
          <Spacer width="small" />
          <Typography variant="labelXSRegular" color="subtle">
            <Lang id="widgets.investmentRoundInfo.secondaryMarketOffers.available" />
          </Typography>
        </SecondaryMarketSummaryItem>
      </SecondaryMarketSummary>
    </>
  );
};

const StyledButton = styled(Button)`
  margin: 0;
  margin-bottom: ${(props) => props.theme.margins.medium};
`;

const SecondaryMarketSummary = styled.div`
  display: flex;
  justify-content: center;
`;

const SecondaryMarketSummaryItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

export default SecondaryMarketOffersSummary;
