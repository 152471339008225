import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import TabButton from 'components/V2/TabButton/TabButton';
import Spacer from 'components/V2/Spacer/Spacer';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';

interface TabContent {
  id?: string;
  title: string;
  component: React.ReactNode;
  url: string;
}
interface Props {
  tabs: TabContent[];
}

const Tabs: React.FC<Props> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const history = useHistory();
  const { location } = history;

  useEffect(() => {
    const tabNameFromURL = tabs.find((tab) => tab.url === location.hash.replace(/#/g, ''));
    if (tabNameFromURL) {
      setActiveTab(tabs.indexOf(tabNameFromURL));
    }
    // Ignore dependencies tabs to prevent switching tabs on re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash, tabs.length]);

  const handleTabClick = (tabIndex: number, url: string) => {
    setActiveTab(tabIndex);
    history.replace({ hash: `#${url}` });
  };

  return (
    <FlexColumn>
      <FlexRow alignHorizontal={AlignDirections.FlexStart}>
        <TabContainer>
          {tabs.map((tab, index) => (
            <React.Fragment key={tab.title}>
              <TabButton
                id={tabs[index].id}
                testID={`tab-${index + 1}`}
                label={tab.title}
                onClick={() => handleTabClick(index, tab.url)}
                isActive={activeTab === index}
              />
              {index < tabs.length - 1 && <Spacer width="small" />}
            </React.Fragment>
          ))}
        </TabContainer>
      </FlexRow>
      <Spacer height="huge" />
      <FlexRow alignHorizontal={AlignDirections.FlexStart}>
        <TabContentWrapper>{activeTab !== null && tabs[activeTab].component}</TabContentWrapper>
      </FlexRow>
    </FlexColumn>
  );
};

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const TabContentWrapper = styled.div`
  width: 100%;
`;

export default Tabs;
