import React, { FC } from 'react';
import styled from 'styled-components';

import { CoreSDK } from '@investown/fe/api-sdk';

import InvestmentDetailRoundInfo from './InvestmentDetailRoundInfo';

interface InvestmentDetailProps {
  investmentRound: CoreSDK.PropertyQuery['property']['investmentRound'];
  propertyImage?: string;
  propertyName: string;
}

const InvestmentDetail: FC<InvestmentDetailProps> = ({ investmentRound, propertyImage, propertyName }) => (
  <Container>
    {propertyImage && <PropertyImage src={propertyImage} />}
    <ContainerBody>
      <InvestmentDetailRoundInfo investmentRound={investmentRound} propertyName={propertyName} />
    </ContainerBody>
  </Container>
);

const Container = styled.div`
  max-width: 358px;
  width: 100%;
  margin: auto;
`;

const ContainerBody = styled.div`
  width: 100%;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.small};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.small};
  border-top: none;
  border: ${({ theme }) => theme.colorTokens.stroke.medium} 1px solid;
`;

const PropertyImage = styled.img`
  width: 100%;
  margin: auto;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.small};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.small};
  height: 200px;
  object-fit: cover;
  object-position: center;
`;

export default InvestmentDetail;
