import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { TestIds } from '@investown/fe/test-utils/testIds';

import Lang from 'util/IntlMessages';
import Button from 'components/Button';
import Spacer from 'components/V2/Spacer/Spacer';
import { PATH_USER_MEMBERSHIP_LEVELS } from 'routes/routesPaths';

const VipGateButton: FC = () => {
  const history = useHistory();
  const onInvestmentButtonClick = useCallback(() => {
    history.push(PATH_USER_MEMBERSHIP_LEVELS);
  }, [history]);

  return (
    <>
      <Spacer height="huge" />
      <Button data-testid={TestIds.VipGateButton} color="primary" block onClick={onInvestmentButtonClick}>
        <Lang id="global.moreAboutMembership" />
      </Button>
      <Spacer height="regular" />
      <VipGateDescription>
        <Lang id="widgets.propertyInvestment.vipGateDescription" />
      </VipGateDescription>
    </>
  );
};

const VipGateDescription = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colorTokens.text.medium};
  margin-bottom: 0;
`;

export default VipGateButton;
