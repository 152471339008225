import React, { FC } from 'react';
import styled from 'styled-components';

import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';

export interface TabButtonProps {
  id?: string;
  testID?: string;
  label: string;
  count?: number;
  isActive: boolean;
  onClick: () => void;
}

const TabButton: FC<TabButtonProps> = ({ id, testID, label, count, isActive, onClick }) => (
  <Container id={id} isActive={isActive} data-testid={testID} onClick={onClick}>
    <Typography variant="labelSMMedium" color={isActive ? 'invertedStrong' : 'strong'}>
      {label}
    </Typography>
    {count !== undefined && (
      <>
        <Spacer width="12" />
        <Typography variant="labelSMMedium" color={isActive ? 'invertedSubtle' : 'subtle'}>
          {count}
        </Typography>
      </>
    )}
  </Container>
);

const Container = styled.div<{ isActive: boolean; testID?: string }>`
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colorTokens.surface.inverted800 : theme.colorTokens.surface.subtle};
  span:first-child {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colorTokens.text.invertedStrong : theme.colorTokens.text.strong};
  }
  ${({ theme, isActive }) =>
    !isActive &&
    `
  &:hover,
  &:focus {
    span:first-child {
      color: ${theme.colorTokens.text.strong};
    }
    background-color: ${theme.colorTokens.surface.medium};
  }
    `}
  padding: ${({ theme }) => `${theme.spacing.regular} ${theme.spacing.extraLarge}`};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

export default TabButton;
