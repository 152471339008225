/* eslint-disable @nx/enforce-module-boundaries */
import * as CoreSDK from '../../../../api-sdk/src/lib/core/__generated__/sdk';
import { UserDetailsDTO } from '../../../../api-sdk/src/lib/users/model';

export type RegistrationStepEventPayload = {
  step: string | VerificationStepsNumbers;
  phone?: string;
  email?: string;
  user_id?: string;
  user_type: 'business' | 'individual';
};

export enum VerificationStepsNumbers {
  MoneyOrigin = 1,
  Pep = 2,
  Veriff = 3,
  QuestionnairePartOneStart = 4,
  QuestionnairePartOneComplete = 5,
  QuestionnairePartTwoStart = 6,
  QuestionnairePartTwoComplete = 7,
}

export enum ProjectDetailTabs {
  About = 'about',
  Partner = 'partner',
  Activity = 'activity',
  RepaymentSchedule = 'repayment_schedule',
  FAQ = 'faq',
}

export type ProjectDetailTabOpenedEventPayload = {
  path: string;
  tab: ProjectDetailTabs;
};

export type LoginEventPayload = UserDetailsDTO;

export enum ErrorTypes {
  SIGN_UP_ERROR = 'sign_up_error',
  LOGIN_FAILED = 'login_failed',
  UNKNOWN = 'unknown',
}

export type ErrorPayload = {
  type: ErrorTypes;
  code?: string;
  message: string;
};

export type InvestOverviewPayload = {
  value?: number;
  currency?: string;
  project: CoreSDK.PropertyQuery['property'] & { slug: string };
};

export type InvestEventPayload = {
  transactionId: string;
  amount: number;
  currency: string;
  project: CoreSDK.PropertyQuery['property'] & ({ slug: string } | undefined);
};

export type ViewItemEventPayload = InvestOverviewPayload;

export type InviteFriendsEventPayload = {
  last_purchase: string;
  average_purchase: string;
  purchases: string;
  userDetails?: UserDetailsDTO;
  resolution: string;
  language: string;
  os?: string;
};

export type ScreenAppViewEventPayload = {
  id: string;
  screen_app_view: string;
  device?: string;
  os?: string;
};
