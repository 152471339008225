import React from 'react';

import { UserDetailsDTO, UserVerificationDataDTO, UserVerificationStatus } from '@investown/fe/api-sdk';

import InvestButton from 'containers/Property/Sidebar/InvestButtonWidget/InvestButton';

interface Props {
  userDetails?: UserDetailsDTO;
  userVerificationData?: UserVerificationDataDTO;
  isPrimaryInvestmentAvailable: boolean;
  minInvestment: number;
  investmentCurrency: string;
  investmentAmountAvailable: number;
  onInvestmentButtonClick: () => void;
  investmentDisabled: boolean;
  shouldHide?: boolean;
}

const InvestButtonWidget: React.FC<Props> = (props) => {
  const {
    userDetails,
    userVerificationData,
    isPrimaryInvestmentAvailable,
    minInvestment,
    investmentCurrency,
    investmentAmountAvailable,
    onInvestmentButtonClick,
    investmentDisabled,
    shouldHide,
  } = props;

  // User not verified and has no suitability status
  if (
    (userDetails &&
      userDetails.verificationStatus !== UserVerificationStatus.Verified &&
      userVerificationData &&
      userVerificationData.suitability === null) ||
    shouldHide
  ) {
    return null;
  }

  return (
    <>
      <InvestButton
        isPrimaryInvestmentAvailable={isPrimaryInvestmentAvailable}
        minInvestment={minInvestment}
        investmentCurrency={investmentCurrency}
        investmentAmountAvailable={investmentAmountAvailable}
        onInvestmentButtonClick={onInvestmentButtonClick}
        investmentDisabled={investmentDisabled}
      />
    </>
  );
};

export default InvestButtonWidget;
