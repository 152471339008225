import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  CoreSDK,
  getProperty,
  getWallet,
  investmentThresholds,
  QueryKeys,
  refetchIntervals,
  useUserTimestamps,
  useUserVerificationData,
} from '@investown/fe/api-sdk';
import { getTotalBonusYield } from '@investown/fe/common-utils';

import InvestmentForm from './InvestmentOrderForm';
import InvestmentDetail from './InvestmentDetail';

import Modal, { Props as ModalProps } from 'components/V2/Modal/Modal';
import Lang from 'util/IntlMessages';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import withUserDetailsAndLevels, { WithUserDetailsAndLevelsProps } from 'util/withUserDetailsAndLevels';
import Spacer from 'components/V2/Spacer/Spacer';
import Typography from 'components/V2/Typography/Typography';
import ErrorEmptyState from 'components/V2/ErrorEmptyState/ErrorEmptyState';
import errorEmptyStates from 'constants/ErrorEmptyStates';
import Loading from 'components/V2/Loading/Loading';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';

type ModalPropsPicked = Pick<ModalProps, 'onClose' | 'visible'>;

interface InvestmentModalProps extends ModalPropsPicked {
  propertyName: string;
}

const InvestmentModal: FC<InvestmentModalProps & WithUserDetailsAndLevelsProps> = ({
  onClose,
  visible,
  bonusYieldForUser,
  propertyName,
}) => {
  const { slug } = useParams<{ slug: string }>();
  const queryResult = useQuery<CoreSDK.PropertyQuery, Error, CoreSDK.PropertyQuery, [string, { slug: string }]>(
    [QueryKeys.Property, { slug }],
    ({ queryKey: [, variables] }) => getProperty(variables.slug),
    {
      refetchInterval: refetchIntervals.thirtySeconds,
    }
  );

  const {
    data: walletData,
    isLoading: walletIsLoading,
    isError: walletIsError,
    isIdle: walletIsIdle,
  } = useQuery(QueryKeys.Wallet, getWallet);
  const {
    data: investmentThresholdsData,
    isLoading: investmentThresholdsIsLoading,
    isError: investmentThresholdsIsError,
    isIdle: investmentThresholdsIsIdle,
  } = useQuery(QueryKeys.InvestmentThresholds, investmentThresholds, {
    refetchOnMount: 'always',
  });
  const { data: verificationData } = useUserVerificationData(false, 'always');
  const {
    data: userTimestampsData,
    isLoading: userTimestampsIsLoading,
    isError: userTimestampsIsError,
    isIdle: userTimestampsIsIdle,
  } = useUserTimestamps({
    enabled: verificationData?.suitability !== 'suitable',
    refetchOnMount: 'always',
  });

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  if (
    queryResult.isIdle ||
    queryResult.isLoading ||
    userTimestampsIsLoading ||
    userTimestampsIsIdle ||
    walletIsLoading ||
    walletIsIdle ||
    investmentThresholdsIsLoading ||
    investmentThresholdsIsIdle
  ) {
    return <Loading />;
  }

  if (queryResult.isError || walletIsError || investmentThresholdsIsError || userTimestampsIsError) {
    return <ErrorEmptyState content={errorEmptyStates.errorFetchData} />;
  }

  const totalBonusYield = queryResult.data.property.investmentRound.bonusYieldEligible
    ? getTotalBonusYield({
        annualPercentageYield: queryResult.data.property.investmentRound.annualPercentageYield,
        bonusYield: bonusYieldForUser,
      })
    : undefined;

  return (
    <Modal
      maxWidth={940}
      header={
        <FullHeightRow alignVertical={AlignDirections.Center}>
          <Typography variant="displayXSMedium" color="strong">
            <Lang id="widgets.propertyInvestment.investButton" />
          </Typography>
        </FullHeightRow>
      }
      visible={visible && !!queryResult.data.property.investmentRound}
      onClose={handleCloseModal}
    >
      <FlexRow alignHorizontal={AlignDirections.Center} alignVertical={AlignDirections.FlexStart}>
        <FlexColumn>
          <InvestmentForm
            investmentRoundCurrency={queryResult.data.property.investmentRound.investmentCurrency}
            investmentRoundId={queryResult.data.property.investmentRound.id}
            limitPerUser={queryResult.data.property.investmentRound.limitPerUser}
            currentUsersTotalInvestment={queryResult.data.property.investmentRound.currentUsersTotalInvestment}
            minInvestment={queryResult.data.property.investmentRound.minInvestment}
            maxInvestment={Math.min(
              queryResult.data.property.investmentRound.investmentAmountAvailable,
              queryResult.data.property.investmentRound.limitPerUser
            )}
            investmentLengthInMonths={queryResult.data.property.investmentRound.investmentLengthInMonths}
            totalBonusYield={totalBonusYield}
            annualPercentageYield={queryResult.data.property.investmentRound.annualPercentageYield}
            endPercentageBonus={queryResult.data.property.investmentRound.endPercentageBonus}
            onModalClose={handleCloseModal}
            wallet={walletData}
            investmentThresholds={investmentThresholdsData}
            userTimestamps={userTimestampsData}
          />
        </FlexColumn>
        <Spacer width="40" />
        <FlexColumn>
          <InvestmentDetail
            propertyImage={queryResult.data.property.photos.find((img) => img.isPrimary)?.url}
            investmentRound={queryResult.data.property.investmentRound}
            propertyName={propertyName}
          />
        </FlexColumn>
      </FlexRow>
    </Modal>
  );
};

const FullHeightRow = styled(FlexRow)`
  height: 100%;
`;

export default withUserDetailsAndLevels(InvestmentModal);
