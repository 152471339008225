import { END, EventChannel, eventChannel, SagaIterator } from 'redux-saga';
import { all, call, delay, fork, take, takeEvery } from 'redux-saga/effects';
import { ActionType, isActionOf } from 'typesafe-actions';
import * as Sentry from '@sentry/react';

import { CoreSDK, getWallet, queryClient, QueryKeys } from '@investown/fe/api-sdk';

import { investedAction, investmentErrorAction, investmentWithdrawnAction } from './actions';

import { captureSentryError } from 'util/sentry';

const WALLET_UPDATE_INTERVAL = 4000;
const WALLET_UPDATE_CHECK_COUNT_LIMIT = 25; // 25 * 4s = 100s

function* resetCaches(
  action: ActionType<typeof investedAction | typeof investmentWithdrawnAction | typeof investmentErrorAction>
): SagaIterator {
  // Refetch all queries after investment with a delay to make sure all data is updated on BE
  yield delay(4000);

  yield all([
    call([queryClient, queryClient.invalidateQueries], QueryKeys.Property),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.InvestedProperties),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.PropertyInvestments),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.SecondaryMarketItems),
    call([queryClient, queryClient.invalidateQueries], QueryKeys.PortfolioStatistics),
  ]);

  // wallet query will be invalidated in the {walletUpdateCheckSaga}
  if (!isActionOf(investedAction, action)) {
    yield call([queryClient, queryClient.invalidateQueries], QueryKeys.Wallet);
  }

  yield delay(4000);

  // Setting new user level can take a while after refreshing PortfolioStatistics so delay a bit
  yield call([queryClient, queryClient.invalidateQueries], QueryKeys.UserDetails);
  yield call([queryClient, queryClient.invalidateQueries], QueryKeys.UserInvestmentStats);

  // wallet query will be invalidated in the {walletUpdateCheckSaga}
  if (!isActionOf(investedAction, action)) {
    yield delay(25000);

    // Refetch wallet if invalidateQuery above was too early to get updated balance in 30s after investment at the same time as currentUsersTotalInvestment in project detail
    // 1s delay after investment, 4s delay for UserInvestmentStats, 25s delay for Wallet. currentUsersTotalInvestment
    yield call([queryClient, queryClient.refetchQueries], QueryKeys.Wallet);
  }
}

function* optimisticWalletUpdate(action: ActionType<typeof investedAction>): any {
  yield call(optimisticWalletQueryUpdate, (walletAmount: number) => {
    try {
      const updatedWalletAmount = walletAmount - action.payload.amount;
      return updatedWalletAmount >= 0 ? updatedWalletAmount : walletAmount;
    } catch (error) {
      captureSentryError('💥 Error optimistically updating wallet amount', error);
      return walletAmount;
    }
  });
}

async function optimisticWalletQueryUpdate(updaterFunction: (walletAmount: number) => number): Promise<void> {
  await queryClient.cancelQueries(QueryKeys.Wallet);
  queryClient.setQueryData<CoreSDK.WalletQuery | undefined>(QueryKeys.Wallet, (oldData) => {
    if (!oldData) {
      return oldData;
    }
    return { Wallet: { ...oldData.Wallet, availableBalance: updaterFunction(oldData.Wallet.availableBalance) } };
  });
}

function getOptimisticWalletUpdateIntervalSaga(): (action: ActionType<typeof investedAction>) => SagaIterator {
  let walletUpdateCheckChannel: EventChannel<number>;

  return function* walletUpdateCheckSaga(action: ActionType<typeof investedAction>): SagaIterator {
    if (walletUpdateCheckChannel != null) {
      walletUpdateCheckChannel.close();
    }
    // we need to prefetch the wallet query to make sure the {QueryKeys.lastWalletUpdate} query has status != idle
    // The status is used in the {isOptimisticWalletRefetchInProgress} function which is used to
    // determine the refetch interval in the wallet query (in WalletWithProfit.tsx)
    queryClient.prefetchQuery(QueryKeys.lastWalletUpdate, getWallet, { staleTime: 0 });
    yield* optimisticWalletUpdate(action);
    walletUpdateCheckChannel = yield call(walletDataCheckInterval, action.payload.investedAt);
    try {
      while (true) {
        // take(END) will cause the saga to terminate by jumping to the finally block
        yield take(walletUpdateCheckChannel);
      }
    } finally {
      Sentry.addBreadcrumb({ message: 'Wallet update check finished. Invalidating wallet query' });
      queryClient.invalidateQueries(QueryKeys.Wallet);
      walletUpdateCheckChannel.close();
    }
  };
}

// https://github.com/redux-saga/redux-saga/blob/main/docs/advanced/Channels.md#using-the-eventchannel-factory-to-connect-to-external-events
function walletDataCheckInterval(lastInvestmentAt: number): EventChannel<number> {
  return eventChannel((emitter) => {
    let requestCount = 0;

    const iv = setInterval(async () => {
      await queryClient.cancelQueries(QueryKeys.lastWalletUpdate);
      Sentry.addBreadcrumb({ message: `Checking wallet update (${requestCount})` });
      const lastWalletUpdate = await queryClient.fetchQuery(QueryKeys.lastWalletUpdate, getWallet, { staleTime: 0 });

      if (requestCount === WALLET_UPDATE_CHECK_COUNT_LIMIT) {
        captureSentryError(
          '💥 Wallet update check count limit reached',
          new Error(
            `Wallet in BE not updated within ${WALLET_UPDATE_CHECK_COUNT_LIMIT * WALLET_UPDATE_INTERVAL} seconds`
          )
        );
        emitter(END);
      }

      if (isWalletUpdatedAfterInvestment(lastWalletUpdate.Wallet.updatedAt, lastInvestmentAt)) {
        Sentry.addBreadcrumb({
          message: `Wallet updated:\nlastInvestmentAt: ${lastInvestmentAt},\n walletUpdatedAt: ${lastWalletUpdate.Wallet.updatedAt}`,
        });
        // this causes the channel to close
        emitter(END);
      } else {
        emitter(lastWalletUpdate.Wallet.updatedAt);
        requestCount += 1;
        Sentry.addBreadcrumb({
          message: `Wallet not yet updated:\nlastInvestmentAt: ${lastInvestmentAt},\n walletUpdatedAt: ${lastWalletUpdate.Wallet.updatedAt}`,
        });
      }
    }, WALLET_UPDATE_INTERVAL);

    // The subscriber must return an unsubscribe function
    return () => {
      Sentry.addBreadcrumb({ message: 'Wallet update check interval cleared' });
      clearInterval(iv);
      queryClient.cancelQueries(QueryKeys.lastWalletUpdate);
      queryClient.resetQueries(QueryKeys.lastWalletUpdate);
    };
  });
}

function isWalletUpdatedAfterInvestment(walletUpdatedAt: number, investedAt: number): boolean {
  if (walletUpdatedAt == null || investedAt == null) {
    return false;
  }

  return walletUpdatedAt >= investedAt;
}

export function isOptimisticWalletRefetchInProgress(): boolean {
  // After optimistic wallet update, we periodically refetch the wallet data to check if the
  // update has been applied. Once the refetch is done, the status of the query will be 'idle'
  return (
    queryClient.getQueryState(QueryKeys.lastWalletUpdate) !== undefined &&
    queryClient.getQueryState(QueryKeys.lastWalletUpdate)?.status !== 'idle'
  );
}

export function* watchInvestedAction(): SagaIterator {
  yield takeEvery(investedAction, resetCaches);
  yield takeEvery(investedAction, getOptimisticWalletUpdateIntervalSaga());
}

export function* watchInvestmentErrorAction(): SagaIterator {
  yield takeEvery(investmentErrorAction, resetCaches);
}

export function* watchInvestmentWithdrawnAction(): SagaIterator {
  yield takeEvery(investmentWithdrawnAction, resetCaches);
}

export default function* root(): SagaIterator {
  yield all([fork(watchInvestedAction), fork(watchInvestmentErrorAction), fork(watchInvestmentWithdrawnAction)]);
}
