import React, { FC } from 'react';
import styled from 'styled-components';

import { TestIds } from '@investown/fe/test-utils/testIds';

import Modal, { Props as ModalProps } from 'components/V2/Modal/Modal';
import Spacer from 'components/V2/Spacer/Spacer';
import Lang from 'util/IntlMessages';
import Button from 'components/V2/Button';
import Typography from 'components/V2/Typography/Typography';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';

type ModalPropsPicked = Pick<ModalProps, 'visible'>;

interface WithdrawalSuccessModalProps extends ModalPropsPicked {
  onCloseModal: () => void;
}

const WithdrawalErrorModal: FC<WithdrawalSuccessModalProps> = ({ visible, onCloseModal }) => (
  <Modal
    maxWidth={700}
    header={
      <Typography variant="displayXSMedium" color="strong">
        <Lang id="property.withdrawFromInvestment.error.heading" />
      </Typography>
    }
    visible={visible}
    onClose={onCloseModal}
  >
    <>
      <Typography variant="bodyBASERegular" color="strong">
        <Lang id="property.withdrawFromInvestment.error.description" />
      </Typography>
      <Spacer height="immense" />
      <FullWidthRow alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexEnd}>
        <Button
          data-testid={TestIds.UnderstandUnsuccessfulWithdrawalFromInvestmentButton}
          variant="primary"
          onClick={onCloseModal}
        >
          <Lang id="property.withdrawFromInvestment.error.button" />
        </Button>
      </FullWidthRow>
    </>
  </Modal>
);

const FullWidthRow = styled(FlexRow)`
  width: 100%;
`;

export default WithdrawalErrorModal;
