import React, { FC } from 'react';
import styled from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';

import { ColorsName, FontWeightName, FontSizeName } from 'styles/theme';

interface Props extends WithChildren {
  className?: string;
  color?: ColorsName;
  fontWeight?: FontWeightName;
  fontSize?: FontSizeName;
  align?: 'left' | 'center' | 'right';
}

const Paragraph: FC<Props> = ({
  children,
  className,
  color = 'textColor',
  fontWeight = 'medium',
  fontSize = 'medium',
  align = 'left',
}) => (
  <StyledParagraph className={className} $color={color} $fontWeight={fontWeight} $fontSize={fontSize} $align={align}>
    {children}
  </StyledParagraph>
);

const StyledParagraph = styled.div<{
  $color: ColorsName;
  $fontWeight: FontWeightName;
  $fontSize: FontSizeName;
  $align: 'left' | 'center' | 'right';
}>`
  display: block;
  font-size: ${({ theme, $fontSize }) => theme.font.size[$fontSize]};
  font-weight: ${({ theme, $fontWeight }) => theme.font.weight[$fontWeight]};
  color: ${({ theme, $color }) => theme.color[$color]};
  text-align: ${({ $align }) => $align};
  white-space: pre-line;
  p {
    margin-bottom: 0;
  }
`;

export default Paragraph;
