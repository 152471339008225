/* eslint-disable @typescript-eslint/naming-convention */
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { QueryKeys } from '../enums';
import { getPropertyInvestmentRoundActivities } from '../core/getPropertyInvestmentRoundActivities';
import * as CoreSDK from '../core/__generated__/sdk';

type ActivitiesQueryKey = [
  string,
  {
    investmentRoundId: string;
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: 'ASC' | 'DESC';
    filter: CoreSDK.PropertyInvestmentRoundActivityFilterType | undefined;
  }
];

type QueryOptions = Parameters<
  typeof useInfiniteQuery<
    CoreSDK.PropertyInvestmentRoundActivitiesQuery,
    Error,
    CoreSDK.PropertyInvestmentRoundActivitiesQuery,
    ActivitiesQueryKey
  >
>[2];

type RequestParams = {
  page?: number;
  perPage?: number;
  sortField?: string;
  sortOrder?: 'ASC' | 'DESC';
  filter?: CoreSDK.PropertyInvestmentRoundActivityFilterType;
};

export function useInfiniteInvestmentRoundActivities(
  investmentRoundId: string,
  {
    page = 0,
    perPage = 10,
    sortField = 'occurredAt',
    sortOrder = 'DESC',
    filter,
  }: Parameters<typeof getPropertyInvestmentRoundActivities>[1],
  queryOptions?: QueryOptions & { onBeforeRequest?: (params: RequestParams) => void }
): UseInfiniteQueryResult<CoreSDK.PropertyInvestmentRoundActivitiesQuery, Error> {
  return useInfiniteQuery<
    CoreSDK.PropertyInvestmentRoundActivitiesQuery,
    Error,
    CoreSDK.PropertyInvestmentRoundActivitiesQuery,
    ActivitiesQueryKey
  >(
    [QueryKeys.InvestmentRoundActivities, { investmentRoundId, page, perPage, sortField, sortOrder, filter }],
    ({ pageParam = page }) => {
      const queryParams = {
        page: pageParam,
        perPage,
        sortField,
        sortOrder,
        filter,
      };
      if (queryOptions?.onBeforeRequest && typeof queryOptions.onBeforeRequest === 'function') {
        queryOptions.onBeforeRequest(queryParams);
      }

      return getPropertyInvestmentRoundActivities(investmentRoundId, queryParams);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.propertyInvestmentRoundActivities.length < perPage ? undefined : pages.length;
      },
      ...queryOptions,
    }
  );
}
