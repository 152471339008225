/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { TestIds } from '@investown/fe/test-utils/testIds';
import { currencyFormat } from '@investown/fe/common-utils';

import Cross from '../Icons/Cross';

import House from 'components/V2/Icons/House';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import { theme } from 'styles/theme';
import Lang from 'util/IntlMessages';

type MarketplaceOfferItem = {
  amount: number;
  currency: string;
};

interface Props {
  offers: MarketplaceOfferItem;
  onCancelAllSecondaryMarketOffersClick: () => void;
}

const MySecondaryOffers: FC<Props> = ({ offers, onCancelAllSecondaryMarketOffersClick }) => {
  const intl = useIntl();

  return (
    <Container>
      <FlexRow alignHorizontal={AlignDirections.FlexStart} alignVertical={AlignDirections.Center}>
        <House color={theme.colorTokens.text.strong} fillColor="transparent" width="16px" />
        <Spacer width="medium" />
        <Typography variant="labelBASEMedium" color="strong">
          <Lang id="secondaryMarketOffersWidget.title" />
        </Typography>
      </FlexRow>
      <Spacer height="large" />
      <FlexRow alignHorizontal={AlignDirections.SpaceBetween} alignVertical={AlignDirections.Center}>
        <Typography variant="labelBASEMedium" color="strong">
          <Lang id="secondaryMarketOffersWidget.share" />
        </Typography>
        <FlexRow alignHorizontal={AlignDirections.FlexEnd} alignVertical={AlignDirections.Center}>
          <Typography variant="labelBASEMedium" color="strong">
            {currencyFormat({ input: offers.amount, currency: offers.currency, locale: intl.locale })}
          </Typography>
          <Spacer width="medium" />
          <CancelButton
            onClick={onCancelAllSecondaryMarketOffersClick}
            data-testid={TestIds.CancelAllSecondaryMarketOffers}
          >
            <Cross color={theme.colorTokens.icon.subtle} hoverColor={theme.colorTokens.icon.subtle} />
          </CancelButton>
        </FlexRow>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.surface.card};
  border: 1px solid ${({ theme }) => theme.colorTokens.surface.medium};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacing.large};
`;

const CancelButton = styled.div`
  cursor: pointer;
  z-index: 2;
  justify-content: center;
  display: flex;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default MySecondaryOffers;
