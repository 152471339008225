import { useQuery, UseQueryResult } from 'react-query';

import { getTotalUserLevelBonusYield, QueryKeys, CoreSDK } from '@investown/fe/api-sdk';

export function useTotalUserLevelBonusYield(
  propertyInvestmentRoundId: string
): UseQueryResult<CoreSDK.TotalUserLevelBonusYieldQuery, Error> {
  const totalUserLevelBonusYield = useQuery<CoreSDK.TotalUserLevelBonusYieldQuery, Error>(
    [QueryKeys.TotalUserLevelBonusYield, propertyInvestmentRoundId],
    () => getTotalUserLevelBonusYield({ propertyInvestmentRoundId })
  );

  return totalUserLevelBonusYield;
}
