/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { WithChildren } from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import Typography from 'components/V2/Typography/Typography';
import CloseOutlineIcon from 'components/Icons/CloseOutlineIcon';

export interface Props extends WithChildren {
  visible: boolean;
  onClose: () => void;
  header?: string | React.ReactNode;
  closeModalOnBackdropClick?: boolean;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  contentContainerStyle?: React.CSSProperties;
  top?: string;
}

const Modal: FC<Props> = (props) => {
  const {
    children,
    visible,
    onClose,
    header,
    minWidth,
    maxWidth,
    minHeight,
    closeModalOnBackdropClick = false,
    contentContainerStyle,
    top,
    ...rest
  } = props;
  const [isShown, setIsShown] = useState(false);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (visible) {
      setIsShown(true);
    } else {
      setIsShown(false);
    }
  }, [visible]);

  return visible ? (
    <ModalContainer isShown={isShown} maxWidth={maxWidth}>
      <ModalWrapper minHeight={minHeight} top={top} {...rest}>
        <ModalHeader>
          <Typography variant="displayXSMedium" color="strong">
            {header}
          </Typography>
          <CloseButton onClick={onClose} data-testid={TestIds.CloseModal}>
            <CloseOutlineIcon color={theme.colorTokens.icon.medium} />
          </CloseButton>
        </ModalHeader>
        <ModalBody minWidth={minWidth} style={contentContainerStyle}>
          {children}
        </ModalBody>
      </ModalWrapper>
      <ModalBackdrop onClick={closeModalOnBackdropClick ? onClose : undefined} />
    </ModalContainer>
  ) : null;
};

interface ModalProps {
  isShown?: boolean;
  minWidth?: number;
  maxWidth?: number;
}

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colorTokens.surface.overlayBlack};
`;

const ModalContainer = styled.div<ModalProps>`
  display: flex;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  transition: 300ms all;
  opacity: ${(props) => (props.isShown ? '1' : '0')};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`};
  z-index: 1000;
  overflow-y: auto;
`;

const ModalWrapper = styled.div<{ minHeight?: number; top?: string }>`
  position: relative;
  margin: 5vh auto auto auto;
  min-width: 300px;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '300px;')};
  height: auto;
  max-height: calc(100vh - 20%);
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${({ theme }) => theme.colorTokens.surface.card};
  z-index: 1001;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ top }) => top && `top: ${top}`};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.extraLarge} ${({ theme }) => theme.spacing.massive};
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.stroke.medium};
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.large};

  span {
    border-radius: 50%;
    background: ${({ theme }) => theme.colorTokens.surface.subtle};
    padding: ${({ theme }) => theme.spacing.medium};
  }
`;

const ModalBody = styled.div<ModalProps>`
  height: 100%;
  width: 100%;
  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.massive};
  overflow-y: auto;
  overflow-x: hidden;
`;

export default Modal;
