import React, { FC } from 'react';

import Icon, { IconProps } from '../..';

import { ReactComponent as IconSVG } from 'assets/images/icons/statusLabel/trendingUp.svg';

const TrendingUp: FC<IconProps> = (props) => {
  const { color, hoverColor, className, width } = props;

  return (
    <Icon color={color} fillColor="transparent" hoverColor={hoverColor} className={className} width={width}>
      <IconSVG />
    </Icon>
  );
};

export default TrendingUp;
