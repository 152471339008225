import React, { FC } from 'react';
import styled from 'styled-components';

import Spacer from 'components/Spacer';
import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';

const FinancialAnalysisTableLoadingSkeleton: FC = () => (
  <>
    <LoadingSkeleton fullWidth />
    <Spacer height="extraLarge" />
    {Array.from({ length: 10 }).map((_, index) => (
      <>
        {/* eslint-disable-next-line react/no-array-index-key */}
        <Row key={index}>
          <NarrowSkeletonWrapper>
            <LoadingSkeleton width="300px" />
          </NarrowSkeletonWrapper>
          <NarrowSkeletonWrapper>
            <LoadingSkeleton width="150px" parentFlex />
          </NarrowSkeletonWrapper>
        </Row>
        <Spacer height="medium" />
      </>
    ))}
  </>
);

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NarrowSkeletonWrapper = styled.div`
  display: flex;
  max-width: 40%;
`;

export default FinancialAnalysisTableLoadingSkeleton;
