import React from 'react';

import { WithChildren } from '@investown/fe/common-utils';
import { CoreSDK } from '@investown/fe/api-sdk';

import RelatedProjectsCard from 'components/V2/RelatedProjectsCard/RelatedProjectsCard';

interface ProjectListRowProps extends WithChildren {
  project: Omit<CoreSDK.RelatedPropertyObjectType, 'photo'> & {
    photo: Pick<CoreSDK.PropertyPhotoObjectType, 'id' | 'url' | 'isPrimary'>;
  };
}

const ProjectListCardPartner: React.FC<ProjectListRowProps> = ({ project }) => {
  const {
    name,
    photo,
    investmentAmount,
    investmentCurrency,
    interestAmount,
    annualPercentageYield,
    investmentTermEnd,
    investmentLengthInMonths,
    investmentRoundStatus,
    investmentRoundRepaymentStatus,
    oldestUnpaidInstallmentDate,
    oldestUnpaidInstallmentDateWithGracePeriod,
    bonusYieldEligible,
    level,
  } = project;

  return (
    <RelatedProjectsCard
      name={name}
      photo={photo}
      annualPercentageYield={annualPercentageYield}
      oldestUnpaidInstallmentDate={oldestUnpaidInstallmentDate}
      oldestUnpaidInstallmentDateWithGracePeriod={oldestUnpaidInstallmentDateWithGracePeriod}
      investmentRoundRepaymentStatus={investmentRoundRepaymentStatus}
      status={investmentRoundStatus}
      investmentTermEnd={investmentTermEnd}
      investmentLengthInMonths={investmentLengthInMonths}
      investmentAmount={investmentAmount}
      investmentCurrency={investmentCurrency}
      interestAmount={interestAmount}
      bonusYieldEligible={bonusYieldEligible}
      isProjectVip={level === CoreSDK.PropertyInvestmentRoundLevel.Vip}
    />
  );
};

export default ProjectListCardPartner;
