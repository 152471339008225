import React, { FC } from 'react';
import styled from 'styled-components';

import PremiumIcon from 'components/Icons/PremiumIcon';
import Lang from 'util/IntlMessages';
import Spacer from 'components/Spacer';

interface VipPillProps {
  size: 'small' | 'large';
}

const VipPill: FC<VipPillProps> = ({ size }) => (
  <Container size={size}>
    <PremiumIcon />
    <Spacer width="small" />
    <GradientSpan>
      <Lang id="global.vip" />
    </GradientSpan>
  </Container>
);

const Container = styled.div<{ size: 'small' | 'large' }>`
  height: ${({ size }) => (size === 'large' ? '32px' : '24px')};
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  font-size: ${({ theme, size }) =>
    size === 'large' ? theme.typography.labelBASEMedium.size : theme.typography.labelXSMedium.size};
  line-height: ${({ theme, size }) =>
    size === 'large' ? theme.typography.labelBASEMedium.lineHeight : theme.typography.labelXSMedium.lineHeight};
  font-weight: ${({ theme, size }) =>
    size === 'large' ? theme.typography.labelBASEMedium.weight : theme.typography.labelXSMedium.weight};
  background-color: ${({ theme }) => theme.colorTokens.surface.inverted950};
  padding: ${({ theme }) =>
    `${theme.spacing.small} ${theme.spacing.regular} ${theme.spacing.small} ${theme.spacing.medium}`};
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;

  & svg {
    width: ${({ size }) => (size === 'large' ? '20px' : '16px')};
  }
`;

const GradientSpan = styled.span`
  background: ${({ theme }) =>
    `linear-gradient(266deg, ${theme.colorTokens.gradient.gold.start} 1.64%, ${theme.colorTokens.gradient.gold.middle} 51.9%, ${theme.colorTokens.gradient.gold.end} 99.12%)`};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export default VipPill;
