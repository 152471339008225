import React, { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useMutation } from 'react-query';

import { TestIds } from '@investown/fe/test-utils/testIds';
import { LegalEntityVerificationVersion, updateVerificationData, UserVerificationVersion } from '@investown/fe/api-sdk';

import ContractAgreementSuccess from './ContractAgreementSuccess';
import ContractAgreementError from './ContractAgreementError';

import Lang from 'util/IntlMessages';
import { getExternalURL } from 'constants/ExternalUrl';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import Button from 'components/Button';
import LoadingWidget from 'components/V2/LoadingWidget/LoadingWidget';

interface Props {
  onClose: () => void;
  inflectedFirstName: string;
  isLegalEntity: boolean;
}
const NewContractModalContent: FC<Props> = ({ onClose, inflectedFirstName, isLegalEntity }) => {
  const intl = useIntl();
  const { mutate: save, isLoading } = useMutation(updateVerificationData);
  const [contractAgreedSuccess, setContractAgreedSuccess] = useState<boolean>(false);
  const [contractAgreedError, setContractAgreedError] = useState<boolean>(false);

  const handleAgreeWithNewInvestmentContract = useCallback(async () => {
    save(
      {
        submitted: true,
        ...(isLegalEntity
          ? { companyVerificationVersion: LegalEntityVerificationVersion.WithConfirmedContract }
          : { version: UserVerificationVersion.WithConfirmedContract }),
      },
      {
        onSuccess: () => {
          setContractAgreedSuccess(true);
        },
        onError: () => setContractAgreedError(true),
      }
    );
  }, [isLegalEntity, save]);

  if (contractAgreedSuccess) {
    return <ContractAgreementSuccess closeModal={onClose} />;
  }

  if (contractAgreedError) {
    return <ContractAgreementError closeModal={onClose} />;
  }

  return (
    <>
      <StaticBlock>
        <Typography variant="bodyLGMedium" color="strong">
          <Lang id="newInvestmentContractModal.salutation" values={{ salutation: inflectedFirstName }} />
        </Typography>
        <Spacer height="medium" />
        <Typography variant="bodyBASERegular" color="medium">
          <Lang id="newInvestmentContractModal.description" values={{ salutation: inflectedFirstName }} />
        </Typography>
      </StaticBlock>
      <Spacer height="extraLarge" />
      <Iframe
        src={getExternalURL('InvestmentContract', true)}
        title={intl.formatMessage({ id: 'newInvestmentContractModal.header' })}
        width="100%"
        height="369px"
      >
        <LoadingWidget />
      </Iframe>
      <Spacer height="extraLarge" />
      <ButtonWrapper>
        <Button
          color="primary"
          onClick={handleAgreeWithNewInvestmentContract}
          data-testid={TestIds.AgreeWithNewInvestmentContract}
          size="large"
          loading={isLoading}
          disabled={isLoading}
        >
          <Lang id="newInvestmentContractModal.button" />
        </Button>
      </ButtonWrapper>
    </>
  );
};

const StaticBlock = styled.div`
  padding: 0 40px;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 369px;
  border-width: 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colorTokens.surface.medium};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
`;

export default NewContractModalContent;
