import React, { useMemo } from 'react';

import { UserDetailsDTO, UserVerificationDataDTO } from '@investown/fe/api-sdk';

import OnboardingSteps from './OnboardingSteps';
import { OnboardingCallbackFnParams } from './helpers';
import OnboardingBanner from './OnboardingBanner/OnboardingBanner';

import FadeIn from 'components/Animations/FadeIn';
import WidgetErrorBoundary from 'components/Widget/WidgetErrorBoundary';
import { OnboardingBannerDirection } from 'components/V2/OnboardingBanner/OnboardingBanner';

interface Props {
  userDetails: UserDetailsDTO;
  userVerificationData: UserVerificationDataDTO;
  direction?: OnboardingBannerDirection;
  isProjectWithOldContract?: boolean;
  isProjectBlockedForUser?: boolean;
  showDepositBanner?: boolean;
  callbackFn: (params: OnboardingCallbackFnParams) => void;
}

function Onboarding({
  userDetails,
  userVerificationData,
  direction = 'row',
  isProjectWithOldContract,
  isProjectBlockedForUser,
  showDepositBanner = true,
  callbackFn,
}: Props) {
  const shouldShowOnboardingSteps = useMemo(() => {
    if (direction === 'column') {
      return true;
    }
    if (direction === 'row' && userDetails.madeDeposit) {
      return true;
    }
    return false;
  }, [direction, userDetails.madeDeposit]);

  const shouldShowOnboardingBanner = useMemo(() => {
    if (direction === 'row' && !userDetails.madeDeposit) {
      return true;
    }
    return false;
  }, [direction, userDetails.madeDeposit]);

  return (
    <FadeIn flex>
      <WidgetErrorBoundary>
        {shouldShowOnboardingSteps && (
          <OnboardingSteps
            userDetails={userDetails}
            userVerificationData={userVerificationData}
            isProjectWithOldContract={isProjectWithOldContract}
            isProjectBlockedForUser={isProjectBlockedForUser}
            direction={direction}
            showDepositBanner={showDepositBanner}
            callbackFn={(params) => callbackFn(params)}
          />
        )}
        {shouldShowOnboardingBanner && (
          <OnboardingBanner userDetails={userDetails} userVerificationData={userVerificationData} />
        )}
      </WidgetErrorBoundary>
    </FadeIn>
  );
}

export default Onboarding;
