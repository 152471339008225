// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  PropertyObjectType,
  PropertyInvestmentRoundPurpose,
} from '../../../../../api-sdk/src/lib/core/__generated__/sdk';
import { AnalyticsAction } from '../analyticsActions';
import { hashSHA256 } from '../analyticsHelpers';
import { InvestownActionCreators, ScreenViewPayload } from '../analyticsService';
import { ErrorPayload, InviteFriendsEventPayload, RegistrationStepEventPayload } from '../types';

// event: 'purchase';
export type PurchaseEventPayload = {
  currency: string;
  value: string;
  item_name: string;
  item_id: string;
  item_category: string;
  price: string;
};

export type FirebaseScreenViewPayload = ScreenViewPayload & {
  screenName: string;
  screenClass?: string;
};
export interface ScreenViewFirebaseEventPayload {
  screen_name: string;
  screen_class: string;
  [key: string]: unknown;
}

// event: 'view_item';
export type ViewItemFirebaseEventPayload = PropertyObjectType;

// event: 'add_to_cart';
export type AddToCartFirebaseEventPayload = {
  value: number;
  currency: string;
  project: PropertyObjectType;
};

export type FirebaseEvent<T> = {
  name: EventNames;
  payload: T;
};

export enum EventNames {
  ADD_TO_CART = 'add_to_cart',
  ERROR = 'error',
  INVITE_FRIENDS = 'invite_friends',
  PURCHASE = 'purchase',
  REGISTRATION_STEP = 'registration_step',
  VERIFICATION_STEP = 'verification_step',
  SCREEN_VIEW = 'screen_view',
  VIEW_ITEM = 'view_item',
}

export interface Events {
  [EventNames.ADD_TO_CART]: FirebaseEvent<AddToCartFirebaseEventPayload>;
  [EventNames.ERROR]: FirebaseEvent<ErrorPayload>;
  [EventNames.INVITE_FRIENDS]: FirebaseEvent<InviteFriendsEventPayload>;
  [EventNames.PURCHASE]: FirebaseEvent<PurchaseEventPayload>;
  [EventNames.REGISTRATION_STEP]: FirebaseEvent<RegistrationStepEventPayload>;
  [EventNames.VERIFICATION_STEP]: FirebaseEvent<RegistrationStepEventPayload>;
  [EventNames.SCREEN_VIEW]: FirebaseEvent<ScreenViewFirebaseEventPayload>;
  [EventNames.VIEW_ITEM]: FirebaseEvent<ViewItemFirebaseEventPayload>;
}

export function createErrorEvent(event: ReturnType<InvestownActionCreators['errorAction']>): Events[EventNames.ERROR] {
  const { payload } = event;
  return {
    name: EventNames.ERROR,
    payload,
  };
}

export function createInviteFriendsEvent(
  event: ReturnType<InvestownActionCreators['inviteFriendsAction']>
): Events[EventNames.INVITE_FRIENDS] {
  const { payload } = event;
  return {
    name: EventNames.INVITE_FRIENDS,
    payload: {
      last_purchase: payload.last_purchase,
      average_purchase: payload.average_purchase,
      purchases: payload.purchases,
      userDetails: payload.userDetails,
      resolution: payload.resolution,
      language: payload.language,
    },
  };
}

export function createRegistrationVerificationEvent(
  event:
    | ReturnType<InvestownActionCreators['registrationStepAction']>
    | ReturnType<InvestownActionCreators['verificationStepAction']>
): Events[EventNames.REGISTRATION_STEP] | Events[EventNames.VERIFICATION_STEP] {
  const { type, payload } = event;
  const name = type === AnalyticsAction.REGISTRATION_STEP ? EventNames.REGISTRATION_STEP : EventNames.VERIFICATION_STEP;
  return {
    name,
    payload: {
      step: payload.step,
      user_type: payload.user_type,
      ...(!!payload.user_id && { user_id: payload.user_id }),
      ...(!!payload.email && { email: hashSHA256(payload.email) }),
      ...(!!payload.phone && { phone: hashSHA256(payload.phone) }),
    },
  };
}

export function createScreenViewEventPayload(payload: FirebaseScreenViewPayload): ScreenViewFirebaseEventPayload {
  const { screenName, screenClass, ...rest } = payload;
  return {
    screen_name: screenName!,
    screen_class: screenClass || screenName!,
    ...rest,
  };
}

type PurposeObjectTypePayload = {
  item_category: PropertyInvestmentRoundPurpose;
  item_category2?: PropertyInvestmentRoundPurpose;
  item_category3?: PropertyInvestmentRoundPurpose;
  item_category4?: PropertyInvestmentRoundPurpose;
  item_category5?: PropertyInvestmentRoundPurpose;
};

export function getItemPurposesPayload(
  purposes: PropertyInvestmentRoundPurpose[] | undefined
): PurposeObjectTypePayload | undefined {
  if (!purposes || !Array.isArray(purposes) || purposes.length === 0) return;

  const payload: PurposeObjectTypePayload = { item_category: purposes[0] };

  const purposeCount = Math.min(purposes.length, 5);
  for (let purposeIndex = 2; purposeIndex <= purposeCount; purposeIndex += 1) {
    payload[`item_category${purposeIndex}` as keyof PurposeObjectTypePayload] = purposes[purposeIndex - 1];
  }

  return payload;
}
