import React, { FC, useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  CoreSDK,
  QueryKeys,
  getActiveSecondaryMarketRemainingOffersSums,
  refetchIntervals,
} from '@investown/fe/api-sdk';

import MySecondaryOffers from 'components/V2/MySecondaryOffers/MySecondaryOffers';
import Typography from 'components/V2/Typography/Typography';
import Lang from 'util/IntlMessages';
import Spacer from 'components/V2/Spacer/Spacer';

interface Props {
  investmentRoundId: string;
  onCancelAllSecondaryMarketOffersClick: () => void;
  shouldRefetchSecondaryMarkerOffers?: boolean;
}

const MySecondaryOffersWidget: FC<Props> = ({
  investmentRoundId,
  onCancelAllSecondaryMarketOffersClick,
  shouldRefetchSecondaryMarkerOffers,
}) => {
  const { data, isLoading, isError, isIdle, refetch } = useQuery<
    CoreSDK.ActiveSecondaryMarketRemainingOffersSumsQuery,
    Error,
    CoreSDK.ActiveSecondaryMarketRemainingOffersSumsQuery,
    [string, string]
  >(
    [QueryKeys.ActiveSecondaryMarketRemainingOffersSums, investmentRoundId],
    () => getActiveSecondaryMarketRemainingOffersSums(investmentRoundId),
    { refetchInterval: refetchIntervals.thirtySeconds, staleTime: 0 }
  );

  useEffect(() => {
    if (shouldRefetchSecondaryMarkerOffers) {
      refetch();
    }
  }, [refetch, shouldRefetchSecondaryMarkerOffers]);

  if (isError) {
    return (
      <Typography variant="labelBASEMedium" color="strong">
        <Lang id="errors.fetchData.description" />
      </Typography>
    );
  }

  if (isLoading || isIdle || data.activeSecondaryMarketOffersSums.currentlyOfferedAmount.amount === 0) {
    return null;
  }

  const offers = {
    amount: data.activeSecondaryMarketOffersSums.currentlyOfferedAmount.amount,
    currency: data.activeSecondaryMarketOffersSums.currentlyOfferedAmount.currency,
  };

  return (
    <>
      <Spacer height="huge" />
      <MySecondaryOffers
        offers={offers}
        onCancelAllSecondaryMarketOffersClick={onCancelAllSecondaryMarketOffersClick}
      />
    </>
  );
};

export default MySecondaryOffersWidget;
