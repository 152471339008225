import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedTime, useIntl } from 'react-intl';

import { CoreSDK } from '@investown/fe/api-sdk';
import { currencyFormat } from '@investown/fe/common-utils';
import { TestIds } from '@investown/fe/test-utils/testIds';

import FlexRow, { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import Typography from 'components/V2/Typography/Typography';
import Spacer from 'components/V2/Spacer/Spacer';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import DividerHorizontal from 'components/Divider/Horizontal';
import Button from 'components/V2/Button';
import Lang from 'util/IntlMessages';

interface InvestmentsWithdrawalModalProps {
  investments: CoreSDK.InvestmentObjectType[];
  onSelectInvestment: (arg0: CoreSDK.InvestmentObjectType) => void;
}

const InvestmentsList: FC<InvestmentsWithdrawalModalProps> = ({ investments, onSelectInvestment }) => {
  const intl = useIntl();

  return (
    <ScrollArea>
      {investments.map(
        (investment) =>
          investment.canBeWithdrawn &&
          !investment.withdrawn && (
            <InvestmentRow key={investment.id}>
              <FlexRow alignVertical={AlignDirections.Stretch}>
                <FlexGrow>
                  <Typography variant="bodySMRegular" color="subtle">
                    <FormattedTime
                      value={investment.occurredAt}
                      day="2-digit"
                      month="2-digit"
                      year="numeric"
                      hour="2-digit"
                      minute="2-digit"
                    />
                  </Typography>
                  <Spacer height="small" />
                  <FlexRow alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexStart}>
                    <Typography variant="bodyBASERegular" color="strong">
                      {currencyFormat({
                        input: investment.amount.amount,
                        currency: investment.amount.currency,
                        locale: intl.locale,
                      })}
                    </Typography>
                    {investment.isAutoinvest ? (
                      <>
                        <Spacer width="large" />
                        <AutoinvestLabel>
                          <Typography variant="labelXSRegular" color="invertedStrong">
                            <Lang id="property.withdrawFromInvestment.autoinvest" />
                          </Typography>
                        </AutoinvestLabel>
                      </>
                    ) : null}
                  </FlexRow>
                </FlexGrow>
                <FlexColumn alignVertical={AlignDirections.FlexEnd}>
                  <Button
                    data-testid={TestIds.WithdrawFromInvestment}
                    variant="danger"
                    onClick={() => onSelectInvestment(investment)}
                  >
                    <Lang id="property.withdrawFromInvestment.button" />
                  </Button>
                </FlexColumn>
              </FlexRow>
              <Spacer height="large" />
              <DividerHorizontal color="faded" />
              <Spacer height="large" />
            </InvestmentRow>
          )
      )}
    </ScrollArea>
  );
};

const ScrollArea = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: 600px;
`;

const FlexGrow = styled(FlexColumn)`
  flex-grow: 5;
`;

const InvestmentRow = styled.div`
  width: 100%;
`;

const AutoinvestLabel = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.surface.seedStarterBlue};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
`;

export default InvestmentsList;
