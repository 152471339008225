import React, { FC } from 'react';

import { ReactComponent as IconSVG } from 'assets/images/icons/info-in-circle.svg';
import Icon, { IconProps } from 'components/V2/Icons';

const InformationCircleOutlineIcon: FC<IconProps> = (props) => {
  const { color, fillColor, hoverColor, className, width } = props;

  return (
    <Icon
      color={color}
      fillColor={fillColor || 'transparent'}
      hoverColor={hoverColor}
      className={className}
      width={width}
    >
      <IconSVG />
    </Icon>
  );
};

export default InformationCircleOutlineIcon;
