import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import LoadingSkeleton from 'components/V2/LoadingSkeleton/LoadingSkeleton';
import { AlignDirections } from 'components/V2/Grid/FlexRow/FlexRow';
import FlexColumn from 'components/V2/Grid/FlexColumn/FlexColumn';
import Spacer from 'components/V2/Spacer/Spacer';
import { Card, RightColumn, Row, imageSize } from 'components/V2/RelatedProjectsCard/RelatedProjectsCard';

const RelatedProjectsListSkeletonCard: FC = () => {
  const theme = useContext(ThemeContext);
  return (
    <Card>
      <FlexColumn>
        <Row alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.SpaceBetween}>
          <LoadingSkeleton width={65} height={22} />
          <Row alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.FlexEnd}>
            <LoadingSkeleton width={27} height={27} />
            <Spacer width="medium" />
            <LoadingSkeleton width={27} height={27} borderRadius="50%" />
          </Row>
        </Row>
        <Spacer height="large" />
        <LoadingSkeleton width="50%" height={32} />
        <Spacer height="large" />
        <LoadingSkeleton height={32} />
        <Spacer height="large" />
        <Row alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.SpaceBetween}>
          <LoadingSkeleton width={145} height={22} />
          <LoadingSkeleton width={45} height={22} />
        </Row>
        <Spacer height="regular" />

        <Row alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.SpaceBetween}>
          <LoadingSkeleton width={145} height={22} />
          <LoadingSkeleton width={45} height={22} />
        </Row>
        <Spacer height="regular" />

        <Row alignVertical={AlignDirections.Center} alignHorizontal={AlignDirections.SpaceBetween}>
          <LoadingSkeleton width={145} height={22} />
          <LoadingSkeleton width={45} height={22} />
        </Row>
      </FlexColumn>
      <Spacer width="massive" />
      <RightColumn>
        <LoadingSkeleton
          borderRadius={theme.borderRadius.large}
          width={imageSize.width / 2}
          height={imageSize.height / 2}
          containerClassName="flex-1"
        />
      </RightColumn>
    </Card>
  );
};

export default RelatedProjectsListSkeletonCard;
