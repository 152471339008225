/* eslint-disable @nx/enforce-module-boundaries */
import { createAction } from 'typesafe-actions';

import { UserSignUpRequest } from '../../../../api-sdk/src/lib/users/model';
import {
  AbilityToBearLossQuestionnaireSubmitResponseData,
  InvestorQuestionnaireSubmitResult,
} from '../../../../api-sdk/src/lib/investorQuestionnaire/model';
import { CreateAutoinvestStrategyMutation } from '../../../../api-sdk/src/lib/core/__generated__/types';

import { AnalyticsAction as ANALYTICS } from './analyticsActions';
import {
  ErrorPayload,
  InvestEventPayload,
  InviteFriendsEventPayload,
  RegistrationStepEventPayload,
  LoginEventPayload,
  InvestOverviewPayload,
  ViewItemEventPayload,
  ScreenAppViewEventPayload,
  ProjectDetailTabOpenedEventPayload,
} from './types';

export const notificationsModalRequestButtonClickAction = createAction(
  ANALYTICS.NOTIFICATIONS_MODAL_REQUEST_BUTTON_CLICK
)();

export const notificationsModalSuspendButtonClickAction = createAction(
  ANALYTICS.NOTIFICATIONS_MODAL_SUSPEND_BUTTON_CLICK
)();

export const signUpStartAction = createAction(ANALYTICS.SIGN_UP_START)();

export const signUpEmailCompleteAction = createAction(ANALYTICS.SIGN_UP_EMAIL_COMPLETE)();

export const signUpNameCompleteAction = createAction(ANALYTICS.SIGN_UP_NAME_COMPLETE)();

export const signUpPasswordCompleteAction = createAction(ANALYTICS.SIGN_UP_PASSWORD_COMPLETE)();

export const verificationStartAction = createAction(ANALYTICS.VERIFICATION_START)();

export const verificationAddressCompleteAction = createAction(ANALYTICS.VERIFICATION_ADDRESS_COMPLETE)();

export const verificationPersonalIdentifierCompleteAction = createAction(
  ANALYTICS.VERIFICATION_PERSONALIDENTIFIER_COMPLETE
)();

export const verificationPhoneStartAction = createAction(ANALYTICS.VERIFICATION_PHONE_START)();

export const verificationPhoneCompleteAction = createAction(ANALYTICS.VERIFICATION_PHONE_COMPLETE)();

export const verificationFirstPhaseCompleteAction = createAction(ANALYTICS.VERIFICATION_FIRST_PHASE_COMPLETE)();

export const verificationSecondPhaseStartAction = createAction(ANALYTICS.VERIFICATION_SECOND_PHASE_START)();

export const verificationSecondPhaseCompletedAction = createAction(ANALYTICS.VERIFICATION_SECOND_PHASE_COMPLETED)();
export const verificationSofCompleteAction = createAction(ANALYTICS.VERIFICATION_SOF_COMPLETE)();

export const verificationBankAccountCompleteAction = createAction(ANALYTICS.VERIFICATION_BANK_ACCOUNT_COMPLETE)();
export const verificationPepCompleteAction = createAction(ANALYTICS.VERIFICATION_PEP_COMPLETE)();

export const verificationDocumentsCaptureStartAction = createAction(ANALYTICS.VERIFICATION_DOCUMENTS_CAPTURE_START)();

export const verificationDocumentsCaptureCompleteAction = createAction(
  ANALYTICS.VERIFICATION_DOCUMENTS_CAPTURE_COMPLETE
)();

export const verificationQuestionnaireStartAction = createAction(ANALYTICS.VERIFICATION_QUESTIONNAIRE_START)();

export const verificationQuestionnaireQuestionAnsweredAction = createAction(
  ANALYTICS.VERIFICATION_QUESTIONNAIRE_ANSWERED
)<{
  questionId: number | string;
  answer?: number | string | boolean;
}>();

export const verificationQuestionnaireQuestionReopenedAction = createAction(
  ANALYTICS.VERIFICATION_QUESTIONNAIRE_REOPENED
)<{
  questionId: number | string;
}>();
export const verificationQuestionnaireCompleteAction = createAction(ANALYTICS.VERIFICATION_QUESTIONNAIRE_COMPLETE)<{
  suitabilityResult: InvestorQuestionnaireSubmitResult;
}>();

export const verificationQuestionnaireUnsuitableAlertAction = createAction(
  ANALYTICS.VERIFICATION_QUESTIONNAIRE_UNSUITABLE
)();

export const verificationQuestionnairePartTwoStartAction = createAction(
  ANALYTICS.VERIFICATION_QUESTIONNAIRE_P2_START
)();
export const verificationQuestionnairePartTwoQuestionAnsweredAction = createAction(
  ANALYTICS.VERIFICATION_QUESTIONNAIRE_P2_ANSWERED
)<{
  questionId: number | string;
  answer?: number | string | boolean;
}>();

export const verificationQuestionnairePartTwoQuestionReopenedAction = createAction(
  ANALYTICS.VERIFICATION_QUESTIONNAIRE_P2_REOPENED
)<{
  questionId: number | string;
}>();

export const verificationQuestionnairePartTwoCompleteAction = createAction(
  ANALYTICS.VERIFICATION_QUESTIONNAIRE_P2_COMPLETE
)<{
  suitabilityResult: InvestorQuestionnaireSubmitResult;
}>();
export const abilityToBearLossQuestionnaireStartAction = createAction(ANALYTICS.ABILITY_TO_BEAR_LOSS_START)();

export const abilityToBearLossQuestionnaireQuestionAnsweredAction = createAction(
  ANALYTICS.ABILITY_TO_BEAR_LOSS_ANSWERED
)<{
  questionId: number | string;
}>();

export const abilityToBearLossQuestionnaireQuestionReopenedAction = createAction(
  ANALYTICS.ABILITY_TO_BEAR_LOSS_REOPENED
)<{
  questionId: number | string;
}>();

export const abilityToBearLossQuestionnaireCompleteAction = createAction(ANALYTICS.ABILITY_TO_BEAR_LOSS_COMPLETE)<{
  submitResponseData: AbilityToBearLossQuestionnaireSubmitResponseData | 'OK';
}>();

export const errorAction = createAction(ANALYTICS.ERROR)<ErrorPayload>();
export const inviteFriendsAction = createAction(ANALYTICS.INVITE_FRIENDS)<InviteFriendsEventPayload>();
export const investAction = createAction(ANALYTICS.INVEST)<InvestEventPayload>();
export const withdrawAction = createAction(ANALYTICS.WITHDRAW)<InvestEventPayload>();
export const investOverviewAction = createAction(ANALYTICS.INVEST_OVERVIEW)<InvestOverviewPayload>();
export const leadAction = createAction(ANALYTICS.LEAD)();
export const logOutAction = createAction(ANALYTICS.LOGOUT)();
export const loginAction = createAction(ANALYTICS.LOGIN)<LoginEventPayload>();
export const passwordResetAction = createAction(ANALYTICS.PASSWORD_RESET)();
export const registrationStepAction = createAction(ANALYTICS.REGISTRATION_STEP)<RegistrationStepEventPayload>();
export const verificationStepAction = createAction(ANALYTICS.VERIFICATION_STEP)<RegistrationStepEventPayload>();
export const projectDetailTabOpenedAction = createAction(
  ANALYTICS.PROJECT_DETAIL_TAB_OPENED
)<ProjectDetailTabOpenedEventPayload>();
export const viewItemAction = createAction(ANALYTICS.VIEW_ITEM)<ViewItemEventPayload>();
export const notificationPreferencesAction = createAction(ANALYTICS.NOTIFICATION_PREFERENCES)<
  UserSignUpRequest['notificationPreferences']
>();
export const screenAppViewAction = createAction(ANALYTICS.SCREEN_APP_VIEW)<ScreenAppViewEventPayload>();
export const referralIconClickedAction = createAction(ANALYTICS.REFERRAL_ICON_CLICKED)();

/** Autoinvest actions */
export const autoinvestStepAction = createAction(ANALYTICS.AUTOINVEST_STEP)<{
  name: string;
  data?: Record<string, unknown>;
}>();
export const autoinvestBankDetailsCopiedAction = createAction(ANALYTICS.AUTOINVEST_BANK_DETAILS_COPIED)();
export const autoinvestActivatedAction = createAction(ANALYTICS.AUTOINVEST_ACTIVATED)<
  CreateAutoinvestStrategyMutation['createAutoinvestStrategy']['parameters']
>();
export const autoinvestDeactivatedAction = createAction(ANALYTICS.AUTOINVEST_DEACTIVATED)();
export const autoinvestParametersChangedAction = createAction(ANALYTICS.AUTOINVEST_PARAMETERS_CHANGED)<
  CreateAutoinvestStrategyMutation['createAutoinvestStrategy']['parameters']
>();
