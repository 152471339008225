import { createAction } from 'typesafe-actions';

export const investedAction = createAction('INVESTED')<{
  amount: number;
  currency: string;
  transactionId: string;
  propertySlug: string;
  investedAt: number;
}>();

export const investmentWithdrawnAction = createAction('INVESTMENT_WITHDRAWN')<void>();

export const investmentErrorAction = createAction('INVESTMENT_ERROR')<void>();

export const investmentPurchaseAction = createAction('INVESTMENT_PURCHASE')<{
  amount: number;
  currency: string;
  transactionId: string;
  propertySlug: string;
}>();
