import { PropertyInvestmentRoundActivitiesQuery, PropertyInvestmentRoundActivityFilterType } from './__generated__/sdk';
import { SortRequest } from './model';
import { getCoreClient } from './client';

export async function getPropertyInvestmentRoundActivities(
  propertyInvestmentRoundId: string,
  {
    page = 0,
    perPage = 10,
    sortField = 'occurredAt',
    filter,
    sortOrder = 'DESC',
  }: {
    page?: number;
    perPage?: number;
    filter?: PropertyInvestmentRoundActivityFilterType;
    sortField?: string;
    sortOrder?: SortRequest['sortOrder'];
  }
): Promise<PropertyInvestmentRoundActivitiesQuery> {
  const coreClient = await getCoreClient();

  return coreClient.PropertyInvestmentRoundActivities({
    propertyInvestmentRoundId,
    page,
    perPage,
    sortField,
    sortOrder,
    filter,
  });
}
