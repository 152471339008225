import React from 'react';

import Lang from 'util/IntlMessages';

interface Props {
  error?: Error | null;
}

const ErrorText: React.FC<Props> = ({ error }) => {
  if (error?.message?.match(/Network (request failed|Error)/i)) {
    return <Lang id="errors.network" />;
  }
  return <Lang id="errors.unknown" />;
};

export default ErrorText;
