import React from 'react';
import styled from 'styled-components';

import Widget from 'components/Widget';
import ErrorText from 'components/Errors/ErrorText';

interface Props {
  error?: Error | null;
}

const ErrorWidget: React.FC<Props> = ({ error }) => (
  <StyledWidget>
    <ErrorText error={error} />
  </StyledWidget>
);

const StyledWidget = styled(Widget)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ErrorWidget;
